export default {
  ITEM_TYPE_MODULE: 1,
  ITEM_TYPE_CATEGORY: 2,
  ITEM_TYPE_SERVICE: 3,
  ITEM_TYPE_PRODUCT: 5,
  ITEM_TYPE_ESTABLISHMENT: 15,
  ITEM_TYPE_BOOKING: 16,
  ITEM_TYPE_LEAD: 20,
  ITEM_TYPE_CATALOGUE_V2: 31,
  ITEM_TYPE_CATALOGUE_PRODUCT_V2: 32
};
