import { getImageUrl, getPDFUrl, openExternalURL } from "../services/utils";
import mixin from "../services/mixin";

const Mixin = {
  data() {
    return {
      locale: "en"
    };
  },
  created() {
    this.locale = this.getLocale();
  },
  mixins: [mixin],
  methods: {
    getPDFUrl,
    getRealImageUrl: getImageUrl,
    getPDFLocale() {
      const lang =
        this.$store.state.interfaceLanguage ??
        this.$store.state.user.locale ??
        this.$store.state.establishment.languages[0].languageCode;
      return lang;
    },
    getPdfMenuName(menu) {
      const locale = this.getPDFLocale();
      if (menu.hasOwnProperty(locale)) {
        // User locale
        if (menu[locale] && menu[locale].hasOwnProperty("name")) {
          return menu[locale].name;
        }
      } else if (menu.hasOwnProperty("en")) {
        // English
        if (menu["en"] && menu["en"].hasOwnProperty("name")) {
          return menu["en"].name;
        }
      } else if (menu[Object.keys(menu)[0]] && menu[Object.keys(menu)[0]].hasOwnProperty("name")) {
        // First saved language
        return menu[Object.keys(menu)[0]].name;
      }
    },
    checkPdfMenu(idPdf, menu) {
      if (menu.hasOwnProperty(this.user.locale)) {
        if (
          menu[this.user.locale] &&
          menu[this.user.locale].hasOwnProperty("id") &&
          menu[this.user.locale].id &&
          menu[this.user.locale].id === idPdf
        ) {
          return menu;
        }
      }
      if (menu.hasOwnProperty("en")) {
        if (menu["en"] && menu["en"].hasOwnProperty("id") && menu["en"].id && menu["en"].id === idPdf) {
          return menu;
        }
      }
      if (
        menu[Object.keys(menu)[0]] &&
        menu[Object.keys(menu)[0]].hasOwnProperty("id") &&
        menu[Object.keys(menu)[0]].id &&
        menu[Object.keys(menu)[0]].id === idPdf
      ) {
        return menu;
      }
      return false;
    },
    checkPdfMenuByMenuId(menuId, menu) {
      if (menu.hasOwnProperty("menuId") && menu.menuId) {
        if (menu.menuId === menuId) {
          return menu;
        }
      }
      return false;
    },
    checkCatalogueLink(catalogue) {
      if (catalogue.type === "pdf" && catalogue.pdf) {
        return this.checkPdfLink(catalogue.pdf);
      } else if (catalogue.type === "url" && catalogue.url) {
        return this.checkPdfLink(catalogue.url);
      } else if (catalogue.type === "products") {
        return true;
      } else if (catalogue.type === "manual") {
        return true;
      }
      return false;
    },
    checkPdfLink(menu) {
      const locale = this.getPDFLocale();
      if (menu.hasOwnProperty(locale) && menu[locale]) {
        if (
          (menu[locale].hasOwnProperty("url") && menu[locale].url) ||
          (menu[locale].hasOwnProperty("id") && menu[locale].id)
        ) {
          return true;
        }
      }
      if (menu.hasOwnProperty("en") && menu["en"]) {
        if (
          (menu["en"].hasOwnProperty("url") && menu["en"].url) ||
          (menu["en"].hasOwnProperty("id") && menu["en"].id)
        ) {
          return true;
        }
      }
      if (
        menu[Object.keys(menu)[0]] &&
        ((menu[Object.keys(menu)[0]].hasOwnProperty("id") && menu[Object.keys(menu)[0]].id) ||
          (menu[Object.keys(menu)[0]].hasOwnProperty("url") && menu[Object.keys(menu)[0]].url))
      ) {
        return true;
      }
      return false;
    },
    getPdfMenuLink(menu) {
      let hasUrl = null;
      const locale = this.getPDFLocale();

      Object.keys(menu).forEach(key => {
        if (menu[key].url) {
          hasUrl = true;
        }
      });

      if (menu.hasOwnProperty(locale) && menu[locale]) {
        if (menu[locale].hasOwnProperty("url") && menu[locale].url) {
          return menu[locale].url;
        } else if (!hasUrl && menu[locale].hasOwnProperty("id") && menu[locale].id) {
          return "https://drive.google.com/viewerng/viewer?embedded=true&url=" + this.getPDFUrl(menu[locale].id);
        }
      }
      if (menu.hasOwnProperty("en") && menu["en"]) {
        if (menu["en"].hasOwnProperty("url") && menu["en"].url) {
          return menu["en"].url;
        } else if (!hasUrl && menu["en"].hasOwnProperty("id") && menu["en"].id) {
          return "https://drive.google.com/viewerng/viewer?embedded=true&url=" + this.getPDFUrl(menu["en"].id);
        }
      }
      if (menu[Object.keys(menu)[0]]) {
        if (menu[Object.keys(menu)[0]].hasOwnProperty("url") && menu[Object.keys(menu)[0]].url) {
          return menu[Object.keys(menu)[0]].url;
        } else if (!hasUrl && menu[Object.keys(menu)[0]].hasOwnProperty("id") && menu[Object.keys(menu)[0]].id) {
          return (
            "https://drive.google.com/viewerng/viewer?embedded=true&url=" +
            this.getPDFUrl(menu[Object.keys(menu)[0]].id)
          );
        }
      }
    },
    needsToOpenInBlankPage(menu) {
      if (menu.hasOwnProperty(this.locale)) {
        return menu[this.locale].openWebsiteInBrowser;
      }
      if (menu.hasOwnProperty("en")) {
        return menu["en"].openWebsiteInBrowser;
      }
      if (menu[Object.keys(menu)[0]]) {
        return menu[Object.keys(menu)[0]].openWebsiteInBrowser;
      }
      return false;
    },
    openPdfMenuURL(menu, isPdf) {
      // url: "https://drive.google.com/viewerng/viewer?embedded=true&url=" + this.getPdfMenuLink(menu),
      // old url: "http://docs.google.com/gview?embedded=true&url=" + this.getPdfMenuLink(menu),
      if (this.checkPdfLink(menu)) {
        if (this.needsToOpenInBlankPage(menu)) {
          openExternalURL(this.getPdfMenuLink(menu), "_blank");
        } else {
          const query = { url: this.getPdfMenuLink(menu), title: this.getPdfMenuName(menu) };
          if (isPdf) {
            query.hideControls = isPdf;
          }

          this.$router.push({
            path: "/external-webview",
            state: {
              params: {
                url: this.getPdfMenuLink(menu),
                title: this.getPdfMenuName(menu)
              }
            },
            name: "ExternalWebview",
            query: query
          });
        }
      }
    }
  }
};

export default Mixin;
