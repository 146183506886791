import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import {
  checkStr,
  checkStrTrim,
  deleteYearFromMoment,
  findService,
  getImageUrl,
  getItemUrl,
  getString,
  getStringNoEmpty,
  isEmbedPWA,
  isFullEmbedPWA,
  isLogged,
  logBackButton,
  logExternalUrl,
  logGTMFrontEvent,
  openExternalURL,
  serviceTypes,
  translate
} from "../services/utils.js";
import { EventBus } from "../services/eventBus";
import config from "../config";
import { UPDATE_USER } from "../store/mutation-types";
import moment from "moment-timezone";
import cloneDeep from "lodash-es/cloneDeep";

const MessagePopup = defineAsyncComponent(() => import("@/components/MessagePopup.vue"));

const Mixin = {
  data() {
    return {
      showError: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState([
      "user",
      "strings",
      "isFromPWA",
      "userInbox",
      "isFirstLoad",
      "establishment",
      "mainColor",
      "embeddedReturnURL",
      "isDesktop",
      "isLobby",
      "isTV",
      "isWidget",
      "services",
      "establishmentHash",
      "verticalScreen",
      "verticalScreenWithVideo"
    ])
  },
  components: {
    "message-popup": MessagePopup
  },
  methods: {
    ...mapActions(["sendPostToUrl", "getService", "decodeEstablishmentHashAndStoreData"]),
    customSystemMessage(chatConfiguration) {
      if (!chatConfiguration || !chatConfiguration.waitingText) {
        return undefined;
      }

      const self = this;
      const timezone = this.establishment.timezone ?? "Europe/Madrid";

      const now = moment().tz(timezone);
      let todayDayOfWeek = parseInt(now.format("d")) - 1;

      if (todayDayOfWeek < 0) {
        todayDayOfWeek += 7;
      }

      let text = undefined;
      chatConfiguration.waitingText.forEach(({ startTurn, endTurn, translatableText, days }) => {
        const startsComponents = startTurn.split(":");
        const endsComponents = endTurn.split(":");

        const startTime = moment().tz(timezone).set({ hour: startsComponents[0], minute: startsComponents[1] });
        const endTime = moment().tz(timezone).set({ hour: endsComponents[0], minute: endsComponents[1] });

        if (parseInt(endsComponents[0]) < parseInt(startsComponents[0])) {
          endTime.add(1, "days");
        }

        if (now.isSameOrAfter(startTime) && now.isSameOrBefore(endTime) && days.indexOf(todayDayOfWeek) !== -1) {
          text = self.translate(translatableText);
        }
      });

      return text;
    },
    conciergeChatIsActive(chatConfiguration) {
      const systemMessage = this.customSystemMessage(chatConfiguration);
      return systemMessage !== undefined;
    },
    resetFromNative() {
      this.$router.replace("/");
    },
    pwaIsNotInitializedAndHasId() {
      return (
        ((!this.establishment.id || !config.cmsApiHostCredentials.bearer || !this.establishmentHash) &&
          this.$router.currentRoute.value.query.id) ||
        (this.establishmentHash &&
          this.$router.currentRoute.value.query.id &&
          this.establishmentHash !== this.$router.currentRoute.value.query.id) ||
        !this.user.userKey
      );
    },
    getDayOfWeek(dayNumber) {
      return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][dayNumber];
    },
    pwaIsNotInitialized() {
      return !this.establishment.id && !config.cmsApiHostCredentials.bearer && !this.establishmentHash;
    },
    hasHistory() {
      if (this.isWidget) {
        return false;
      }

      if (!this.embeddedReturnURL || !isEmbedPWA() || isFullEmbedPWA()) {
        return true;
      }

      return (
        this.embeddedReturnURL.replace(config.currentPWAApiHost, "") === "/" ||
        !this.$router.currentRoute.value.fullPath.includes(this.embeddedReturnURL.replace(config.currentPWAApiHost, ""))
      );
    },
    getMonthDayWeekText(event) {
      const locale = this.getLocale();
      moment.locale(locale);

      const start = moment(moment(event.startDate).format("YYYY-MM-DD")).format("ll");
      const end = moment(moment(event.endDate).format("YYYY-MM-DD")).format("ll");

      /*this code deletes the year in spanish and english*/
      const startString = deleteYearFromMoment(locale, start);
      const endString = deleteYearFromMoment(locale, end);

      return this.getString("FROM") + "" + startString + "" + this.getString("TO") + "" + endString;
    },
    getLocale() {
      let locale = "en";
      if (window.navigator.language) {
        locale = window.navigator.language.substring(0, 2);
      } else if (this.user.locale) {
        locale = this.user.locale;
      }
      return locale;
    },
    translate(translatable) {
      return translate(translatable, this.user);
    },
    translateEnglishOrDefault(translatable) {
      return translate(translatable, { locale: "en" });
    },
    translateEnglishOrDefaultForced(translatable) {
      return translate(translatable, { locale: "en" }, true);
    },
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    getStringNoEmpty(key) {
      return getStringNoEmpty(this.strings, key, this.user);
    },
    getStringUrl(key) {
      const string = this.strings.find(string => string.strKey === key);
      if (string && string.custom && string.custom[this.establishment.id]) {
        return this.translate(string.custom[this.establishment.id]);
      } else {
        return " ";
      }
    },
    getFormattedDate(d) {
      const date = new Date(d.substring(0, 10));
      return date.toLocaleString(this.getLocale(), { day: "2-digit", month: "2-digit", year: "2-digit" });
    },
    getFormattedDateWithWeekday(d) {
      const date = new Date(d.substring(0, 10));
      return date.toLocaleString(this.getLocale(), { weekday: "long", day: "2-digit", month: "short" });
    },
    getFormattedTime(d) {
      const date = new Date(d);
      return d.substring(11, 16);
    },
    userAgent() {
      return window.navigator.userAgent;
    },
    isAndroid() {
      const ua = this.userAgent();
      return ua.indexOf("Android") > -1 && /Chrome\/[.0-9]*/.test(ua) && ua.indexOf("Version") === -1;
    },
    isIos() {
      return /iphone|ipod|ipad/i.test(this.userAgent());
    },
    back(pageName) {
      if (pageName && typeof pageName === "string") {
        logBackButton(pageName);
      }
      if (this.$store.state.user.idLink) {
        this.$store.commit(UPDATE_USER, { idLink: false });
        if (history.state.back) {
          history.back();
          return;
        }
        this.$router.push({
          path: "/",
          query: {
            id: this.$route.query.id
          }
        });

        return;
      }

      this.$router.go(-1);
    },
    backRoute(pageName, category, subcategory) {
      if (pageName && typeof pageName === "string") {
        logBackButton(pageName, category, subcategory);
      }
      this.$router.go(-1);
    },
    isLogged: function () {
      return isLogged(this.user);
    },
    selectService(serviceId, serviceType, itemType) {
      let locale = "en";
      if (this.user.locale) {
        locale = this.user.locale;
      }
      const path = "/webview/";
      const params = {
        establishment: this.establishment.id,
        locale: locale,
        itemType: itemType ? itemType : 3,
        serviceType: serviceType ? serviceType : 0,
        id: serviceId,
        imageId: 0,
        psId: 0
      };

      this.$router.push({
        path: path,
        params: {
          itemType: params.itemType,
          serviceType: params.serviceType,
          id: params.id,
          imageId: params.imageId
        },
        state: { params: cloneDeep(params) },
        name: "ServiceDetail",
        query: { id: this.establishmentHash }
      });
    },

    toCamelCase: function (str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index == 0 ? match.toLowerCase() : match.toUpperCase();
      });
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toTitleCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    //now only save name room checkIn and checkOut. if we need more, add them
    updateUserAfterLogin: function (response) {
      if (
        response.establishments &&
        response.establishmentId &&
        response.establishments[response.establishmentId] &&
        (response.establishments[response.establishmentId].userType === "CLIENT" ||
          // response.establishments[response.establishmentId].userType === 'POSTCLIENT' ||
          response.establishments[response.establishmentId].userType === "PRECLIENT")
      ) {
        //we use this checks because as preclient, maybe we dont have room (clubmed), or a preclient without checkin
        let nameUser = response.establishments[response.establishmentId].name;
        let roomUser = response.establishments[response.establishmentId].room;
        let checkInUser = response.establishments[response.establishmentId].checkIn;
        let checkOutUser = response.establishments[response.establishmentId].checkOut;
        if (!checkStr(nameUser)) {
          nameUser = "";
        }
        if (!checkStr(roomUser)) {
          roomUser = "";
        }
        if (!checkStr(checkInUser)) {
          checkInUser = "";
        }
        if (!checkStr(checkOutUser)) {
          checkOutUser = "";
        }

        try {
          this.$store.commit(UPDATE_USER, {
            name: nameUser,
            room: roomUser,
            checkIn: checkInUser,
            checkOut: checkOutUser
          });
        } catch (e) {
          console.error("Error update login: ", e);
        }
      }
    },

    calculateMaxProductsAdded(addOnRows, product, row) {
      let currentQuantity = -1;
      if (row) {
        currentQuantity = row.quantity ? row.quantity : 0;
      }
      let quantityAddon = 0;
      const productId = product.id;

      if (addOnRows && addOnRows.length) {
        addOnRows.forEach(addon => {
          if (productId == addon.id) {
            quantityAddon = quantityAddon + addon.quantity;
          }
        });
      }
      const maxReached = quantityAddon === product.maxQuantityPerOrder;
      if (maxReached) {
        return currentQuantity;
      } else {
        return product.maxQuantityPerOrder;
      }
    },
    goReservationSummary({ id, description, product, photographs }) {
      if (id.indexOf("booking") !== -1) {
        const bookingId = parseInt(id.split(":")[0], 10);
        let booking = null;
        if (this.userInbox.bookings) {
          booking = this.userInbox.bookings[bookingId];
        }

        if (booking) {
          let locale = "en";
          if (this.user.locale) {
            locale = this.user.locale;
          }
          const path = "/transaction-confirmation/";
          booking.serviceName = description;
          booking.imageUrl = getImageUrl(photographs[0]);
          booking.serviceType = serviceTypes[booking.cmsModule];
          const params = {
            establishment: this.establishment.id,
            locale: locale,
            bookingData: booking,
            isBookingFunnel: false
          };

          this.$router.push({
            path: path,
            state: { params: cloneDeep(params) },
            name: "TransactionConfirmation",
            query: { id: this.establishmentHash }
          });
        }
      } else if (id.indexOf("lead") !== -1) {
        const leadId = parseInt(id.split(":")[0], 10);
        let lead = null;
        if (this.userInbox.requests) {
          lead = this.userInbox.requests[leadId];
        }

        if (lead) {
          let locale = "en";
          if (this.user.locale) {
            locale = this.user.locale;
          }
          const path = "/transaction-confirmation/";
          lead.serviceName = description;
          lead.imageUrl = getImageUrl(product.photographs[0]);
          lead.itemName = product.translatableName[locale];
          lead.itemNameEnglish = this.translateEnglishOrDefault(product.translatableName);
          lead.serviceType = serviceTypes[lead.cmsModule];
          const params = {
            establishment: this.establishment.id,
            locale: locale,
            requestData: lead,
            isBookingFunnel: false
          };

          this.$router.push({
            path: path,
            state: { params: cloneDeep(params) },
            name: "TransactionConfirmation",
            query: { id: this.establishmentHash }
          });
        }
      } else if (id.indexOf("order") !== -1) {
        const orderId = parseInt(id.split(":")[0], 10);
        let order = null;
        if (this.userInbox.orders) {
          order = this.userInbox.orders[orderId];
        }

        if (order) {
          let locale = "en";
          if (this.user.locale) {
            locale = this.user.locale;
          }
          const path = "/transaction-confirmation/";
          order.serviceName = description;

          const params = {
            establishment: this.establishment.id,
            locale: locale,
            orderData: order,
            isBookingFunnel: false
          };

          this.$router.push({
            path: path,
            state: { params: cloneDeep(params) },
            name: "TransactionConfirmation",
            query: { id: this.establishmentHash }
          });
        }
      }
    },
    /* not used by the moment*/
    getIconAssetsUrl(key, fromAssets) {
      if (!fromAssets) {
        return "public/img/" + key; //test it if we use public/img.
      } else {
        return "../assets/" + key;
      }
    },

    itemHourString(item) {
      if (item && item.starts && item.ends) {
        if (item.starts.substr(0, 5) != "00:00" || item.ends.substr(0, 5) != "23:59") {
          return item.starts.substr(0, 5) + "-" + item.ends.substr(0, 5);
        } else {
          return this.getString("OPEN_24H");
        }
      }
      return "";
    },
    getSurveyItemId(idSurvey, firstId, originalItemId) {
      let itemId = firstId;
      let alerts = this.userInbox && this.userInbox.alerts ? this.userInbox.alerts : false;
      var alertsDictionary = alerts;
      var keys = Object.keys(alertsDictionary);
      var values = keys.map(function (v) {
        return alertsDictionary[v];
      });
      alerts = values;
      const idAlertAssociated = -1;
      let idAlert = -1;
      if (alerts) {
        alerts.forEach(alert => {
          if (idSurvey == alert.itemId) {
            idAlert = alert.id;
          }
        });
      }

      if (idAlert && idAlert != -1) {
        let serviceIdAux = -1;
        const bookings = this.userInbox && this.userInbox.bookings ? this.userInbox.bookings : false;
        if (bookings) {
          const keys = Object.keys(bookings);
          keys.forEach(key => {
            if (bookings[key].alertSurvey) {
              if (bookings[key].alertSurvey == idAlert) {
                itemId = bookings[key].service;
                serviceIdAux = itemId;
              }
            }
          });
        }

        if (serviceIdAux == -1) {
          const requests = this.userInbox && this.userInbox.requests ? this.userInbox.requests : false;
          if (requests) {
            const keys = Object.keys(requests);
            keys.forEach(key => {
              if (requests[key].alertSurvey) {
                if (requests[key].alertSurvey == idAlert) {
                  itemId = requests[key].service;
                  serviceIdAux = itemId;
                }
              }
            });
          }
        }

        if (serviceIdAux == -1) {
          const orders = this.userInbox && this.userInbox.orders ? this.userInbox.orders : false;
          if (orders) {
            const keys = Object.keys(orders);
            keys.forEach(key => {
              if (orders[key].alertSurvey) {
                if (orders[key].alertSurvey == idAlert) {
                  itemId = orders[key].service;
                  serviceIdAux = itemId;
                }
              }
            });
          }
        }
      }

      //default values
      if (!itemId) {
        itemId = firstId;
      }
      if (!itemId) {
        itemId = originalItemId;
      }

      return itemId;
    },
    lightenDarkenColor(col, amt) {
      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
    noProductType(product) {
      return product && (!product.productTypes || Object.keys(product.productTypes).length === 0);
    },
    onlyOneProductType(product) {
      return (
        product.productTypes &&
        Object.keys(product.productTypes).length &&
        Object.keys(product.productTypes).length === 1
      );
    },
    moreThanOneProductType(product) {
      return (
        product.productTypes && Object.keys(product.productTypes).length && Object.keys(product.productTypes).length > 1
      );
    },
    oneProductTypeDescriptionNotEmptyOrDefault(product, productType) {
      if (!productType) {
        const productTypes = product.productTypes;
        if (!productTypes || !Object.values(productTypes) || Object.values(productTypes).length === 0) {
          return false;
        }

        let retValue = false;
        Object.values(productTypes).forEach(pt => {
          if (
            pt &&
            pt.description &&
            this.translate(pt.description) !== "" &&
            checkStrTrim(this.translate(pt.description)) &&
            this.translate(pt.description) !== "Default" &&
            this.translate(pt.description) !== "default" &&
            this.translate(pt.description) !== "Normal"
          ) {
            retValue = true;
          }
        });
        return retValue;
      }
      const description = productType.description;
      if (!description) {
        return false;
      }
      const translation = this.translate(description);
      if (translation === "Default" || translation === "default" || translation === "Normal" || translation === "") {
        return false;
      }
      return true;
    },
    checkProductDuration(productDetail) {
      if (!productDetail) {
        return false;
      }
      if (productDetail.hasOwnProperty("duration") && productDetail.duration > 0) {
        return true;
      }

      let hasDuration = false;
      if (productDetail.addOns) {
        productDetail.addOns.forEach(addon => {
          if (addon.type === "duration") {
            hasDuration = true;
            return true;
          } else if (addon.options && addon.options.length > 0 && addon.options[0].hasOwnProperty("duration")) {
            hasDuration = true;
            return true;
          }
        });
      }
      if (hasDuration) {
        return true;
      }

      return false;
    },
    oneProductTypeWithOffer(product) {
      return (
        product?.productTypes &&
        Object.keys(product.productTypes).length === 1 &&
        product.productTypes[Object.keys(product.productTypes)[0]].priceOld
      );
    },
    oneProductTypeWithoutOffer(product) {
      const off =
        product?.productTypes &&
        Object.keys(product.productTypes).length === 1 &&
        !product.productTypes[Object.keys(product.productTypes)[0]]?.priceOld;
      return off;
    },
    oneProductTypeDescriptionNotEmptyOrNormal(product, productType, noCheckNormal) {
      if (!productType) {
        const productTypes = product.productTypes;
        if (!productTypes || !Object.values(productTypes) || Object.values(productTypes).length === 0) {
          return false;
        }

        let retValue = false;
        Object.values(productTypes).forEach(pt => {
          if (
            pt &&
            pt.description &&
            this.translate(pt.description) !== " " &&
            this.translate(pt.description) !== "" &&
            (!noCheckNormal && this.translate(pt.description)) !== "Normal"
          ) {
            retValue = true;
          }
        });

        return retValue;
      }
      const description = productType.description;
      if (!description) {
        return false;
      }
      const translation = this.translate(description);
      if ((!noCheckNormal && translation === "Normal") || translation === "") {
        return false;
      }
      return true;
    },
    oneProductTypeDescriptionNotEmpty(product, productType) {
      return this.oneProductTypeDescriptionNotEmptyOrNormal(product, productType, true);
    },
    getOnlyPrice(product) {
      if (!product.productTypes || Object.keys(product.productTypes).length === 0) {
        return "";
      }
      return product.productTypes[Object.keys(product.productTypes)[0]].price;
    },
    getPriceOld(product) {
      if (!product.productTypes || Object.keys(product.productTypes).length === 0) {
        return null;
      }
      return product.productTypes[Object.keys(product.productTypes)[0]].priceOld;
    },
    getOfferTitle(product) {
      if (!product.productTypes || Object.keys(product.productTypes).length === 0) {
        return null;
      }
      return this.translate(product.productTypes[Object.keys(product.productTypes)[0]].offerTitle);
    },
    getTitleSpaProduct() {
      if (this.serviceIsBookable) {
        return "product-type-title";
      } else {
        return "product-type-title-no-reserve";
      }
    },
    getTitleSpa(product) {
      if (this.moreThanOneProductType(product)) {
        return "product-title-only";
      } else {
        if (this.serviceIsBookable) {
          if (this.getPriceOld(product)) {
            return "product-title";
          } else {
            return "product-title-old-price";
          }
        } else {
          if (this.getPriceOld(product)) {
            return "product-title-no-reserve";
          } else {
            return "product-title-old-price-no-reserve";
          }
        }
      }
    },

    urlBubbleTapped: function (data, userData) {
      this.getService({ serviceId: data.serviceId }).then(service => {
        if (service && service.website) {
          this.$router.push({
            path: "/external-webview",
            state: {
              params: {
                url: service.website,
                title: this.translate(service.translatableName)
              }
            },
            name: "ExternalWebview"
          });
          logExternalUrl(service.website, "/", "frontpage", "home");
        }
      });
    },
    customBubbleTapped: function (data, userData) {
      this.getService({ serviceId: data.serviceId }).then(service => {
        if (
          service &&
          data.itemType === 3 &&
          (service.website || service.phone || service.email || this.translate(service.translatableDescription))
        ) {
          const desktopURL = this.isDesktop ? "/desktop" : this.isLobby ? "/lobby" : this.isTV ? "/tv" : "";
          const url = desktopURL + "/webview/3/custom/" + service.id + "/" + 0;
          this.$router.push({
            path: url,
            query: { bubbleName: this.translate(data.translatableName), id: this.establishmentHash }
          });
        } else {
          this.$router.push({
            path: getItemUrl(
              data.itemType,
              data.itemId,
              data.serviceType,
              userData,
              data.cmsModule,
              data.serviceId,
              this.isLobby,
              this.isDesktop,
              this.isTV,
              data.calendarType
            ),
            query: { bubbleName: this.translate(data.translatableName), id: this.establishmentHash }
          });
        }
      });
    },

    setItemUrl(data, index, title) {
      const userData = this.user;
      userData.establishmentId = this.establishment.id;
      userData.locale = this.locale;
      userData.id = userData.uid;

      if (data.website && (data.website.length || typeof data.website === "object")) {
        let url = false;
        if (typeof data.website === "string") {
          url = data.website;
        } else if (typeof data.website === "object") {
          url = this.translate(data.website);
        }

        if (data.openWebsiteInBrowser) {
          openExternalURL(url, "_blank");
        } else {
          this.$router.push({
            path: "/external-webview",
            state: {
              params: {
                url: url,
                title: this.translate(data.translatableName),
                fullscreen: true
              }
            },
            name: "ExternalWebview"
          });
        }
        return;
      }

      if (data.cmsModule === 42) {
        this.urlBubbleTapped(data, userData);
        return;
      }

      if (data.cmsModule === 43) {
        this.customBubbleTapped(data, userData);
        return;
      }

      if (data.cmsModule === 72 && this.user && this.isLogged(this.user)) {
        if (this.isDesktop) {
          this.hasPanel = !this.hasPanel;
          this.hasInbox = false;
        } else {
          this.$router.push("/guest-panel");
        }
        return;
      }

      if (data.cmsModule === 97) {
        EventBus.$emit("enquiriesButtonAction", { itemType: 15, itemId: this.establishment.id, title: title });
        return;
      }

      if (data.cmsModule === 75) {
        //showDigitalKey method is in frontpageMixin, it cant be accessed from lateralmenu and we will get an error
        this.showDigitalKey();
        return;
      }

      const path = getItemUrl(
        data.itemType,
        data.itemId,
        data.serviceType,
        userData,
        data.cmsModule,
        data.serviceId,
        this.isLobby,
        this.isDesktop,
        this.isTV,
        data.calendarType
      );

      this.$router.push({
        path,
        query: { bubbleName: this.translate(data.translatableName), id: this.establishmentHash }
      });
    },

    bubbleTapped: function ({ data, index, title, bubbleType, bubbleIndex, logTitle }) {
      this.sendFrontEvent({ data, index, title, bubbleType, bubbleIndex, logTitle });
      this.setItemUrl(data, index, title);
    },

    sendFrontEvent: function ({ data, index, title, bubbleType, bubbleIndex, logTitle }) {
      const ind = parseInt(index) + 1;
      let bubbleIndexLabel = "S1" + "B" + ind;

      if (logTitle && logTitle.length) {
        title = logTitle;
      }
      if (
        bubbleType === "box-bubble-child" ||
        bubbleType === "floating-bubble" ||
        bubbleType === "shortcut-child" ||
        bubbleType === "upsell-child"
      ) {
        bubbleIndexLabel = bubbleIndex;
      }

      logGTMFrontEvent("/", "frontpage", "home", bubbleType, title, bubbleIndexLabel);
    },

    getImageUrlChat(service) {
      if (service.serviceType) {
        const theService = findService(service.itemId, null, this.services);
        if (theService && theService.photographs && theService.photographs.length) {
          return getImageUrl(theService.photographs[0], "_appthumb", "_appthumb");
        } else if (this.user.activity.leads) {
          const activityList = this.user.activity.leads.find(item => item.serviceId === service.serviceId);
          if (activityList && activityList.product.photographs && activityList.product.photographs.length) {
            return getImageUrl(activityList.product.photographs[0], "_appthumb", "_appthumb");
          }
        }
      } else {
        let backgroundImage = undefined;
        if (this.$store.state.frontpage.data && this.$store.state.frontpage.data.backgroundImage) {
          backgroundImage = this.$store.state.frontpage.data.backgroundImage;
        }
        if (this.isDesktop && this.$store.state.frontpage.data.desktopBackgroundImage) {
          backgroundImage = this.$store.state.frontpage.data.desktopBackgroundImage;
        }
        return getImageUrl(backgroundImage, true, "_webthumb");
      }
    }
  }
};

export default Mixin;
