import axios from "axios";
import { sendRequestErrorToSlack } from "@/services/utils";
import { setupCache } from "axios-cache-interceptor";

const instance = axios.create({
  timeout: 25000
});
const service = setupCache(instance, {
  interpretHeader: false,
  cacheTakeover: false
});

service.interceptors.response.use(
  response => {
    const responseData = response.data;
    const resBody = responseData ? responseData.data : false;
    // Status not 200
    if (response && response.status !== 200) {
      sendRequestErrorToSlack(response);
      return Promise.reject(response);
    }
    // Status 200
    else if (
      (resBody && resBody.errors && resBody.errors.length) ||
      (responseData && responseData.errors && responseData.errors.length)
    ) {
      // Requests status 200 but Error found in response body
      if (resBody && resBody.errors && resBody.errors.length) {
        sendRequestErrorToSlack(response);
        return Promise.reject(resBody.errors);
      } else {
        sendRequestErrorToSlack(response);
        return Promise.reject(responseData.errors);
      }
    } else {
      // Success response
      return response;
    }
  },
  error => {
    const response = error.response;
    const specificErrors = response?.data?.errors || response?.data?.error || false;
    const responseError = specificErrors ? specificErrors : error;

    sendRequestErrorToSlack(response);
    return Promise.reject(responseError);
  }
);

export const request = data => {
  return new Promise((resolve, reject) => {
    service.request(data).then(resolve).catch(reject);
  });
};

export default service;
