import { getAuthBearer } from "../auth";

import config from "../../config.js";
import * as stayRequest from "../request";

const surveysHeaders = state => {
  return {
    "Content-Type": "application/json",
    "Stay-Version": "3",
    Authorization: "Bearer " + getAuthBearer(state),
    "Stay-Authorization": state.user.userKey,
    "Stay-Establishment": state.clusterEstablishmentId
  };
};

const sendCommentToSurveyChat = (state, success, fail, data) => {
  const userKey = state.user.userKey;
  const baseUrl = `${config.mobileApiHost}/v1/requests/chat`;
  const url = `${baseUrl}?userkey=${userKey}`;

  return stayRequest
    .request({
      method: "post",
      url: url,
      headers: { "Content-Type": "application/json" },
      params: {
        apikey: config.apiKey,
        establishment: state.establishment.id,
        devicekey: state.user.deviceKey
      },
      data: data
    })
    .then(success)
    .catch(fail);
};

const getSurveyChatMessages = (state, success, fail, data) => {
  const baseUrl = `${config.mobileApiHost}/v1/requests/chat`;
  const url = `${baseUrl}/itemType/${data.itemType}/itemId/${data.itemId}?userkey=${state.user.userKey}`;

  return stayRequest
    .request({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json"
      },
      params: {
        apikey: config.apiKey,
        establishment: state.establishment.id,
        devicekey: state.user.deviceKey
      },
      data: data,
      cache: false
    })
    .then(success)
    .catch(fail);
};

const getQuestionnaires = (state, success, fail) => {
  const url = `${config.clusterHost}/survey/user/questionnaires`;

  return stayRequest
    .request({
      method: "get",
      url: url,
      headers: surveysHeaders(state),
      cache: false
    })
    .then(success)
    .catch(fail);
};

const getReplies = (state, success, fail) => {
  const url = `${config.clusterHost}/survey/user/replies`;

  return stayRequest
    .request({
      method: "get",
      url: url,
      headers: surveysHeaders(state),
      cache: false
    })
    .then(success)
    .catch(fail);
};

const sendSurvey = (state, success, fail, data) => {
  const url = `${config.clusterHost}/survey/user/questionnaire/${data._id}`;

  return stayRequest
    .request({
      method: "post",
      url: url,
      data: data.body,
      headers: surveysHeaders(state)
    })
    .then(success)
    .catch(fail);
};

const getDisplaySurvey = (state, success, fail) => {
  return stayRequest
    .request({
      method: "get",
      url: `${config.clusterHost}/survey/user/tracking`,
      headers: surveysHeaders(state),
      cache: false
    })
    .then(success)
    .catch(fail);
};

const sendDisplaySurvey = (state, success, fail, data) => {
  const url = `${config.clusterHost}/survey/user/tracking/${data.id}`;

  return stayRequest
    .request({
      method: "post",
      url: url,
      data: data.data,
      headers: surveysHeaders(state)
    })
    .then(success)
    .catch(fail);
};

const getExternalReviewsConfiguration = (state, success, fail, establishmentId) => {
  const url = `${config.clusterHost}/survey/external-reviews-configuration/${establishmentId}`;

  return stayRequest
    .request({
      method: "get",
      url: url,
      headers: surveysHeaders(state),
      cache: false
    })
    .then(success)
    .catch(fail);
};

export {
  getQuestionnaires,
  getReplies,
  sendSurvey,
  getExternalReviewsConfiguration,
  getSurveyChatMessages,
  sendCommentToSurveyChat,
  getDisplaySurvey,
  sendDisplaySurvey
};
