<template>
  <div class="bubble-wrapper bubble-element" :style="'background: #' + data.color + ''" @click="action">
    <div class="icon" :style="'background: url(' + getImageUrl(bubbleItem.icon, true) + ')'"></div>
    <div class="title">{{ getTitle() }}</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getImageUrl, translate } from "@/services/utils.js";
import mixin from "@/services/mixin";

export default {
  mixins: [mixin],
  props: ["data", "bubbleItem", "locale", "index"],
  emits: ["action"],
  data() {
    return {};
  },
  created() {},
  methods: {
    getImageUrl,
    getTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? translate(this.bubbleItem.translatableName, { locale: this.locale })
        : "";
    },
    getEnglishTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? this.translateEnglishOrDefault(this.bubbleItem.translatableName, this.user)
        : "";
    },
    action: function () {
      this.$emit("action", {
        data: this.bubbleItem,
        index: this.index,
        title: this.getEnglishTitle(),
        bubbleType: "bubble-parent"
      });
    }
  }
};
</script>

<style scoped>
.bubble-wrapper {
  height: 6.8em;
  width: 6.8em;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.85;
  position: relative;
}

.bubble-wrapper img {
  max-height: 2.3em;
  width: 2.6em;
  margin-top: 1.2em;
  text-align: center;
}

.posChild-1 {
  position: absolute;
  bottom: -6.5em;
  right: -11em;
  z-index: 1;
}

.posChild-2 {
  position: absolute;
  top: -6.8em;
  right: -15.2em;
  z-index: 1;
}

.posChild-3 {
  position: absolute;
  top: -13.4em;
  right: -11em;
  z-index: 1;
}

.pos1 .posChild-1,
.pos2 .posChild-1 {
  right: -2.8em;
}

.pos1 .posChild-2,
.pos2 .posChild-2 {
  right: 1.2em;
}

.pos1 .posChild-3,
.pos2 .posChild-3 {
  right: -2.8em;
}

.three .bubble-element:nth-child(3) .posChild-1 {
  right: -11em !important;
}
.three .bubble-element:nth-child(3) .posChild-2 {
  right: -15.2em !important;
}
.three .bubble-element:nth-child(3) .posChild-3 {
  right: -11em !important;
}

.icon {
  width: 2.3em;
  height: 2.3em;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  margin-top: 1em;
  display: inline-block;
}

.title {
  color: white;
  font-size: 0.85em;
  font-weight: 600;
  padding: 0 0.8em;
  width: 7em;
  display: table-cell;
  vertical-align: bottom;
  bottom: 0.3em;
  position: relative;
  height: 2em;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #35495e;
}
</style>
