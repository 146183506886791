const config = {};

//stay pwa
config.baseApiKey = "a97e0983e8fe50d72ca0d6782912d70a";
config.baseAppId = 76;

//melia pwa
// config.baseApiKey = "d2e0ac2976be6e905ba66a75e255f587";
config.meliaAppId = 91;
config.riuAppId = 107;
config.stayEmbedAppId = 113;

config.lobbyApiKey = "167e53d884aafeb0513b43a4b272e4fc";
config.lobbyAppId = 49;

config.apiKey = config.baseApiKey;
config.appId = config.baseAppId;

config.mobileApiHost = import.meta.env.VITE_VUE_APP_MOBILE_API;
config.imageHost = import.meta.env.VITE_VUE_APP_IMG_API;
config.pdfHost = import.meta.env.VITE_VUE_APP_PDF_API;
config.cmsApiHost = import.meta.env.VITE_VUE_APP_CMS_API;
config.loginPmsHost = import.meta.env.VITE_VUE_APP_LOGIN_PMS_HOST;

config.apiHost = import.meta.env.VITE_VUE_APP_API;
config.twilioSystemEndpoint = "system";
config.currentPWAApiHost = import.meta.env.VITE_VUE_APP_PWA_HOST;
config.elasticHost = import.meta.env.VITE_VUE_APP_ELASTIC_HOST;

config.clusterHost = import.meta.env.VITE_VUE_APP_CLUSTER_HOST;

config.cmsApiHostCredentials = {
  bearer: ""
};
config.botApiCredentials = {
  bearer: ""
};
config.clusterApiCredentials = {
  bearer: ""
};
config.applicationServer = {
  PublicKey: import.meta.env.VITE_VUE_APP_SERVER_PUBLIC_KEY
};

config.enableVuexLogger = import.meta.env.VITE_VUE_APP_ENABLE_VUEX_LOGGER
  ? import.meta.env.VITE_VUE_APP_ENABLE_VUEX_LOGGER
  : false;

export default config;
