import StayTrackerService from "@/services/stayTracker/StayTrackerService";
import { getClusterToken } from "@/services/auth";

const stayTrackerService = new StayTrackerService();
const state = {
  basePayload: undefined,
  bearer: undefined
};

const actions = {
  async updateToken({ rootState, commit }) {
    const clusterToken = await getClusterToken(rootState.establishment.id);
    commit("setBearer", clusterToken.access_token);
  },

  async init({ rootState, state, commit, dispatch }) {
    const payload = {
      appId: rootState.user.appId,
      establishment: rootState.establishment.id,
      deviceUid: rootState.deviceUid
    };
    if (rootState?.user?.id) {
      payload.userId = rootState.user.id;
    } else if (rootState?.user?.uid) {
      payload.userId = rootState.user.uid;
    }
    commit("setBasePayload", payload);

    if (state.bearer == null) {
      await dispatch("updateToken");
    }

    return payload;
  },

  async sendEvent({ dispatch, state }, { module, eventType, event, payload }) {
    try {
      if (!module || !eventType || !event) {
        throw new Error("Missing the required param: module, eventType or event");
      }

      await dispatch("init");
      const response = await stayTrackerService.sendStayEvent({
        eventModule: module,
        event,
        eventType,
        payload: { ...payload, ...state.basePayload },
        bearer: state.bearer
      });

      return response;
    } catch (error) {
      if (error.message === "UNAUTHORIZED") {
        await dispatch("updateToken");
        throw Error("TRY_AGAIN");
      }

      return error;
    }
  },

  async viewEvent({ rootState, dispatch, state }, { module, eventType, event, payload }) {
    try {
      await dispatch("init");
      const response = await stayTrackerService.viewEvent({
        eventModule: module,
        event,
        eventType,
        payload: { ...payload, ...state.basePayload },
        bearer: state.bearer
      });

      return response;
    } catch (error) {
      if (error.message === "UNAUTHORIZED") {
        await dispatch("updateToken");
      }

      console.error("Error sending stay event", error);
      return error;
    }

    return await new Promise((resolve, reject) => {});
  },

  async clickEvent({ dispatch, state }, { module }) {
    await dispatch("init");

    return await new Promise((resolve, reject) => {
      stayTrackerService.clickEvent(
        response => resolve(response),
        async error => {
          if (error[0] === "UNAUTHORIZED") {
            await dispatch("updateToken");
          }

          console.error("Error sending stay event", error);
          reject(error);
        },
        { event, eventModule: module, payload: { ...state.basePayload }, bearer: state.bearer }
      );
    });
  }
};

// mutations
const mutations = {
  setBasePayload(state, payload) {
    state.basePayload = payload;
  },
  setBearer(state, payload) {
    state.bearer = payload;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
