<template>
  <div class="frontpage-column" :class="[index > 0 ? 'not-first' : '']">
    <div class="column-title" :style="{ color: data.textColor ? data.textColor : '' }">
      {{ getTitle() }}
    </div>

    <div v-if="bubbleItem.children && bubbleItem.children.length > 0" class="frontpage-slide-row">
      <slot v-for="(item, bubbleIndex) in bubbleItem.children">
        <frontpage-v4-item
          :key="'item-' + index + '-' + bubbleIndex"
          :bubble-item="item"
          :data="data"
          :locale="locale"
          :class-name="index === 0 ? 'first-row' : ''"
          num-items="1"
          item-row="1"
          :index="bubbleIndex"
          @action="action"
        ></frontpage-v4-item>
        <div v-if="bubbleIndex === bubbleItem.children.length - 1" :key="'gap' + index" class="right-gap"></div>
      </slot>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { translate } from "@/services/utils.js";
const FrontpageV4Item = defineAsyncComponent(() => import("./FrontpageV4Item.vue"));
import mixin from "@/services/mixin";

export default {
  components: {
    FrontpageV4Item
  },
  mixins: [mixin],
  props: ["data", "bubbleItem", "index", "locale"],
  emits: ["action"],
  data() {
    return {};
  },
  created() {},
  methods: {
    getTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? translate(this.bubbleItem.translatableName, { locale: this.locale })
        : "";
    },
    action: function ({ index, title, logTitle }) {
      var selectedItem =
        typeof index === "undefined" || this.bubbleItem.children.length == 0
          ? this.bubbleItem
          : this.bubbleItem.children[index];

      let section = 1;
      if (this.index != undefined) {
        section = parseInt(this.index) + 1;
      }
      const ind = parseInt(index) + 1;
      const label = "S" + section + "B" + ind; // index + 1 + " de " + this.bubbleItem.children.length

      this.$emit("action", {
        data: selectedItem,
        index: this.index + "_" + (index + 1),
        title: title,
        logTitle: logTitle,
        bubbleType: "box-bubble-child",
        bubbleIndex: label //index + 1 + " de " + this.bubbleItem.children.length
      });
    }
  }
};
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import (reference) "../../../assets/styleguide.less";

.frontpage-column {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  text-align: center;
  &.not-first {
    margin-top: 8px;
  }
}

.column-title {
  text-transform: none;
  display: flex;
  flex: 0 0 100%;
  .h3-black;
}
.frontpage-slide-row {
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: calc(100% + 8px);
  overflow-x: scroll;
  margin-top: 10px;
  margin-left: -8px;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
.right-gap {
  width: 1px;
  height: 1px;
  flex: 1px 0 0;
}
</style>
