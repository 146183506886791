import { createRouter, createWebHistory } from "vue-router";

const UpSellNotificationScreen = () => import("@/components/Promotions/PromotionsScreen.vue");
const FrontpageFactory = () => import("@/components/FrontpageFactory.vue");
const WidgetsFrontpage = () => import("@/components/WidgetsFrontpage/WidgetsHome.vue");
const LoginComponent = () => import("@/components/LoginComponent.vue");
const ServiceList = () => import("@/components/ServiceList.vue");
const ServiceDetail = () => import("@/components/Web.vue");
const RequestsProductList = () => import("@/components/RequestsProductList.vue");
const CalendarList = () => import("@/components/CalendarList.vue");
const Activities = () => import("@/components/Activities.vue");
const Map = () => import("@/components/Map.vue");
const ActivityDetail = () => import("@/components/ActivityDetail.vue");
const ActivityTimetable = () => import("@/components/ActivityTimetable.vue");
const BookingForm = () => import("@/components/BookingForm.vue");
const OrderSummary = () => import("@/components/OrderSummary.vue");
const SpacesOrders = () => import("@/components/Spaces/SpacesOrders.vue");
const OrderSummaryAddOns = () => import("@/components/OrderSummaryAddOns.vue");
const OrderingFunnel = () => import("@/components/OrderingFunnel");
const TransactionConfirmation = () => import("@/components/TransactionConfirmation.vue");
const SurveyV3Chat = () => import("@/components/SurveysV3/SurveyChat.vue");
const Inbox = () => import("@/components/Inbox.vue");
const GuestPanel = () => import("@/components/GuestPanel.vue");
const AlertDetail = () => import("@/components/AlertDetail.vue");
const Shows = () => import("@/components/Shows.vue");
const ShowsEventCalendar = () => import("@/components/ShowsEventCalendar.vue");
const RedirectionEventCalendar = () => import("@/components/RedirectionEventCalendar.vue");
const LandscapeRedirectionEventCalendar = () => import("@/components/Landscape/LandscapeRedirectionEventCalendar.vue");
const ExternalWebview = () => import("@/components/ExternalWebview.vue");
const ParksWaitTimes = () => import("@/components/ParksWaitTimes.vue");
//Congress
const CongressSumary = () => import("@/components/Congress/CongressSumary.vue");
const CongressSponsorsList = () => import("@/components/Congress/CongressSponsorsList.vue");
const CongressSpeakersList = () => import("@/components/Congress/CongressSpeakersList.vue");
const CongressScheduleList = () => import("@/components/Congress/CongressScheduleList.vue");
const CongressSponsorsDetail = () => import("@/components/Congress/CongressSponsorsDetail.vue");
const CongressSpeakersDetail = () => import("@/components/Congress/CongressSpeakersDetail.vue");
const CongressScheduleDetail = () => import("@/components/Congress/CongressScheduleDetail.vue");
const LandscapeCongressGrid = () => import("@/components/Landscape/LandscapeCongressGrid.vue");
const LandscapeCongressDetail = () => import("@/components/Landscape/LandscapeCongressDetail.vue");
const LandscapeCongressDetailWhite = () => import("@/components/Landscape/LandscapeCongressDetailWhite.vue");
const LandscapeCongressScheduleList = () => import("@/components/Landscape/LandscapeCongressActivities.vue");
// Landscape
const TVFrontpageFactory = () => import("@/components/Landscape/TVFrontpageFactory.vue");
const TVServiceList = () => import("@/components/Landscape/TVServiceList.vue");
const TVServiceListGrid = () => import("@/components/Landscape/TVServiceListGrid.vue");
const TVServiceDetail = () => import("@/components/Landscape/TVServiceDetail.vue");
const TVServiceDetailRoomService = () => import("@/components/Landscape/TVServiceDetailRoomService.vue");
const TVServiceDetailSpa = () => import("@/components/Landscape/TVServiceDetailSpa.vue");
const LandscapeShowsEventsList = () => import("@/components/Landscape/LandscapeShowsEventsList.vue");
const LandscapeShowsEventsGrid = () => import("@/components/Landscape/LandscapeShowsEventsGrid.vue");
const LandscapeActivitiesList = () => import("@/components/Landscape/LandscapeActivitiesList.vue");
const LandscapeActivitiesListWhite = () => import("@/components/Landscape/LandscapeActivitiesListWhite.vue");
const MyEvents = () => import("@/components/MyEvents.vue");

const FullSchedule = () => import("@/components/FullSchedule.vue");
const ServiceCatalogue = () => import("@/components/ServiceCatalogue.vue");
const ServiceCatalogueV2 = () => import("../../../shared/src/views/Catalogues/ServiceCatalogueV2.vue");
const ElasticSearchResultsList = () => import("@/components/ElasticSearchResultsList.vue");
const SmartSearch = () => import("@/components/SmartSearch.vue");
const ServiceGrouping = () => import("@/components/Grouping/ServiceGrouping.vue");
const LandscapeGrouping = () => import("@/components/Landscape/LandscapeGrouping.vue");
const ServiceGroupingRedirect = () => import("@/components/Grouping/ServiceGroupingRedirect.vue");
const ServiceWithConfigurableProducts = () =>
  import("@/components/ConfigurableProducts/ServiceWithConfigurableProducts.vue");
const DKEmail = () => import("@/components/DigitalKey/DKEmail.vue");
const DKPhoneNumber = () => import("@/components/DigitalKey/DKPhoneNumber.vue");
const DKKeysList = () => import("@/components/DigitalKey/DKKeysList.vue");
const DKKeyDetail = () => import("@/components/DigitalKey/DKKeyDetail.vue");
const DKFormulary = () => import("@/components/DigitalKey/DKFormulary.vue");
const DKErrorScreen = () => import("@/components/DigitalKey/DKErrorScreen.vue");
const DKKeysRedirection = () => import("@/components/DigitalKey/DKKeysRedirection.vue");
const DKKeysRedirectionReload = () => import("@/components/DigitalKey/DKKeysRedirectionReload.vue");
const Weather = () => import("@/components/Weather.vue");
const FullOrder = () => import("@/components/TransactionConfirmationOrderDetail.vue");
const EnquiriesList = () => import("@/components/Enquiries/EnquiriesList.vue");
const EnquiriesChat = () => import("@/components/Enquiries/EnquiriesChat.vue");
const AddMemberForm = () => import("@/components/Miniclub/AddMemberForm.vue");
const MembersList = () => import("@/components/Miniclub/MembersList.vue");
const MemberDetail = () => import("@/components/Miniclub/MemberDetail.vue");
const CityGuide = () => import("@/components/CityGuide/CityGuide.vue");
const CityGuideBookmarkNote = () => import("@/components/CityGuide/CityGuideBookmarkNote.vue");
const LandscapeCityGuide = () => import("@/components/Landscape/LandscapeCityGuide.vue");
const Directory = () => import("@/components/Directory/Directory.vue");
const LandscapeDirectory = () => import("@/components/Directory/LandscapeDirectory.vue");
const BookingAvailability = () => import("@/views/BookingForm/BookingAvailability.vue");
const PaymentResult = () => import("@/components/Payments/PaymentResult.vue");
const ProfileAllActivity = () => import("@/components/WidgetsFrontpage/MainComponents/ProfileAllActivity.vue");
const ProfileLegalLinks = () => import("@/components/WidgetsFrontpage/MainComponents/ProfileLegalLinks.vue");
const CampaignLanding = () => import("@/components/Campaigns/CampaignLanding");

export default createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: [
    {
      path: "/",
      name: "Frontpage",
      component: FrontpageFactory
    },
    {
      path: "/widgets-frontpage",
      name: "WidgetsFrontpage",
      component: WidgetsFrontpage
    },
    {
      path: "/login",
      name: "Login",
      props: () => history.state?.params,
      component: LoginComponent
    },
    {
      path: "/service-list/:serviceType",
      name: "ServiceList",
      component: ServiceList,
      props: true
    },
    {
      path: "/my-events",
      name: "MyEvents",
      component: MyEvents
    },
    {
      path: "/service-list/:serviceType/:preSelectedCategory",
      name: "ServiceListPreselected",
      component: ServiceList,
      props: true
    },
    {
      path: "/service-list/multi-establishment/:serviceType",
      name: "ServiceListMulti",
      component: ServiceList,
      props: true
    },
    {
      path: "/service-list/multi-establishment/list",
      name: "ServiceListMultiList",
      component: ServiceList,
      props: true
    },
    {
      path: "/item-list/:serviceId/:serviceType",
      name: "ItemList",
      component: ServiceList,
      props: true
    },
    {
      path: "/external-services-list/:cmsModule",
      name: "ExternalServicesList",
      component: ServiceList
    },
    {
      path: "/item-list/:serviceId/:preSelectedCategory/:serviceType",
      name: "ItemListPreselected",
      component: ServiceList,
      props: true
    },
    {
      path: "/webview/:itemType/:serviceType/:id/:imageId",
      name: "ServiceDetail",
      props: () => history.state?.params,
      component: ServiceDetail
    },
    {
      path: "/webview/:itemType/:serviceType/:id/:imageId/siteId/:siteId",
      name: "ServiceDetailSiteId",
      component: ServiceDetail
    },
    {
      path: "/webview/:itemType/:serviceType/:id/:imageId/:parentItemServiceId",
      name: "ServiceItemDetail",
      component: ServiceDetail
    },
    {
      path: "/webview/:itemType/:serviceType/:id/:imageId/product/:productId",
      name: "ServiceDetailWithProduct",
      component: ServiceDetail
    },
    {
      path: "/webview/:itemType/:serviceType/:id/:imageId",
      name: "ServiceWithConfigurableProducts",
      component: ServiceDetail
    },
    {
      path: "/item-detail/:itemType/:serviceType/:serviceId/:itemId",
      name: "ItemDetail",
      component: ServiceWithConfigurableProducts
    },
    {
      path: "/webview-ga/:establishment/:locale/:itemType/:serviceType/:id/:imageId/:psId",
      name: "ServiceDetailGA",
      component: ServiceWithConfigurableProducts,
      props: {
        isGA: true
      }
    },
    {
      path: "/requests/:itemType/:serviceType/:serviceId?",
      name: "RequestsProductList",
      component: RequestsProductList
    },
    {
      path: "/requests-ga/:establishment/:locale/:itemType/:serviceType/:psId",
      name: "RequestsProductListGA",
      component: RequestsProductList,
      props: {
        isGA: true
      }
    },
    {
      path: "/requests-ga/:establishment/:locale/:itemType/:serviceType/:psId/:productId",
      name: "RequestsProductListGAWithProduct",
      component: RequestsProductList,
      props: {
        isGA: true
      }
    },

    {
      path: "/calendar-list/:serviceType",
      name: "CalendarList",
      component: CalendarList
    },
    {
      path: "/dk-email",
      name: "DKEmail",
      component: DKEmail
    },
    {
      path: "/dk-phone-number",
      name: "DKPhoneNumber",
      component: DKPhoneNumber
    },
    {
      path: "/dk-keys-list",
      name: "DKKeysList",
      component: DKKeysList
    },
    {
      path: "/dk-keys-redirection",
      name: "DKKeysRedirection",
      component: DKKeysRedirection,
      props: true
    },
    {
      path: "/dk-keys-redirection-reload",
      name: "DKKeysRedirectionReload",
      component: DKKeysRedirectionReload,
      props: true
    },
    {
      path: "/dk-key-detail",
      name: "DKKeyDetail",
      component: DKKeyDetail
    },
    {
      path: "/dk-key-formulary",
      name: "DKFormulary",
      component: DKFormulary
    },
    {
      path: "/dk-key-error",
      name: "DKErrorScreen",
      component: DKErrorScreen
    },
    {
      path: "/my-congress/:idCongress",
      name: "CongressSumary",
      component: CongressSumary
    },
    {
      path: "/my-congress-schedule/:idEvent",
      name: "CongressScheduleList",
      component: CongressScheduleList
    },
    {
      path: "/my-congress-speakers/:idEvent",
      name: "CongressSpeakersList",
      component: CongressSpeakersList
    },
    {
      path: "/my-congress-sponsor/:idEvent",
      name: "CongressSponsorsList",
      component: CongressSponsorsList,
      props: () => history.state?.params
    },
    {
      path: "/my-congress-speakers-detail/:idEvent/:idSpeaker",
      name: "CongressSpeakersDetail",
      component: CongressSpeakersDetail
    },
    {
      path: "/my-congress-sponsor-detail/:idEvent/:idCompany",
      name: "CongressSponsorsDetail",
      component: CongressSponsorsDetail
    },
    {
      path: "/my-congress-schedule-detail/:idEvent/:idActivity",
      name: "CongressScheduleDetail",
      component: CongressScheduleDetail,
      props: () => history.state?.params
    },

    {
      path: "/promotions/:idPromo",
      name: "UpSellNotificationScreenView",
      component: UpSellNotificationScreen,
      props: true
    },

    {
      path: "/desktop/upsell/:idPromo",
      name: "UpSellNotificationScreenViewDesktop",
      component: UpSellNotificationScreen,
      props: true
    },

    {
      path: "/activities/:category/:serviceType",
      name: "Activities",
      component: Activities,
      props: true
    },

    {
      path: "/eventcalendar/:category/:serviceType",
      name: "EventCalendar",
      component: Activities,
      props: true
    },

    {
      path: "/showseventcalendar/:category/:serviceType",
      name: "ShowsEventCalendar",
      component: ShowsEventCalendar,
      props: true
    },

    {
      path: "/redirectioneventcalendar/:category/:serviceType",
      name: "RedirectionEventCalendar",
      component: RedirectionEventCalendar,
      props: true
    },

    {
      path: "/activities-ga/:establishment/:category/:psId/:userkey",
      name: "ActivitiesGA",
      component: Activities,
      props: route => ({
        ...route.params,
        ...{
          isGA: true
        }
      })
    },
    {
      path: "/map",
      name: "Map",
      component: Map,
      props: () => history.state?.params
    },
    {
      path: "/map/:markerLatLong",
      name: "MapMarker",
      component: Map,
      props: true
    },
    {
      path: "/map-marker-list",
      name: "MapMarkerList",
      component: Map,
      props: true
    },
    {
      path: "/map-marker-list/:establishment/:locale/:psId",
      name: "MapMarkerListParams",
      component: Map,
      props: true
    },
    {
      path: "/activity/:serviceType/:id",
      component: ActivityDetail,
      name: "ActivityDetail"
    },
    {
      path: "/activity/:serviceType/:id/:date/:startHour/:endHour",
      component: ActivityDetail
    },
    {
      path: "/congress-activity/:congressId/:id/:date/:startHour/:endHour",
      component: ActivityDetail
    },
    {
      path: "/activity/:serviceType/:id/:date/:startHour/:endHour",
      component: ActivityDetail,
      props: true
    },
    {
      path: "/activity-timetable/:serviceType/:id/:category",
      component: ActivityTimetable
    },
    {
      path: "/activity-timetable/:congressId/:id",
      component: ActivityTimetable
    },
    {
      path: "/booking-form/:serviceId",
      component: BookingForm,
      props: () => history.state?.params,
      name: "BookingForm"
    },
    {
      path: "/booking-availability",
      component: BookingAvailability,
      props: true,
      name: "BookingAvailability"
    },
    {
      path: "/booking-form-ga/:establishment/:serviceId/:psId/:userkey/:apikey/:devicekey",
      component: BookingForm,
      props: () => ({ ...history.state?.params, isGA: true }),
      name: "BookingFormGA"
    },
    {
      path: "/order",
      component: OrderSummary,
      props: () => history.state?.params,
      name: "OrderSummary"
    },
    {
      path: "/spaces-orders",
      component: SpacesOrders,
      props: () => history.state?.params,
      name: "SpacesOrders"
    },
    {
      path: "/order-addons",
      component: OrderSummaryAddOns,
      props: () => history.state?.params,
      name: "OrderSummaryAddOns"
    },
    {
      path: "/ordering-funnel",
      component: OrderingFunnel,
      props: () => history.state?.params,
      name: "OrderingFunnel"
    },
    {
      path: "/transaction-confirmation",
      component: TransactionConfirmation,
      props: () => history.state?.params,
      name: "TransactionConfirmation"
    },
    {
      path: "/survey-chat",
      component: SurveyV3Chat,
      props: () => history.state?.params,
      name: "SurveyChat"
    },
    {
      path: "/full-order/",
      component: FullOrder,
      name: "FullOrder",
      props: () => history.state?.params
    },
    {
      path: "/inbox",
      component: Inbox,
      name: "Inbox"
    },
    {
      path: "/guest-panel",
      component: GuestPanel,
      name: "GuestPanel"
    },
    {
      path: "/add-member-form",
      component: AddMemberForm,
      name: "AddMemberForm"
    },
    {
      path: "/members-list/:itemId",
      component: MembersList,
      name: "MembersList"
    },
    {
      path: "/member-detail",
      component: MemberDetail,
      props: () => history.state?.params,
      name: "MemberDetail"
    },
    {
      path: "/alert-detail/:alertId",
      component: AlertDetail
    },
    {
      path: "/shows",
      component: Shows
    },

    // ============== TV ===============
    {
      path: "/tv",
      component: TVFrontpageFactory,
      props: {
        isTV: true
      }
    },
    {
      path: "/tv/service-list/:serviceType",
      name: "TVServiceList",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/tv/calendar-list/:serviceType",
      name: "TVActivitiesList",
      component: LandscapeActivitiesList,
      props: true
    },
    {
      path: "/tv/showseventcalendar/:category/:serviceType",
      name: "TVShowsEventsList",
      component: LandscapeShowsEventsList,
      props: true
    },

    {
      path: "/tv/eventcalendar/:category/:serviceType",
      name: "EventCalendarTv",
      component: LandscapeActivitiesList,
      props: true
    },
    {
      path: "/tv/activity/:serviceType/:serviceId",
      name: "TVActivitiesList2",
      component: LandscapeActivitiesList,
      props: true
    },
    {
      path: "/tv/activities/:category",
      name: "ActivitiesPreselected",
      component: LandscapeActivitiesList,
      props: true
    },
    {
      path: "/tv/map",
      name: "TVMap",
      component: Map,
      props: true
    },
    {
      path: "/tv/webview/:itemType/:serviceType/:id/:imageId",
      name: "TVCustomDetail",
      component: TVServiceDetail,
      props: true
    },
    {
      path: "/tv/:itemType/:serviceType/:id/:imageId",
      name: "TVCustomDetail2",
      component: TVServiceDetail,
      props: true
    },
    {
      path: "/tv/service-list/:serviceType/:preSelectedCategory",
      name: "TVServiceListPreselected",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/tv/item-list/:id/:preSelectedCategory/:serviceType",
      name: "TVItemListPreselected",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/tv/item-list/:serviceId/:serviceType",
      name: "TVItemList",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/tv/requests/:itemType/:serviceType",
      name: "RequestsProductListTv",
      component: TVServiceDetailRoomService,
      props: true
    },
    // ============== Desktop ===============
    {
      path: "/desktop",
      component: TVFrontpageFactory,
      props: {
        isLobby: false,
        isDesktop: true
      }
    },
    {
      path: "/desktop/service-list/:serviceType",
      name: "DesktopServiceList",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/desktop/calendar-list/:serviceType",
      name: "DesktopActivitiesList",
      component: LandscapeActivitiesList,
      props: true
    },
    {
      path: "/desktop/activity/:serviceType/:serviceId",
      name: "DesktopActivitiesList2",
      component: LandscapeActivitiesList,
      props: true
    },
    {
      path: "/desktop/showseventcalendar/:category/:serviceType",
      name: "DesktopShowsEventsList",
      component: LandscapeShowsEventsGrid,
      props: true
    },
    {
      path: "/desktop/eventcalendar/:category/:serviceType",
      name: "DesktopEventCalendar",
      component: LandscapeActivitiesListWhite,
      props: true
    },
    {
      path: "/desktop/activities/:category",
      name: "DesktopActivitiesPreselected",
      component: LandscapeActivitiesListWhite,
      props: true
    },
    {
      path: "/desktop/map",
      name: "DesktopMap",
      component: Map,
      props: true
    },
    {
      path: "/desktop/shows",
      name: "DesktopShows",
      component: LandscapeShowsEventsGrid,
      props: true
    },
    {
      path: "/desktop/webview/:itemType/:serviceType/:id/:imageId",
      name: "DesktopCustomDetail",
      component: TVServiceDetail,
      props: true
    },
    {
      path: "/desktop/webview-rs/:itemType/:serviceType/:id/:imageId",
      name: "DesktopCustomDetailRS",
      component: TVServiceDetailRoomService,
      props: true
    },
    {
      path: "/desktop/webview-spa/:itemType/:serviceType/:id/:imageId",
      name: "DesktopCustomDetailSpa",
      component: TVServiceDetailSpa
    },
    {
      path: "/desktop/webview/:itemType/:serviceType/:id/:imageId/product/:productId",
      name: "DesktopCustomDetailProduct",
      component: TVServiceDetail,
      props: true
    },
    // {
    //   path: '/desktop/:itemType/:serviceType/:id/:imageId',
    //   name: 'DesktopCustomDetail2',
    //   component: DesktopServiceList,
    //   props:true
    // },
    {
      path: "/desktop/service-list/:serviceType/:preSelectedCategory",
      name: "DesktopServiceListPreselected",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/desktop/item-list/:id/:preSelectedCategory/:serviceType",
      name: "DesktopItemListPreselected",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/desktop/item-list/:serviceId/:serviceType",
      name: "DesktopItemList",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/desktop/requests/:itemType/:serviceType",
      name: "DesktopProductList",
      component: TVServiceDetailRoomService,
      props: () => history.state?.params
    },
    {
      path: "/desktop/item-detail/:itemType/:serviceType/:id/:itemId",
      name: "ItemDetailDesktop",
      component: TVServiceDetail,
      props: true
    },
    {
      path: "/desktop/grouping/:serviceId",
      name: "LandscapeGrouping",
      component: ServiceGroupingRedirect
    },
    {
      path: "/desktop/grouping_map/:serviceType",
      name: "LandscapeGroupingMap",
      component: ServiceGroupingRedirect
    },
    {
      path: "/desktop/my-congress/:idCongress",
      name: "LandscapeCongressDetail",
      component: LandscapeCongressDetailWhite,
      props: true
    },
    {
      path: "/tv/my-congress/:idCongress",
      name: "TVLandscapeCongressDetail",
      component: LandscapeCongressDetailWhite,
      props: true
    },
    {
      path: "/desktop/my-congress-schedule/:idEvent",
      name: "LandscapeCongressScheduleList",
      component: LandscapeCongressScheduleList
    },
    {
      path: "/desktop/my-congress-speakers/:idEvent",
      name: "LandscapeCongressSpeakersList",
      component: CongressSpeakersList
    },
    {
      path: "/desktop/my-congress-sponsor/:idEvent",
      name: "LandscapeCongressSponsorsList",
      component: CongressSponsorsList
    },
    {
      path: "/desktop/my-congress-speakers-detail/:idEvent/:idSpeaker",
      name: "LandscapeCongressSpeakersDetail",
      component: CongressSpeakersDetail
    },
    {
      path: "/desktop/my-congress-sponsor-detail/:idEvent/:idCompany",
      name: "LandscapeCongressSponsorsDetail",
      component: CongressSponsorsDetail
    },
    {
      path: "/desktop/my-congress-schedule-detail/:idEvent/:idActivity",
      name: "LandscapeCongressScheduleDetail",
      component: CongressScheduleDetail
    },
    {
      path: "/desktop/my-events",
      name: "LandscapeMyEvents",
      component: LandscapeCongressGrid
    },
    {
      path: "/external-webview",
      component: ExternalWebview,
      props: true,
      name: "ExternalWebview"
    },
    {
      path: "/wait-times",
      component: ParksWaitTimes
    },
    {
      path: "/full-schedule",
      component: FullSchedule,
      name: "FullSchedule",
      props: () => history.state?.params
    },
    {
      path: "/full-schedule/activity/:serviceType/:serviceId/:categoryName",
      component: FullSchedule,
      name: "FullScheduleActivity",
      props: true
    },
    {
      path: "/service-catalogue/",
      component: ServiceCatalogue,
      name: "ServiceCatalogue",
      props: true
    },
    {
      path: "/service-catalogue/:serviceType/:serviceId/catalogue/:catalogueId",
      component: ServiceCatalogue,
      name: "ServiceCatalogueWithId",
      props: () => history.state?.params
    },
    {
      path: "/service-catalogue/:serviceType/:serviceId/catalogue/:catalogueId/:id/:itemType/:spaceSitePreselected/:isSpacesOrder/:spacesOrderEnabled/:bookingId/:spaceCombinationId",
      component: ServiceCatalogue,
      name: "ServiceCatalogueWithIdSpaces",
      props: true
    },
    {
      path: "/service-catalogue-v2/:serviceType/:servicePublicId/catalogue/:cataloguePublicId",
      component: ServiceCatalogueV2,
      name: "ServiceCatalogueV2WithId",
      props: () => history.state?.params
    },
    {
      path: "/service-catalogue-v2/:serviceType/:serviceId/catalogue/:catalogueId/:id/:itemType/:spaceSitePreselected/:isSpacesOrder/:spacesOrderEnabled/:bookingId/:spaceCombinationId",
      component: ServiceCatalogueV2,
      name: "ServiceCatalogueV2WithIdSpaces",
      props: () => history.state?.params
    },
    {
      path: "/desktop/service-catalogue-v2/:serviceType/:servicePublicId/catalogue/:cataloguePublicId",
      component: ServiceCatalogueV2,
      name: "ServiceCatalogueV2WithIdDesktop",
      props: () => history.state?.params
    },
    {
      path: "/desktop/service-catalogue-v2/:serviceType/:serviceId/catalogue/:catalogueId/:id/:itemType/:spaceSitePreselected/:isSpacesOrder/:spacesOrderEnabled/:bookingId/:spaceCombinationId",
      component: ServiceCatalogueV2,
      name: "ServiceCatalogueV2WithIdSpacesDesktop",
      props: () => history.state?.params
    },
    {
      path: "/redirect",
      component: FrontpageFactory,
      name: "Redirect",
      props: true
    },
    {
      path: "/desktop/redirect",
      component: FrontpageFactory,
      name: "RedirectDesktop",
      props: true
    },
    {
      path: "/desktop/redirectioneventcalendar/:category/:serviceType",
      name: "DesktopRedirectionEventCalendar",
      component: LandscapeRedirectionEventCalendar,
      props: true
    },
    {
      path: "/tv/redirectioneventcalendar/:category/:serviceType",
      name: "TVRedirectionEventCalendar",
      component: LandscapeRedirectionEventCalendar,
      props: true
    },
    {
      path: "/elastic-search-result",
      name: "ElasticSearchResult",
      props: () => history.state?.params,
      component: ElasticSearchResultsList
    },
    {
      path: "/smart-search",
      name: "SmartSearch",
      props: true,
      component: SmartSearch
    },
    {
      path: "/desktop/smart-search",
      name: "DesktopSmartSearch",
      props: true,
      component: SmartSearch
    },
    {
      path: "/grouping/:serviceId",
      name: "ServiceGrouping",
      component: ServiceGrouping
    },
    {
      path: "/grouping_map/:serviceType",
      name: "ServiceGroupingMap",
      component: ServiceGrouping
    },
    {
      path: "/weather",
      name: "Weather",
      component: Weather
    },
    {
      path: "/enquiries-list",
      name: "EnquiriesList",
      component: EnquiriesList
    },
    {
      path: "/enquiries-chat",
      name: "EnquiriesChat",
      component: EnquiriesChat
    },
    {
      path: "/chat-list",
      name: "ChatList",
      props: true,
      component: EnquiriesList
    },
    {
      path: "/chat-list/:serviceType",
      name: "ChatListFiltered",
      props: true,
      component: EnquiriesList
    },
    {
      path: "/chat-detail/:chatId",
      name: "ChatDetail",
      props: true,
      component: EnquiriesChat
    },
    {
      path: "/chat-detail/:itemType/:itemId",
      name: "ChatDetailNew",
      props: true,
      component: EnquiriesChat
    },
    {
      path: "/city-guide",
      name: "CityGuide",
      props: true,
      component: CityGuide
    },
    {
      path: "/city-guide-bookmarks",
      name: "CityGuideBookmarks",
      props: true,
      component: CityGuide
    },
    {
      path: "/bookmark-note/:serviceId",
      name: "CityGuideBookmarkNote",
      props: true,
      component: CityGuideBookmarkNote
    },
    {
      path: "/desktop/city-guide",
      name: "LandscapeCityGuide",
      component: LandscapeCityGuide
    },
    {
      path: "/desktop/city-guide-bookmarks",
      name: "LandscapeCityGuideBookmarks",
      component: LandscapeCityGuide
    },
    {
      path: "/directory",
      name: "Directory",
      component: Directory
    },
    {
      path: "/desktop/directory",
      name: "LandscapeDirectory",
      component: LandscapeDirectory
    },
    {
      path: "/environment",
      name: "Environment",
      component: Directory
    },
    {
      path: "/desktop/environment",
      name: "LandscapeEnvironment",
      component: LandscapeDirectory
    },
    {
      path: "/payment-result/:serviceType/:serviceId/:bookingId/:orderId",
      name: "PaymentResult",
      component: PaymentResult
    },
    // ============== Lobby ===============
    {
      path: "/lobby",
      component: TVFrontpageFactory,
      props: {
        isLobby: true
      }
    },
    {
      path: "/lobby/service-list/:serviceType",
      name: "LobbyServiceList",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/lobby/calendar-list/:serviceType",
      name: "LobbyActivitiesList",
      component: LandscapeActivitiesList,
      props: true
    },
    {
      path: "/lobby/activity/:serviceType/:serviceId",
      name: "LobbyActivitiesList2",
      component: LandscapeActivitiesList,
      props: true
    },
    {
      path: "/lobby/showseventcalendar/:category/:serviceType",
      name: "LobbyShowsEventsList",
      component: LandscapeShowsEventsGrid,
      props: true
    },
    {
      path: "/lobby/eventcalendar/:category/:serviceType",
      name: "LobbyEventCalendar",
      component: LandscapeActivitiesListWhite,
      props: true
    },
    {
      path: "/lobby/activities/:category",
      name: "LobbyActivitiesPreselected",
      component: LandscapeActivitiesListWhite,
      props: true
    },
    {
      path: "/lobby/map",
      name: "LobbyMap",
      component: Map,
      props: true
    },
    {
      path: "/lobby/shows",
      name: "LobbyShows",
      component: LandscapeShowsEventsGrid,
      props: true
    },
    {
      path: "/lobby/webview/:itemType/:serviceType/:id/:imageId",
      name: "LobbyCustomDetail",
      component: TVServiceDetail,
      props: true
    },
    {
      path: "/lobby/webview-rs/:itemType/:serviceType/:id/:imageId",
      name: "LobbyCustomDetailRS",
      component: TVServiceDetailRoomService,
      props: true
    },
    {
      path: "/lobby/webview-spa/:itemType/:serviceType/:id/:imageId",
      name: "LobbyCustomDetailSpa",
      component: TVServiceDetailSpa,
      props: true
    },
    {
      path: "/lobby/webview/:itemType/:serviceType/:id/:imageId/product/:productId",
      name: "LobbyCustomDetailProduct",
      component: TVServiceDetail,
      props: true
    },
    {
      path: "/lobby/service-list/:serviceType/:preSelectedCategory",
      name: "LobbyServiceListPreselected",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/lobby/item-list/:id/:preSelectedCategory/:serviceType",
      name: "LobbyItemListPreselected",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/lobby/item-list/:serviceId/:serviceType",
      name: "LobbyItemList",
      component: TVServiceListGrid,
      props: true
    },
    {
      path: "/lobby/requests/:itemType/:serviceType",
      name: "LobbyProductList",
      component: TVServiceDetailRoomService,
      props: true
    },
    {
      path: "/lobby/item-detail/:itemType/:serviceType/:id/:itemId",
      name: "LobbyItemDetail",
      component: TVServiceDetail,
      props: true
    },
    {
      path: "/lobby/grouping/:serviceId",
      name: "LobbyGrouping",
      component: ServiceGroupingRedirect
    },
    {
      path: "/lobby/grouping_map/:serviceType",
      name: "LobbyGroupingMap",
      component: ServiceGroupingRedirect
    },
    {
      path: "/lobby/my-congress/:idCongress",
      name: "LobbyCongressDetail",
      component: LandscapeCongressDetailWhite,
      props: true
    },
    {
      path: "/lobby/my-congress-schedule/:idEvent",
      name: "LobbyCongressScheduleList",
      component: LandscapeCongressScheduleList
    },
    {
      path: "/lobby/my-congress-speakers/:idEvent",
      name: "LobbyCongressSpeakersList",
      component: CongressSpeakersList
    },
    {
      path: "/lobby/my-congress-sponsor/:idEvent",
      name: "LobbyCongressSponsorsList",
      component: CongressSponsorsList
    },
    {
      path: "/lobby/my-congress-speakers-detail/:idEvent/:idSpeaker",
      name: "LobbyCongressSpeakersDetail",
      component: CongressSpeakersDetail
    },
    {
      path: "/lobby/my-congress-sponsor-detail/:idEvent/:idCompany",
      name: "LobbyCongressSponsorsDetail",
      component: CongressSponsorsDetail
    },
    {
      path: "/lobby/my-congress-schedule-detail/:idEvent/:idActivity",
      name: "LobbyCongressScheduleDetail",
      component: CongressScheduleDetail
    },
    {
      path: "/lobby/my-events",
      name: "LobbyMyEvents",
      component: LandscapeCongressGrid
    },
    {
      path: "/lobby/redirect",
      component: FrontpageFactory,
      name: "RedirectLobby",
      props: true
    },
    {
      path: "/lobby/redirectioneventcalendar/:category/:serviceType",
      name: "LobbyRedirectionEventCalendar",
      component: LandscapeRedirectionEventCalendar,
      props: true
    },
    {
      path: "/lobby/city-guide",
      name: "LobbyCityGuide",
      component: LandscapeCityGuide
    },
    {
      path: "/lobby/city-guide-bookmarks",
      name: "LobbyCityGuideBookmarks",
      component: LandscapeCityGuide
    },
    {
      path: "/lobby/directory",
      name: "LobbyDirectory",
      component: LandscapeDirectory
    },
    {
      path: "/lobby/environment",
      name: "LobbyEnvironment",
      component: LandscapeDirectory
    },
    {
      path: "/profile-all-activity",
      name: "ProfileAllActivity",
      component: ProfileAllActivity
    },
    {
      path: "/profile-legal-links",
      name: "ProfileLegalLinks",
      component: ProfileLegalLinks
    },
    {
      path: "/campaign-landing",
      name: "CampaignLanding",
      component: CampaignLanding
    }
  ]
});
