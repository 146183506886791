import { request } from "@/services/request";
import * as auth from "@/services/auth";
import config from "@/config";
import moment from "moment";

export default class PromotionsService {
  static async getAll(state) {
    return await request({
      method: "get",
      url: `${config.clusterHost}/stay-api/promotions/`,
      headers: { ...this._headers(state) },
      cache: false
    }).then(({ data }) =>
      data?.result.filter(it => {
        const isActive = it.status === 1;

        const endDateIsGreater = it.endDate
          ? !(moment(it.endDate).diff(moment().tz(state.establishment?.timezone)) < -86399000)
          : true;
        const startDateIsLess = moment(it.startDate).diff(moment().tz(state.establishment?.timezone)) < 0;

        const isAvailable = endDateIsGreater && startDateIsLess;

        return isActive && isAvailable;
      })
    );
  }

  static async getById(state, id) {
    return await request({
      method: "get",
      url: `${config.clusterHost}/stay-api/promotions/id/${id}`,
      headers: { ...this._headers(state) },
      cache: false
    }).then(({ data }) => data);
  }

  static async applyOnRoomService(state, { promotionId, roomServiceData }) {
    const { discountValue } = state.promotions.promotionsList.find(it => it.id === promotionId);
    const finalPrice = roomServiceData * (1 - discountValue / 100);

    return await {
      initialPrice: roomServiceData,
      discount: finalPrice - roomServiceData,
      finalPrice
    };
  }

  static _headers(state) {
    const bearer = auth.getAuthBearer(state);
    return {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    };
  }
}
