<template>
  <div
    class="frontpage-item"
    :class="[className, isDesktop ? 'desktop' : '', hidden ? 'hidden' : '', hasEvents ? 'with-events' : '']"
    @click.stop="action"
  >
    <div class="image-container" :class="[isDesktop ? 'desktop' : '', hasEvents ? 'with-events' : '']">
      <div
        class="image"
        :class="[isDesktop ? 'desktop' : '', hasEvents ? 'with-events' : '']"
        :style="'opacity:' + data.bubbleOpacity + ';' + 'background-color:' + data.bubbleColor"
      ></div>
      <img
        v-if="bubbleItem.iconBubble"
        :class="[isDesktop ? 'desktop' : '']"
        class="image-img"
        :src="getImageUrl(bubbleItem.iconBubble, true)"
      />
    </div>
    <div
      class="title"
      :class="[isDesktop ? 'desktop' : '', hasEvents ? 'with-events' : '']"
      :style="{ color: data.textColor ? data.textColor : '' }"
    >
      {{ getTitle() }}
    </div>
    <!-- <div class="offer-badge" v-if="bubbleItem.translatableBadge && bubbleItem.translatableBadge.length > 0 && getBadgeTitle()">{{ getBadgeTitle() }}</div> -->
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
import mixin from "@/services/mixin";

export default {
  mixins: [mixin],
  props: [
    "data",
    "bubbleItem",
    "locale",
    "index",
    "numItems",
    "itemRow",
    "className",
    "lastItem",
    "maxBubbles",
    "page",
    "hidden",
    "hasEvents"
  ],
  emits: ["action"],
  data() {
    return {};
  },
  created() {},
  methods: {
    getImageUrl,
    getTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? translate(this.bubbleItem.translatableName, { locale: this.locale })
        : "";
    },
    getBadgeTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableBadge
        ? translate(this.bubbleItem.translatableBadge, { locale: this.locale })
        : "";
    },
    getEnglishTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? this.translateEnglishOrDefault(this.bubbleItem.translatableName)
        : "";
    },
    getEnglishForcedTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? this.translateEnglishOrDefaultForced(this.bubbleItem.translatableName)
        : "";
    },
    action: function () {
      const section = parseInt(this.page) - 1;
      let ind = parseInt(this.index) + 1;
      if (section != 0) {
        ind = ind + section * this.maxBubbles;
      }
      const label = "S1" + "BB" + ind;
      this.$emit("action", {
        data: this.bubbleItem,
        index: this.index,
        bubbleIndex: label,
        bubbleType: "box-bubble-child",
        title: this.getEnglishTitle(),
        logTitle: this.getEnglishForcedTitle()
      });
    }
  }
};
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import (reference) "../../../assets/styleguide.less";
@import (reference) "../../../assets/tv-styleguide.less";

.title {
  top: 80px;
  position: absolute;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: center;
  .caption-black;
  .semibold;
  @media (max-height: 700px) {
    top: 70px;
  }
  @media (max-height: 600px) {
    &.with-events {
      top: 60px;
    }
  }
  &.desktop {
    top: 142px;
    .h5-medium;
  }
}

.frontpage-item {
  position: relative;
  flex: 100px 0 0;
  height: 118px;
  width: 100px;
  margin-top: @base-unit;
  margin-bottom: 16px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 24px;
  transition: all ease-in-out 0.2s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    transition: all ease-in-out 0.2s;
  }
  @media (max-width: 350px) {
    flex: 80px 0 0;
    width: 80px;
  }
  @media (max-height: 600px) {
    height: 95px;
    &.with-events {
      height: 70px;
      margin-top: 0;
    }
  }
  @media (max-height: 700px) {
    &.with-events {
      height: 90px;
    }
  }
  &.desktop {
    flex: 200px 0 0;
    height: 200px;
    width: 200px;
  }
  &.hidden {
    opacity: 0;
  }
}

.front-no-2-elem:last-child {
  margin-top: @base-unit;
}

.image-container {
  left: 18px;
  position: absolute;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &.desktop {
    left: 40px;
    top: 8px;
    height: 120px;
    width: 120px;
  }
  @media (max-width: 350px) {
    left: 10px;
    width: 60px;
    height: 60px;
  }
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  width: 64px;
  height: 64px;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 350px) {
    left: 0;
    width: 60px;
    height: 60px;
  }
  &.desktop {
    border-radius: 60px;
    height: 120px;
    width: 120px;
  }
}
.image-img {
  z-index: 2;
  width: 32px;
  height: 32px;
  @media (max-width: 350px) {
    width: 30px;
    height: 30px;
  }
  &.desktop {
    height: 64px;
    width: 64px;
  }
}
.front-2-elem {
  width: calc(50% - 3pt);
}

.front-1-elem {
  width: 100% !important;
}

.front-2-elem-margin {
  margin-left: @base-unit;
  width: calc(50% - 3pt);
}

.offer-badge {
  color: white;
  font-weight: bold;
  font-size: 15px;
  padding: 4px 12px;
  background-color: var(--main-color);
  top: 8px;
  right: 8px;
  position: absolute;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  font-weight: 700;
  border-radius: 16px;
  max-width: 80%;
  .subheader-white;
}
</style>
<style lang="less">
.superwrapper.landscape {
  .wrapper {
    .frontpage-container {
      .nodes {
        .frontpage-item {
          &:nth-child(2) {
            margin-left: 24px;
          }
          &:nth-child(3) {
            margin-left: 24px;
          }
          &:nth-child(4) {
            margin-left: 24px;
          }
          &:nth-child(5) {
            margin-left: 24px;
          }
          &:nth-child(7) {
            margin-left: 24px;
          }
          &:nth-child(8) {
            margin-left: 24px;
          }
          &:nth-child(9) {
            margin-left: 24px;
          }
        }
      }
      .nodes-rows-of-threee {
        .frontpage-item {
          &:nth-child(2) {
            margin-left: 200px;
          }
          &:nth-child(3) {
            margin-left: 200px;
          }
          &:nth-child(5) {
            margin-left: 200px;
          }
          &:nth-child(6) {
            margin-left: 200px;
          }
          &:nth-child(8) {
            margin-left: 200px;
          }
          &:nth-child(9) {
            margin-left: 200px;
          }
        }
      }
      .nodes-rows-of-three {
        .frontpage-item {
          &:nth-child(2) {
            margin-left: 48px;
          }
          &:nth-child(3) {
            margin-left: 48px;
          }
          &:nth-child(5) {
            margin-left: 48px;
          }
          &:nth-child(6) {
            margin-left: 48px;
          }
          &:nth-child(8) {
            margin-left: 48px;
          }
          &:nth-child(9) {
            margin-left: 48px;
          }
        }
      }
    }
  }
}
</style>
