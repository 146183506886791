import request from "@/services/request";
import config from "@/config";

export default class UserService {
  static async getUserData(state) {
    const params = new URLSearchParams({
      establishment: state.establishment.id,
      apikey: config.apiKey
    });

    if (state.user?.userKey && state.user?.deviceKey) {
      params.append("userkey", state.user.userKey);
      params.append("devicekey", state.user.deviceKey);
    }

    const url = `${config.mobileApiHost}/v2/users/info?${params.toString()}`;

    const { data } = await request({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json"
      },
      data: {},
      cache: false
    });
    return data;
  }
}
