import config from "@/config";
import axios from "axios";
import * as types from "@/store/mutation-types";
import store from "@/store";
import { sendRequestErrorToSlack } from "@/services/utils";

const getClusterToken = (establishment, secondTry, essentialBearer) => {
  return new Promise((resolve, reject) => {
    const url = "/api/auth/cluster/" + establishment;

    axios.get(url).then(
      response => {
        if (response && response.data && response.data.data && response.data.data.access_token) {
          store.commit(types.TOKEN_API_CLUSTER, response.data.data.access_token);
          store.commit(types.TOKEN_REFRESH_API_CLUSTER, response.data.data.refresh_token);

          config.clusterApiCredentials.bearer = response.data.data.access_token;

          if (response.data.data.bot) {
            config.botApiCredentials.bearer = response.data.data.bot;
            store.commit(types.SET_BOT_TOKEN, config.botApiCredentials.bearer);
          }
          if (response.data.data.cms) {
            config.cmsApiHostCredentials.bearer = response.data.data.cms;
            store.commit(types.SET_CMS_TOKEN, config.cmsApiHostCredentials.bearer);
          }

          resolve(response.data.data);
        } else {
          if (url === "/api/auth/cluster/" + establishment) {
            sendRequestErrorToSlack(response); // to pwa channel
          }
          reject();
        }
      },
      err => {
        if (url === "/api/auth/cluster/" + establishment) {
          sendRequestErrorToSlack(err.response); // to pwa channel
        }
        reject(err);
      }
    );
  });
};
function parseErrorToSlack(err, type) {
  if (err) {
    const errObject = JSON.parse(JSON.stringify(err));
    if (errObject) {
      let statusCode = "";
      if (errObject.status) {
        statusCode = errObject.status;
      } else {
        statusCode = errObject.message;
      }
      if (errObject.message != "Network Error") {
        if (statusCode.includes("status code") && statusCode.indexOf("status code") == statusCode.length - 15) {
          statusCode = statusCode.substring(statusCode.length - 3, statusCode.length);
        }
        // "ERROR TEST CMS: " +
        const slackInfo = {
          status: statusCode,
          config: {
            method: errObject.config?.method,
            baseUrl: "",
            url: errObject.config?.url
          },
          data: {
            errors: ""
          }
        };
        if (errObject.data?.errors) {
          slackInfo.data.errors = errObject.data?.errors;
        } else {
          let errData = "";
          if (errObject.data) {
            errData = errObject.data;
          }
          slackInfo.data.errors = errObject.name + ": " + errObject.message + ". " + errObject.stack + ". " + errData;
        }
        sendRequestErrorToSlack(slackInfo, type);
      }
    }
  }
}

function getAuthBearer(state) {
  let bearer = "";
  if (config.clusterApiCredentials.bearer) {
    bearer = config.clusterApiCredentials.bearer;
  } else {
    if (state.tokenApiCluster) {
      bearer = state.tokenApiCluster;
    } else {
      bearer = config.cmsApiHostCredentials.bearer;
    }
  }

  return bearer;
}

export { getClusterToken, getAuthBearer };
