import config from "../config";
import store from "../store";
import axios from "axios";
import * as blackWords from "./blackwords";
import difference from "lodash-es/difference";
import * as auth from "./auth";
import itemTypes from "@/utils/enums/itemTypes";

//TODO: delete old elastic after it is deprecated in backend
const ELASTIC_ADVANCED = true;

const isDesktop = function () {
  return store.state.isDesktop;
};

const searchElastic = (state, success, fail, data) => {
  const establishment = state.establishment.id;
  let body = {};
  if (data.rawQuery) {
    body = data.rawQuery;
  } else {
    const keywords = data.keywords;
    const exclude = data.exclude;
    const optional = data.optional;
    const mustClause = [];
    if (keywords && keywords.length > 0) {
      keywords.forEach(keyword => {
        const bool = {
          bool: {
            must: [{ match: { keywords: keyword } }, { match: { establishment: establishment } }]
          }
        };
        mustClause.push(bool);
      });
    }
    if (exclude && exclude.length > 0) {
      const bool = {
        bool: {
          must_not: [{ match: { keywords: exclude.join(" ") } }]
        }
      };
      mustClause.push(bool);
    }
    body = {
      query: {
        bool: {
          must: mustClause
        }
      }
    };
    if (optional && optional.length > 0) {
      const shouldClause = [];
      const bool = {
        bool: {
          must: [{ match: { keywords: optional.join(" ") } }, { match: { establishment: establishment } }]
        }
      };
      shouldClause.push(bool);
      body.query.bool.should = shouldClause;
    }
  }

  let url = config.elasticHost + "/_search?size=50";

  if (ELASTIC_ADVANCED) {
    body.size = 50;
  }

  const bearer = auth.getAuthBearer(state);

  let headers = {};
  if (ELASTIC_ADVANCED) {
    url = config.elasticHost + "/stay_objects/search";
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer,
      "Stay-Elastic-Version": "OS1.2",
      "Stay-Establishment": state.clusterEstablishmentId
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer,
      "Stay-Establishment": state.clusterEstablishmentId
    };
  }

  axios({
    method: "post",
    url: url,
    headers: headers,
    data: body,
    params: {}
  })
    .then(success)
    .catch(fail);
};

const searchPredefined = (state, success, fail, tags) => {
  let body = {};
  const mustClause = [];
  const bool = {
    bool: {
      must: [{ match: { keywords: tags } }, { match: { itemType: 24 } }]
    }
  };
  mustClause.push(bool);
  body = {
    query: {
      bool: {
        must: mustClause
      }
    }
  };
  let url = config.elasticHost + "/_search?size=50";

  if (ELASTIC_ADVANCED) {
    body.size = 50;
  }
  const bearer = auth.getAuthBearer(state);

  let headers = {};
  if (ELASTIC_ADVANCED) {
    url = config.elasticHost + "/stay_objects/search";
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer,
      "Stay-Elastic-Version": "OS1.2",
      "Stay-Establishment": state.clusterEstablishmentId
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer,
      "Stay-Establishment": state.clusterEstablishmentId
    };
  }

  axios({
    method: "post",
    url: url,
    headers: headers,
    data: body,
    params: {}
  })
    .then(success)
    .catch(fail);
};

const generateElasticFilter = (mustIncludeClause, shouldIncludeClause) => {
  const body = {
    query: {
      bool: {}
    }
  };
  if (mustIncludeClause) {
    body.query.bool.must = mustIncludeClause;
  }
  if (shouldIncludeClause) {
    body.query.bool.should = shouldIncludeClause;
  }

  return body;
};

const generateMustIncludeClause = (objects, excludedObjects) => {
  const establishment = store.state.establishment.id;
  const mustClause = [];
  const mustInsideBool = [];
  objects.forEach(object => {
    Object.keys(object).forEach(key => {
      const value = object[key];
      mustInsideBool.push({ match: { [key]: value } });
    });
  });
  const bool = {
    bool: {
      must: mustInsideBool.concat({ match: { establishment: establishment } })
    }
  };
  mustClause.push(bool);

  if (excludedObjects) {
    excludedObjects.forEach(object => {
      const bool = {
        bool: {
          must_not: [{ match: object }]
        }
      };
      mustClause.push(bool);
    });
  }

  return mustClause;
};

const generateShouldIncludeClause = objects => {
  const establishment = store.state.establishment.id;
  const shouldClause = [];
  objects.forEach(object => {
    const mustInsideBool = [];
    let isModule = false;
    Object.keys(object).forEach(key => {
      const value = object[key];
      if (Array.isArray(value)) {
        value.forEach(val => {
          mustInsideBool.push({ match: { [key]: val } });
        });
      } else {
        mustInsideBool.push({ match: { [key]: value } });

        if (key === "_id" && value.startsWith("1-")) {
          isModule = true;
        }
      }
    });
    let must = [];
    if (isModule) {
      must = mustInsideBool;
    } else {
      must = mustInsideBool.concat({ match: { establishment: establishment } });
    }
    const bool = {
      bool: {
        must: must
      }
    };
    shouldClause.push(bool);
  });

  return shouldClause;
};
const filterBoolMust = (element, establishmentId, itemType) => {
  return {
    bool: {
      must: [
        { match: { objectName: element } },
        { match: { establishment: establishmentId } },
        { match: { itemType: itemType } }
      ]
    }
  };
};
const filterBoolMustWildCard = (text, establishmentId, itemType) => {
  return {
    bool: {
      must: [
        { wildcard: { objectName: text + "*" } },
        { match: { establishment: establishmentId } },
        { match: { itemType: itemType } }
      ]
    }
  };
};
const filterJoinWhiteSpace = (keywords, establishmentId, itemType) => {
  return {
    bool: {
      must: [
        { match: { keywords: keywords.join(" ") } },
        { match: { establishment: establishmentId } },
        { match: { itemType: itemType } }
      ]
    }
  };
};
const searchAllServicesOrProducts = (state, success, fail, data) => {
  const text = difference(data.text.split(" "), blackWords.es, blackWords.en).join(" ");
  const keywords = data.keywords;
  const words = text.split(" ");
  const mustWithWildcard = [];
  const establishmentId = store.state.establishment.id;
  const shouldWithWildcardItemType3 = [];
  const shouldWithWildcardItemType5 = [];
  const shouldWithWildcardItemType31 = [];
  const shouldWithWildcardItemType32 = [];
  if (words.length > 1) {
    const last = words.pop();
    words.forEach(element => {
      if (element.length > 0) {
        shouldWithWildcardItemType3.push(filterBoolMust(element, establishmentId, itemTypes.ITEM_TYPE_SERVICE));
        shouldWithWildcardItemType5.push(filterBoolMust(element, establishmentId, itemTypes.ITEM_TYPE_PRODUCT));

        if (!isDesktop()) {
          shouldWithWildcardItemType31.push(filterBoolMust(element, establishmentId, itemTypes.ITEM_TYPE_CATALOGUE_V2));
          shouldWithWildcardItemType32.push(
            filterBoolMust(element, establishmentId, itemTypes.ITEM_TYPE_CATALOGUE_PRODUCT_V2)
          );
        }
      }
    });
    if (last.length > 0) {
      shouldWithWildcardItemType3.push(filterBoolMustWildCard(last, establishmentId, itemTypes.ITEM_TYPE_SERVICE));
      shouldWithWildcardItemType5.push(filterBoolMustWildCard(last, establishmentId, itemTypes.ITEM_TYPE_PRODUCT));

      if (!isDesktop()) {
        shouldWithWildcardItemType31.push(
          filterBoolMustWildCard(last, establishmentId, itemTypes.ITEM_TYPE_CATALOGUE_V2)
        );
        shouldWithWildcardItemType32.push(
          filterBoolMustWildCard(last, establishmentId, itemTypes.ITEM_TYPE_CATALOGUE_PRODUCT_V2)
        );
      }
    }
  } else {
    if (text.length > 0) {
      shouldWithWildcardItemType3.push(filterBoolMustWildCard(text, establishmentId, itemTypes.ITEM_TYPE_SERVICE));
      shouldWithWildcardItemType5.push(filterBoolMustWildCard(text, establishmentId, itemTypes.ITEM_TYPE_PRODUCT));

      if (!isDesktop()) {
        shouldWithWildcardItemType31.push(
          filterBoolMustWildCard(text, establishmentId, itemTypes.ITEM_TYPE_CATALOGUE_V2)
        );
        shouldWithWildcardItemType32.push(
          filterBoolMustWildCard(text, establishmentId, itemTypes.ITEM_TYPE_CATALOGUE_PRODUCT_V2)
        );
      }
    }
  }

  const body = {
    query: {
      bool: {
        should: []
      }
    }
  };

  if (shouldWithWildcardItemType3.length > 0) {
    body.query.bool.should.push({
      bool: {
        should: shouldWithWildcardItemType3
      }
    });
    body.query.bool.should.push({
      bool: {
        should: shouldWithWildcardItemType5
      }
    });
    if (!isDesktop()) {
      body.query.bool.should.push({
        bool: {
          should: shouldWithWildcardItemType31
        }
      });
      body.query.bool.should.push({
        bool: {
          should: shouldWithWildcardItemType32
        }
      });
    }
  }
  if (keywords.length > 0) {
    body.query.bool.should.push(filterJoinWhiteSpace(keywords, establishmentId, itemTypes.ITEM_TYPE_SERVICE));
    body.query.bool.should.push(filterJoinWhiteSpace(keywords, establishmentId, itemTypes.ITEM_TYPE_PRODUCT));
    body.query.bool.should.push({
      bool: {
        must: [
          { match: { keywords: keywords.join(" ") } },
          { match: { establishment: establishmentId } },
          { match: { itemType: 5 } }
        ]
      }
    });
    if (!isDesktop()) {
      body.query.bool.should.push(filterJoinWhiteSpace(keywords, establishmentId, itemTypes.ITEM_TYPE_CATALOGUE_V2));
      body.query.bool.should.push(
        filterJoinWhiteSpace(keywords, establishmentId, itemTypes.ITEM_TYPE_CATALOGUE_PRODUCT_V2)
      );
    }
    body.query.bool.should.push({
      bool: {
        must: [{ match: { keywords: keywords.join(" ") } }, { match: { itemType: 24 } }]
      }
    });
  }
  let url = config.elasticHost + "/_search?size=100";

  if (ELASTIC_ADVANCED) {
    body.size = 100;
  }

  const bearer = auth.getAuthBearer(state);

  let headers = {};
  if (ELASTIC_ADVANCED) {
    url = config.elasticHost + "/stay_objects/search";
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer,
      "Stay-Elastic-Version": "OS1.2",
      "Stay-Establishment": state.clusterEstablishmentId
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer,
      "Stay-Establishment": state.clusterEstablishmentId
    };
  }

  axios({
    method: "post",
    url: url,
    data: body,
    headers: headers
  })
    .then(success)
    .catch(fail);
};

export {
  searchElastic,
  searchPredefined,
  generateElasticFilter,
  generateMustIncludeClause,
  generateShouldIncludeClause,
  searchAllServicesOrProducts
};
