import axios from "axios";

const service = axios.create({
  timeout: 25000
});

service.interceptors.response.use(
  response => {
    const responseData = response.data;
    const resBody = responseData ? responseData.data : false;
    // Status not 200
    if (response && response.status !== 200) {
      return Promise.reject(response);
    }
    // Status 200
    else if (
      (resBody && resBody.errors && resBody.errors.length) ||
      (responseData && responseData.errors && responseData.errors.length)
    ) {
      // Requests status 200 but Error found in response body
      if (resBody && resBody.errors && resBody.errors.length) {
        return Promise.reject(resBody.errors);
      } else {
        return Promise.reject(responseData.errors);
      }
    } else {
      // Success response
      return response;
    }
  },
  error => {
    const response = error.response;
    const specificErrorsMessage = response?.data?.message ? response?.data?.message : specificErrors;
    const specificErrors =
      response && response.data && response.data.errors ? response.data.errors : specificErrorsMessage;
    const responseError = specificErrors ? specificErrors : error;
    return Promise.reject(response);
  }
);

export const requestDigitalKey = data => {
  return new Promise((resolve, reject) => {
    service
      .request(data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default service;
