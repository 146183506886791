<template>
  <div
    class="frontpage-item"
    :class="numItems === 2 ? [itemRow === 1 ? 'front-2-elem' : 'front-2-elem-margin'] : 'front-1-elem'"
    @click="action"
  >
    <div
      class="image"
      :style="
        'background-image: url(' + getImageUrl(bubbleItem.icon, false, numItems === 1 ? '_ld' : '_webthumb') + ')'
      "
    ></div>
    <div class="image-overlay"></div>
    <div class="title" :class="numItems === 2 ? 'title2elem' : 'title1elem'">{{ getTitle() }}</div>
    <div
      v-if="bubbleItem.translatableBadge && Object.keys(bubbleItem.translatableBadge).length > 0 && getBadgeTitle()"
      class="offer-badge"
    >
      {{ getBadgeTitle() }}
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
import mixin from "@/services/mixin";

export default {
  mixins: [mixin],
  props: ["data", "bubbleItem", "locale", "index", "numItems", "itemRow"],
  emits: ["action"],
  methods: {
    getImageUrl,
    getTitle() {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? translate(this.bubbleItem.translatableName, { locale: this.locale })
        : "";
    },
    getBadgeTitle() {
      return this.bubbleItem && this.bubbleItem.translatableBadge
        ? translate(this.bubbleItem.translatableBadge, { locale: this.locale })
        : "";
    },
    getEnglishTitle() {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? this.translateEnglishOrDefault(this.bubbleItem.translatableName)
        : "";
    },
    getEnglishTitleForced() {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? this.translateEnglishOrDefaultForced(this.bubbleItem.translatableName)
        : "";
    },
    action() {
      this.$emit("action", {
        data: this.bubbleItem,
        index: this.index,
        title: this.getEnglishTitle(),
        logTitle: this.getEnglishTitleForced()
      });
    }
  }
};
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import (reference) "../../../assets/styleguide.less";

.title {
  top: 0;
  position: absolute;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 2 * @base-unit;
  text-align: left;
}
.title1elem {
  .h3-white;
}

.title2elem {
  .h4-white;
}

.frontpage-item {
  position: relative;
  height: 140px;
  margin-top: @base-unit;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all ease-in-out 0.2s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    transition: all ease-in-out 0.2s;
  }
}

.front-no-2-elem:last-child {
  margin-top: @base-unit;
}

.image-overlay {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.25);
}
.image {
  position: absolute;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.front-2-elem {
  width: calc(50% - 3pt);
}

.front-1-elem {
  width: 100% !important;
}

.front-2-elem-margin {
  margin-left: @base-unit;
  width: calc(50% - 3pt);
}

.offer-badge {
  color: white;
  font-size: 15px;
  padding: 4px 12px;
  background-color: var(--main-color);
  bottom: 8px;
  right: 8px;
  position: absolute;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  font-weight: 700;
  border-radius: 16px;
  max-width: 80%;
  .subheader-white;
}
</style>
