import config from "../config";
import request from "@/services/request";

const get = (state, success, fail, draft) => {
  const id = state.isLobby ? config.lobbyAppId : config.appId;

  if (!state.establishmentHash || !config.cmsApiHostCredentials?.bearer) {
    console.error("Frontpage request needs more info");
    return;
  }

  let url = `${config.cmsApiHost}/api/v1/frontpage/app/${id}`;
  if (draft) {
    url += "?draft=true";
  }

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {
      getDeepLinks: true
    },
    params: {},
    cache: !draft
  })
    .then(success)
    .catch(fail);
};

const getNextActivities = (state, success, fail) => {
  const id = state.isLobby ? config.lobbyAppId : config.appId;

  request({
    method: "get",
    url: `${config.cmsApiHost}/frontpage/${id}/next-activities`,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {
      getDeepLinks: true
    },
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getOpenServices = (state, success, fail) => {
  const id = state.isLobby ? config.lobbyAppId : config.appId;

  request({
    method: "get",
    url: `${config.cmsApiHost}/frontpage/${id}/open-services`,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {
      getDeepLinks: true
    },
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getServicesWithBookingAvailability = (state, success, fail) => {
  const id = state.isLobby ? config.lobbyAppId : config.appId;

  request({
    method: "get",
    url: `${config.cmsApiHost}/frontpage/${id}/services-with-booking-availability`,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {
      getDeepLinks: true
    },
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getProductsWithPromotion = (state, success, fail) => {
  const id = state.isLobby ? config.lobbyAppId : config.appId;

  request({
    method: "get",
    url: `${config.cmsApiHost}/frontpage/${id}/products-with-promotion`,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {
      getDeepLinks: true
    },
    params: {}
  })
    .then(success)
    .catch(fail);
};

export { get, getNextActivities, getOpenServices, getServicesWithBookingAvailability, getProductsWithPromotion };
