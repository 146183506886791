<template>
  <div
    class="frontpage-item"
    :class="[className, data.backgroundImage || data.mobileBackgroundOverlay ? 'with-background' : '']"
    @click.stop="action"
  >
    <div
      class="image"
      :style="'background-image: url(' + getImageUrl(bubbleItem.icon, false, numItems == 1 ? '_ld' : '_webthumb') + ')'"
    ></div>
    <div class="image-overlay"></div>
    <div class="title" :class="numItems == 2 ? 'title2elem' : 'title1elem'">{{ getTitle() }}</div>
    <div
      v-if="bubbleItem.translatableBadge && Object.keys(bubbleItem.translatableBadge).length > 0 && getBadgeTitle()"
      class="offer-badge"
    >
      {{ getBadgeTitle() }}
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
import mixin from "@/services/mixin";

export default {
  mixins: [mixin],
  props: ["data", "bubbleItem", "locale", "index", "numItems", "itemRow", "className", "lastItem"],
  emits: ["action"],
  data() {
    return {};
  },
  created() {},
  methods: {
    getImageUrl,
    getTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? translate(this.bubbleItem.translatableName, { locale: this.locale })
        : "";
    },
    getBadgeTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableBadge
        ? translate(this.bubbleItem.translatableBadge, { locale: this.locale })
        : "";
    },
    getEnglishTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? this.translateEnglishOrDefault(this.bubbleItem.translatableName)
        : "";
    },
    getEnglishTitleForced: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? this.translateEnglishOrDefaultForced(this.bubbleItem.translatableName)
        : "";
    },
    action: function () {
      this.$emit("action", {
        data: this.bubbleItem,
        index: this.index,
        title: this.getEnglishTitle(),
        logTitle: this.getEnglishTitleForced()
      });
    }
  }
};
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import (reference) "../../../assets/styleguide.less";

.title {
  bottom: 0;
  position: absolute;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 2 * @base-unit;
  text-align: left;
}
.title1elem {
  .h3-white;
}

.title2elem {
  .h4-white;
}

.frontpage-item {
  position: relative;
  flex: 180px 0 0;
  height: 180px;
  width: 180px;
  margin: @base-unit 8px 24px 8px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 12px;
  transition: all ease-in-out 0.2s;
  box-shadow: 0 12px 22px 0 rgba(105, 113, 129, 0.3);
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    transition: all ease-in-out 0.2s;
  }
  &.first-row {
    flex: 240px 0 0;
    width: 240px;
    height: 160px;
  }
  &.with-background {
    box-shadow: none;
  }
}

.front-no-2-elem:last-child {
  margin-top: @base-unit;
}

.image-overlay {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.6;
  background: linear-gradient(180deg, rgba(19, 19, 25, 0) 0%, #131319 100%);
}
.image {
  position: absolute;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.front-2-elem {
  width: calc(50% - 3pt);
}

.front-1-elem {
  width: 100% !important;
}

.front-2-elem-margin {
  margin-left: @base-unit;
  width: calc(50% - 3pt);
}

.offer-badge {
  color: white;
  font-weight: bold;
  font-size: 15px;
  padding: 4px 12px;
  background-color: var(--main-color);
  top: 8px;
  right: 8px;
  position: absolute;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  font-weight: 700;
  border-radius: 16px;
  max-width: 80%;
  .subheader-white;
}
</style>
