<template>
  <div class="bubble-group bubble-element">
    <bubble
      :bubble-item="bubbleItem"
      :data="data"
      :class="{ 'animated pulse zIndex1': isActive }"
      :locale="locale"
      @click="activate"
    ></bubble>
    <div v-show="isActive" class="overlay" :class="{ scale: isActive }" @click="activate"></div>
    <div v-if="bubbleItem.children && bubbleItem.children[0]" v-show="isActive" class="level">
      <bubble
        v-if="bubbleItem.children[0]"
        v-show="isActive1"
        :bubble-item="bubbleItem.children[0]"
        :index="0"
        :data="data"
        class="posChild-1 animated bounceIn"
        :locale="locale"
        @action="action"
      ></bubble>
      <bubble
        v-if="bubbleItem.children[1]"
        v-show="isActive2"
        :bubble-item="bubbleItem.children[1]"
        :index="1"
        :data="data"
        class="posChild-2 animated bounceIn"
        :locale="locale"
        @action="action"
      ></bubble>
      <bubble
        v-if="bubbleItem.children[2]"
        v-show="isActive3"
        :bubble-item="bubbleItem.children[2]"
        :index="2"
        :data="data"
        class="posChild-3 animated bounceIn"
        :locale="locale"
        @action="action"
      ></bubble>
    </div>
  </div>
</template>

<script>
import { getImageUrl, logGTMEvent, translate } from "@/services/utils.js";
import Bubble from "./Bubble.vue";
import mixin from "@/services/mixin";

export default {
  components: {
    Bubble
  },
  mixins: [mixin],
  props: ["data", "bubbleItem", "index", "locale", "parentBubbleCount"],
  emits: ["action"],
  data() {
    return {
      isActive: false,
      isActive1: false,
      isActive2: false,
      isActive3: false
    };
  },
  created() {},
  methods: {
    getImageUrl,
    getTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? translate(this.bubbleItem.translatableName, { locale: this.locale })
        : "";
    },
    getEnglishTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? this.translateEnglishOrDefault(this.bubbleItem.translatableName, this.user)
        : "";
    },
    activate: function () {
      this.isActive = !this.isActive;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      if (this.isActive) {
        setTimeout(() => (this.isActive1 = true), 20);
        setTimeout(() => (this.isActive2 = true), 200);
        setTimeout(() => (this.isActive3 = true), 400);
      }
      logGTMEvent(
        "/",
        "frontpage",
        "home",
        "bubble-parent",
        this.getEnglishTitle(),
        this.index + " de " + this.parentBubbleCount
      );
    },
    action: function ({ index, title }) {
      this.$emit("action", {
        data: this.bubbleItem.children[index],
        index: this.index + "_" + index + 1,
        title: title,
        bubbleType: "bubble-child",
        bubbleIndex: index + 1 + " de " + this.bubbleItem.children.length
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.bubble-group {
  display: inline-block;
  position: relative;
}

.overlay {
  background: black;
  height: 6em;
  width: 6em;
  position: absolute;
  bottom: -1.5em;
  left: 0.3em;
  opacity: 0.6;
  border-radius: 50%;
  z-index: 1;
  transition: all 2s ease 0.5s;
}

.scale {
  transform: scale(23, 23);
}
.level {
  position: absolute;
}

.zIndex1 {
  z-index: 2;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #35495e;
}
</style>
