import { mapActions } from "vuex";
import cloneDeep from "lodash-es/cloneDeep";

export default {
  data() {
    return {
      notification: undefined
    };
  },
  watch: {
    notification: {
      handler(value) {
        this.$store.commit("promotions/setPromotionSelected", value);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(["getProduct"]),
    async goToBookWithUpSell() {
      const item = {
        service: undefined,
        product: undefined,
        productType: undefined
      };

      if (this.notification.itemType === 3) {
        return await this.getService({ serviceId: this.notification.itemId }).then(service =>
          this.$router.push({
            name: "ServiceDetail",
            params: {
              itemType: String(3),
              serviceType: String(service.typeString),
              id: String(service.id),
              imageId: String(service.photographs[0])
            },
            state: {
              params: cloneDeep({
                isPromo: true,
                promoData: this.notification,
                userkey: this.user.userKey,
                devicekey: this.user.deviceKey,
                serviceData: service,
                establishment: this.establishment.id,
                serviceType: this.serviceType,
                locale: this.user.locale ?? "en",
                psId: 0
              })
            },
            query: { id: this.$store.state.establishmentHash }
          })
        );
      }

      if (this.notification.itemType === 5) {
        await this.getProduct(this.notification.itemId)
          .then(product => {
            item.service = product.service;
            item.product = this.notification.itemId;
            item.productType = Object.values(product.productTypes)[0].id;
          })
          .catch(console.error);
      }

      return this.$router.replace({
        name: "BookingForm",
        params: {
          serviceId: item.service
        },
        state: {
          params: cloneDeep({
            isPromo: true,
            promoData: this.notification,
            bookingItem: {
              product: item.product,
              productType: item.productType
            }
          })
        }
      });
    }
  }
};
