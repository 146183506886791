import * as frontpage from "../../services/frontpage";
import * as types from "../mutation-types";

// initial state
// shape: [{ id, quantity }]
const state = {
  data: {}
};

// getters
const getters = {
  checkoutStatus: state => state.checkoutStatus
};

// actions
const actions = {
  getFrontpage({ commit, rootState }, draft) {
    return new Promise((resolve, reject) => {
      frontpage.get(
        rootState,
        response => {
          if (!response.cached) {
            commit(types.LOAD_FRONTPAGE, response.data);
          }
          resolve(response.data);
        },
        error => console.error("Error getting Frontpage", error),
        draft
      );
    });
  },
  storeFrontpage({ commit, rootState }, data) {
    commit(types.LOAD_FRONTPAGE, data);
  },
  getFrontpageNextActivities({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      frontpage.getNextActivities(
        rootState,
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        },
        data
      );
    });
  },
  getFrontpageOpenServices({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      frontpage.getOpenServices(
        rootState,
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        },
        data
      );
    });
  },
  getFrontpageServicesWithBookingAvailability({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      frontpage.getServicesWithBookingAvailability(
        rootState,
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        },
        data
      );
    });
  },
  getFrontpageProductsWithPromotion({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      frontpage.getProductsWithPromotion(
        rootState,
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        },
        data
      );
    });
  }
};

// mutations
const mutations = {
  [types.LOAD_FRONTPAGE](state, frontpageData) {
    frontpageData.level1Nodes = frontpageData.level1_nodes;
    frontpageData.level2Nodes = frontpageData.level2_nodes;
    state.data = frontpageData;
  },
  [types.IS_DEEP_LINK](state, isDeepLink) {
    state.data = Object.assign(state.data, { deepLink: isDeepLink });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
