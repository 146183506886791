import config from "../config";
import { getAuthBearer } from "./auth";
import axios from "axios";
import { sendRequestErrorToSlack } from "@/services/utils";
import store from "../store";

const requestService = axios.create({
  timeout: 25000
});

requestService.interceptors.request.use(
  config => {
    config.headers["Content-Type"] = "application/json";
    if (getAuthBearer(store.state)) {
      config.headers["Authorization"] = "Bearer " + getAuthBearer(store.state);
    }
    if (store.state.user?.userKey) {
      config.headers["Stay-Authorization"] = store.state.user?.userKey ? store.state.user.userKey : "";
    }
    if (store.state.clusterEstablishmentId) {
      config.headers["Stay-Establishment"] = store.state.clusterEstablishmentId;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

requestService.interceptors.response.use(
  response => {
    const responseData = response.data;
    const resBody = responseData ? responseData.data : false;
    // Status not 200
    if (response && response.status !== 200) {
      sendRequestErrorToSlack(response);
      return Promise.reject(response);
    }
    // Status 200
    else if (
      (resBody && resBody.errors && resBody.errors.length) ||
      (responseData && responseData.errors && responseData.errors.length)
    ) {
      // Requests status 200 but Error found in response body
      if (resBody && resBody.errors && resBody.errors.length) {
        sendRequestErrorToSlack(response);
        return Promise.reject(resBody.errors);
      } else {
        sendRequestErrorToSlack(response);
        return Promise.reject(responseData.errors);
      }
    } else {
      // Success response
      return response;
    }
  },
  error => {
    const response = error.response;
    let errorCode = response?.data?.errorStringCode ? response.data.errorStringCode : false;
    if (!errorCode && response?.data?.errors?.length > 0) {
      errorCode = response.data.errors[0];
    }
    const responseError = errorCode ? errorCode : false;
    sendRequestErrorToSlack(response);
    return Promise.reject(responseError);
  }
);

const getSpaceReservationFields = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/reservation/fields"
  })
    .then(success)
    .catch(fail);
};

const sendSpacePostToUrl = (state, success, fail, url, body) => {
  const urlPath = config.clusterHost + "/stay-api" + url;
  requestService({
    method: "post",
    url: urlPath,
    data: body
  })
    .then(success)
    .catch(fail);
};

const getSpaceConfigurationByService = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/configuration/"
  })
    .then(success)
    .catch(fail);
};

const getSpaceConfigurationByType = (state, success, fail, serviceType) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/type/" + serviceType + "/configuration/list"
  })
    .then(success)
    .catch(fail);
};

const getSpaceRestrictionsByService = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/restriction/"
  })
    .then(success)
    .catch(fail);
};
const getSpaceMultiserviceRestrictionsByService = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/restriction/personalized"
  })
    .then(success)
    .catch(fail);
};

const getSpaceInterval = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/timetable/interval"
  })
    .then(success)
    .catch(fail);
};

const getSpacesTurns = (state, success, fail, data) => {
  requestService({
    method: "post",
    url: config.clusterHost + "/stay-api/booking/spaces/type/" + data.serviceId + "/fields/info",
    data: {
      bookingDate: data.bookingDate,
      pax: data.pax
    }
  })
    .then(success)
    .catch(fail);
};

const getSpaceReservationList = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: `${config.clusterHost}/stay-api/booking/spaces/service/${serviceId}/reservation/list`
  })
    .then(success)
    .catch(fail);
};

const getSpaceReservationFieldsOrder = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/reservation/fields/order"
  })
    .then(success)
    .catch(fail);
};

const getSpaceByService = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/space/?siteFields[]=translatableName"
  })
    .then(success)
    .catch(fail);
};

const getSpaceCombinationInfo = (state, success, fail, serviceId, spaceCombinationId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/combination/" + spaceCombinationId
  })
    .then(success)
    .catch(fail);
};

const cancelSpaceBooking = (state, success, fail, serviceId, bookingId) => {
  const urlPath =
    config.clusterHost +
    "/stay-api/booking/spaces/service/" +
    serviceId +
    "/reservation/update/" +
    bookingId +
    "/client";
  const body = {
    status: 2,
    comment: ""
  };
  requestService({
    method: "post",
    url: urlPath,
    data: body
  })
    .then(success)
    .catch(fail);
};

const getSpaceServiceSummary = (state, success, fail, serviceId) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/timetable/summary"
  })
    .then(success)
    .catch(fail);
};
const getSpaceDaySchedule = (state, success, fail, serviceId, date) => {
  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/service/" + serviceId + "/timetable/schedule/" + date
  })
    .then(success)
    .catch(fail);
};

const getSpaceDayScheduleServiceList = (state, success, fail, serviceList, date) => {
  let list = "";
  serviceList.forEach((service, index) => {
    if (index == 0) {
      list = list + "?";
    } else {
      list = list + "&";
    }
    list = list + "services[]=" + service;
  });

  requestService({
    method: "get",
    url: config.clusterHost + "/stay-api/booking/spaces/services/timetable/schedules/" + date + list
  })
    .then(success)
    .catch(fail);
};

const getSpaceScheduleDays = (state, success, fail, serviceId, date, numberDays) => {
  requestService({
    method: "get",
    url:
      config.clusterHost +
      "/stay-api/booking/spaces/service/" +
      serviceId +
      "/timetable/schedule/" +
      date +
      "/days/" +
      numberDays
  })
    .then(success)
    .catch(fail);
};

const getPaymentPage = (state, success, fail, orderId, bookingId, service, serviceType) => {
  requestService({
    method: "post",
    url: `${config.clusterHost}/tm/establishment/${state.establishment.id}/service/${serviceType}/${service}/payment`,
    data: {
      orderId,
      bookingId
    }
  })
    .then(success)
    .catch(fail);
};

const getPaymentIntent = (state, success, fail, orderId, bookingId, service, serviceType) => {
  requestService({
    method: "post",
    url: `${config.clusterHost}/tm/establishment/${state.establishment.id}/service/${serviceType}/${service}/payment-intent`,
    data: {
      orderId,
      bookingId
    }
  })
    .then(success)
    .catch(fail);
};

const getSpaceOrder = (state, success, fail, orderId, bookingId, service) => {
  requestService({
    method: "get",
    url:
      config.clusterHost +
      "/stay-api/booking/spaces/service/" +
      service +
      "/reservation/" +
      bookingId +
      "/order/" +
      orderId
  })
    .then(success)
    .catch(fail);
};
const getServiceTypeConfigurationByType = (state, success, fail, data) => {
  requestService({
    method: "get",
    url: `${config.clusterHost}/stay-api/booking/spaces/type/${data.serviceTypeId}/configuration/`
  })
    .then(success)
    .catch(fail);
};

const paymentsEnabledForService = (state, success, fail, data) => {
  requestService({
    method: "get",
    url: `${config.clusterHost}/tm/payment-configuration/establishment/${data.establishment}/service/${data.serviceId}`
  })
    .then(success)
    .catch(fail);
};

export {
  getSpaceReservationFields,
  sendSpacePostToUrl,
  getSpaceConfigurationByService,
  getSpaceConfigurationByType,
  getSpaceRestrictionsByService,
  getSpaceMultiserviceRestrictionsByService,
  getSpaceInterval,
  getSpaceReservationList,
  getSpaceReservationFieldsOrder,
  getSpaceByService,
  getSpaceCombinationInfo,
  cancelSpaceBooking,
  getSpaceServiceSummary,
  getSpaceScheduleDays,
  getSpaceDaySchedule,
  getSpaceDayScheduleServiceList,
  getSpacesTurns,
  getPaymentPage,
  getPaymentIntent,
  getSpaceOrder,
  getServiceTypeConfigurationByType,
  paymentsEnabledForService
};
