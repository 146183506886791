import * as surveys from "../../services/shared/surveys";

// initial state
// shape: [{ id, quantity }]
const state = {
  data: {}
};

// getters
const getters = {};

// actions
const actions = {
  getQuestionnaires({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      surveys.getQuestionnaires(
        rootState,
        response => {
          const params = { data: response.data.data, establishmentId: rootState.clusterEstablishmentId };
          if (!response.cached) {
            commit("allSurveys", params);
          }
          resolve(response.data.data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getReplies({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      surveys.getReplies(
        rootState,
        response => {
          const params = { data: response.data.data, establishmentId: rootState.clusterEstablishmentId };
          if (!response.cached) {
            commit("surveysReplies", params);
          }
          resolve(response.data.data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  sendSurvey({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      surveys.sendSurvey(
        rootState,
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        },
        data
      );
    });
  },
  getDisplaySurvey({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      surveys.getDisplaySurvey(
        rootState,
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  sendDisplaySurvey({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      surveys.sendDisplaySurvey(
        rootState,
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        },
        data
      );
    });
  },
  getExternalReviewsConfiguration({ commit, rootState }, establishmentId) {
    return new Promise((resolve, reject) => {
      surveys.getExternalReviewsConfiguration(
        rootState,
        response => {
          commit("externaReviewsConfiguration", {
            data: response.data.data,
            establishmentId: rootState.clusterEstablishmentId
          });
          resolve(response.data.data);
        },
        error => {
          reject(error);
        },
        establishmentId
      );
    });
  },

  getSurveyChatMessages({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      surveys.getSurveyChatMessages(
        rootState,
        response => {
          resolve(response.data.data);
        },
        error => {
          console.error("Error getting chat messages: ", error);
          reject(error);
        },
        data
      );
    });
  },
  sendCommentToSurveyChat({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      surveys.sendCommentToSurveyChat(
        rootState,
        response => {
          resolve(response.data.data);
        },
        error => {
          console.error("Error sending chat messages: ", error);
          reject(error);
        },
        data
      );
    });
  }
};

// mutations
const mutations = {
  allSurveys(state, data) {
    const surveys = state.allSurveys ?? {};
    surveys[data.establishmentId] = {};
    data.data.forEach(survey => (surveys[survey.establishment][survey._id] = survey));
    state.allSurveys = surveys;
  },
  surveysReplies(state, data) {
    let replies = {};
    if (state.surveyReplies) {
      replies = state.surveyReplies;
    }
    replies[data.establishmentId] = data.data;
    state.surveyReplies = replies;
  },
  externaReviewsConfiguration(state, { data, establishmentId }) {
    const externalReviewsConfiguration = { ...state.surveyExternalReviewsConfiguration };

    if (data.externalReviewsConfig) {
      externalReviewsConfiguration[establishmentId] = data.externalReviewsConfig;
    }

    state.surveyExternalReviewsConfiguration = externalReviewsConfiguration;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
