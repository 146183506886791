<template>
  <span class="c-promotion-upsell-chip" :class="{ 'c-promotion-upsell-chip--absolute': absolute }">{{
    promotion
  }}</span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PromotionsUpsellChip",
  props: {
    promotion: {
      type: String,
      required: true
    },
    absolute: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
</script>

<style scoped lang="less">
@import (reference) "../../assets/styleguide.less";

.c-promotion-upsell-chip {
  .u-text__headline--6;
  width: fit-content;

  padding: (@size-base / 2) @size-base*2;
  border-radius: 50px;
  background-color: #fff2be;

  text-align: center;

  &--absolute {
    position: absolute;
    top: -@size-base*3;
    left: @size-base*4;
  }
}
</style>
