export default {
  MODULE_PROPERTIES: 1,
  MODULE_FACILITIES: 2,
  MODULE_ROOMS: 3,
  MODULE_RESTAURANTS: 4,
  MODULE_SPA: 5,
  MODULE_SPORTS: 6,
  MODULE_TOURS: 7,
  MODULE_POI: 8,
  MODULE_PREMIUM_SERVICES: 9,
  MODULE_ACTIVITIES: 10,
  MODULE_SHOWS: 11,
  MODULE_MY_CONGRESSES: 12,
  MODULE_CONGRESSES: 13,
  MODULE_MY_WEDDINGS: 14,
  MODULE_GUESTS: 15,
  MODULE_EMPLOYEES: 16,
  MODULE_TRAVEL_CLUB: 17,
  MODULE_ROOM_SERVICE: 18,
  MODULE_GALLERY: 19,
  MODULE_FRONTPAGE: 20,
  MODULE_ALERTS: 21,
  MODULE_RESORT_CREDIT: 22,
  MODULE_SURVEYS: 23,
  MODULE_MEETME: 24,
  MODULE_PRIVILEGES: 25,
  MODULE_TEMPLATES: 26,
  MODULE_STRINGS: 27,
  MODULE_CONCIERGE: 28,
  MODULE_ADMIN: 29,
  MODULE_WEDDINGS: 30,
  MODULE_IMAGES: 31,
  MODULE_CAR_FINDER: 32,
  MODULE_PEOPLE_FINDER: 33,
  MODULE_FUN_PICTURES: 34,
  MODULE_AUGMENTED_REALITY: 35,
  MODULE_SHAKE_AND_SHARE: 36,
  MODULE_LETSPLAY: 37,
  MODULE_ONDEMAND_BOOKINGS: 38,
  MODULE_DASHBOARD: 39,
  MODULE_CASINO: 40,
  MODULE_CONTACT: 41,
  MODULE_URLS: 42,
  MODULE_CUSTOM: 43,
  MODULE_TRACK_TL: 44,
  MODULE_VISIOGLOBE: 45,
  MODULE_LANDINGS: 46,
  MODULE_HOW_TO_GET: 47,
  MODULE_MAP: 48,
  MODULE_GUEST_SERVICE: 49, //issues
  MODULE_WHAT_TO_DO_NOW: 50,
  MODULE_THEME_PARK_SHOWS: 51,
  MODULE_ESTABLISHMENT_CALENDAR: 52,
  MODULE_SOCIAL_NETWORKS: 53,
  MODULE_MY_TICKETS: 54,
  MODULE_LUMIPLAN_WEBCAMS: 55,
  MODULE_LUMIPLAN_METEO: 56,
  MODULE_LUMIPLAN_LIFTS: 57,
  MODULE_LUMIPLAN_SKI_MAP: 58,
  MODULE_INBOX: 59,
  MODULE_MY_SCHEDULE: 60,
  MODULE_PROMO_ITEMS: 61,
  MODULE_APPEARANCE: 62,
  MODULE_CHAT: 63,
  MODULE_FAQS: 64,
  MODULE_WELCOME: 65,
  MODULE_DEEP_LINKING: 66,
  MODULE_ONLINE_RADIO: 67,
  MODULE_BEACONS: 68,
  MODULE_LOBBY_TOUCH: 69,
  MODULE_WEATHER: 70,
  MODULE_WTDN_SHOWS: 71,
  MODULE_CHECKIN: 72,
  MODULE_AMENITIES: 73,
  MODULE_HOUSEKEEPING: 74,
  MODULE_MOBILE_KEY: 75,
  MODULE_REQUEST: 76,
  MODULE_EXTERNAL_POI: 77,
  MODULE_EXTERNAL_RESTAURANT: 78,
  MODULE_EXTERNAL_TOUR: 79,
  MODULE_TRIPADVISOR: 80,
  MODULE_BOTFAQS: 81,
  MODULE_EDIT_MENU: 82,
  MODULE_ROOM_SERVICE_V2: 83,
  MODULE_EVENT_CALENDAR: 84,
  MODULE_MHIKES: 85,
  MODULE_OTHER_FACILITIES: 86,
  MODULE_POOL: 87,
  MODULE_ATTRACTION: 88,
  MODULE_SHOP: 90,
  MODULE_SERVICE_GROUPING: 91,
  WAITING_TIME: 92,
  MODULE_HOW_TO_GET_V2: 93,
  MODULE_MY_ROOM: 94,
  MODULE_GROUPING_MAP: 96,
  MODULE_REQUEST_CHAT: 97,
  MODULE_COVID19: 98,
  MODULE_WELCOME_MESSAGE: 99,
  MODULE_LAUNDRY: 100,
  MODULE_CHECKOUT: 101,
  MODULE_GROUPING_SEARCH: 102,
  MODULE_LEGAL_TEXTS: 104,
  MODULE_MINICLUB: 105,
  MODULE_OTHER_REQUESTS: 106,
  MODULE_GYM: 107,
  MODULE_DIRECTORY: 108,
  MODULE_BOOKING_SPACES: 109,
  MODULE_CITY_GUIDE: 110,
  MODULE_BALINESE_BED: 111,
  MODULE_ENVIRONMENT: 112,
  MODULE_PROMOTIONS: 113,
  MODULE_INSIGHTS_BENCHMARKS: 114,
  MODULE_BOOKING_SPACES_MULTI_ESTABLISHMENT: 115
};
