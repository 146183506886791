<template>
  <div class="shortcuts-widget" :class="[isDesktop ? 'desktop' : '']">
    <div class="shortcuts-wrapper" :class="['items-' + shortcuts.length]">
      <div
        v-for="(item, index) in shortcuts"
        :key="item.itemId + '-' + index"
        class="shortcut"
        :style="{}"
        @click.prevent="selectedShortcut(item, index)"
      >
        <div
          class="shortcut-icon"
          :style="{
            'background-image': 'url(' + getImageUrl(item.icon, '/original') + ')',
            'background-color': getBackgroudColor()
          }"
        ></div>
        <div class="shortcut-name" :style="{ color: textColor ? textColor : '' }">
          {{ translate(item.translatableName) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate, translateEnglishOrDefaultForced } from "@/services/utils.js";
import { mapState } from "vuex";

export default {
  props: {
    shortcuts: {
      type: Array,
      required: true
    },
    shortcutsBackgroundColor: String,
    textColor: String
  },
  emits: ["action"],
  computed: {
    ...mapState(["isDesktop", "isTV", "isLobby"])
  },
  methods: {
    getImageUrl,
    translate,
    translateEnglishOrDefaultForced,
    getClassName() {
      const classes = {
        1: "one-item",
        2: "two-items",
        3: "three-items"
      };

      const obj = { isDesktop: this.isDesktop || this.isLobby || this.isTV };
      obj[classes[this.shortcuts.length]] = true;
      return obj;
    },
    getBackgroudColor() {
      if (this.shortcutsBackgroundColor && this.shortcutsBackgroundColor !== "") {
        return this.shortcutsBackgroundColor;
      } else {
        return "#" + this.$store.state.mainColor;
      }
    },
    selectedShortcut(item, index) {
      const ind = parseInt(index) + 1;
      const label = "SH" + ind;

      this.$emit("action", {
        data: item,
        index: 0 + "_" + (index + 1),
        title: this.translate(item.translatableName),
        logTitle: this.translateEnglishOrDefaultForced(item.translatableName),
        bubbleType: "shortcut-child",
        bubbleIndex: label
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
@maincolor: var(--main-color);

.shortcuts-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.items-2 {
    margin-left: 110px;
    width: calc(100% - 220px);
  }
  &.items-3 {
    margin-left: 60px;
    width: calc(100% - 120px);
  }
  &.items-4 {
    margin-left: 0;
    width: 100%;
  }
}
.shortcuts-widget {
  font-family: @font-family;
  position: absolute;
  z-index: 100;
  top: 136px;
  left: 0;
  right: 0;

  &.chat-congress {
    margin-top: 200px;
  }

  padding: 0 12px;
  box-sizing: border-box;

  &.desktop {
    width: 400px;
    position: absolute;
    top: 40px;
    left: calc(50% - 200px);
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .shortcut {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    width: 70px;
    .shortcut-icon {
      width: 56px;
      height: 56px;
      border-radius: 28px;
      vertical-align: top;
      background-size: 28px auto;
      background-repeat: no-repeat;
      background-position: 50%;
    }

    .shortcut-name {
      vertical-align: top;
      font-weight: 700;
      font-size: 16px;
      background-size: 20px auto;
      background-repeat: no-repeat;
      background-position: 0px;
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .caption-black;
      .medium;
      line-height: 14px;
      margin-top: 8px;
    }
  }

  &.isDesktop {
    box-sizing: border-box;
    left: ~"calc(50% - 300px)";
    right: auto;
    top: 170px;
    text-align: center;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
    border-radius: 27px;
    padding: 0;

    &.enabledCongressSlider {
      top: 115px;
    }

    .shortcut-name {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &.one-item {
      width: 250px;
      left: ~"calc(50% - 125px)";
    }

    &.two-items {
      width: 500px;
      left: ~"calc(50% - 250px)";
    }

    &.three-items {
      width: 750px;
      left: ~"calc(50% - 375px)";

      .shortcut {
        box-sizing: border-box;
        height: 54px;
        max-width: auto;
        display: inline-block;
        border-radius: 0px;
        .shortcut-name {
          max-width: ~"calc(100% - 20px)";
          margin-top: 4px;
        }

        .shortcut-icon {
          display: none;
        }
        margin-right: 0px;
      }

      .shortcut-0 {
        border-top-left-radius: 27px;
        border-bottom-left-radius: 27px;
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 10px;
          bottom: 10px;
          width: 1px;
          background: rgba(255, 255, 255, 0.3);
        }
      }

      .shortcut-1 {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      .shortcut-2 {
        border-top-right-radius: 27px;
        border-bottom-right-radius: 27px;
      }

      .shortcut-0,
      .shortcut-1 {
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 10px;
          bottom: 10px;
          width: 1px;
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .shortcut-name-three {
      display: none;
    }

    .shortcut-name {
      display: inline-block !important;
    }

    .shortcut-name-three {
      display: none;
    }
  }
}
</style>
