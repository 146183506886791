import config from "@/config";
import * as auth from "@/services/auth.js";
import request from "@/services/request.js";
import store from "@/store";

function getBaseUrl() {
  return store.state.isWidget ? "/api" : config.clusterHost;
}

function getHeaders(state) {
  const bearer = auth.getAuthBearer(state);
  return {
    Authorization: "Bearer " + bearer,
    "Stay-Authorization": state.user.userKey,
    "Stay-Establishment": state.clusterEstablishmentId
  };
}

const getServiceCataloguesV2 = (state, success, fail, data) => {
  let url = `/stay-api/services/catalogue/service/${data.id}/catalogue`;
  if (data.catalogueId) {
    url = `/stay-api/services/catalogue/service/${data.id}/catalogue/${data.catalogueId}`;
  }
  request({
    method: "get",
    headers: { ...getHeaders(state) },
    url: getBaseUrl() + url
  })
    .then(success)
    .catch(fail);
};

const getServicesCatalogueConfiguration = (state, success, fail, data) => {
  const url = `/stay-api/services/catalogue/service/${data.serviceId}/configuration/catalogue/${data.catalogueId}`;
  request({
    method: "get",
    headers: { ...getHeaders(state) },
    url: getBaseUrl() + url
  })
    .then(success)
    .catch(fail);
};
const getServicesCatalogueCategories = (state, success, fail, data) => {
  const url = `/stay-api/services/catalogue/service/${data.servicePublicId}/category/catalogue/${data.catalogueId}/list`;
  request({
    method: "get",
    headers: { ...getHeaders(state) },
    url: getBaseUrl() + url
  })
    .then(success)
    .catch(fail);
};
const getServicesCatalogueSubcategories = (state, success, fail, data) => {
  const url = `/stay-api/services/catalogue/service/${data.servicePublicId}/subcategory/catalogue/${data.catalogueId}/list`;
  request({
    method: "get",
    headers: { ...getHeaders(state) },
    url: getBaseUrl() + url
  })
    .then(success)
    .catch(fail);
};

const getServicesCatalogueProducts = (state, success, fail, data) => {
  const url = `/stay-api/services/catalogue/products/catalogue/${data.catalogueId}/list`;
  request({
    method: "get",
    headers: { ...getHeaders(state) },
    url: getBaseUrl() + url,
    cache: false
  })
    .then(success)
    .catch(fail);
};

export {
  getServiceCataloguesV2,
  getServicesCatalogueConfiguration,
  getServicesCatalogueCategories,
  getServicesCatalogueSubcategories,
  getServicesCatalogueProducts
};
