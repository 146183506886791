import store from "../store";
import { isEmbedPWA } from "./utils";
import timezones from "../utils/enums/timezones";

function getCommonData() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    "user-agent": window.navigator.userAgent,
    locale:
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.userLanguage || navigator.language || navigator.browserLanguage || "es-ES",
    location: timezones[timezone] ? timezones[timezone] : "ES",
    referrer: document.referrer,
    pathname: window.location.pathname,
    href: window.location.href,
    appType: isEmbedPWA() ? "embed" : store.state.isLobby ? "lobby" : "pwa"
  };
}
export function logScreen(pageName, category, serviceType, serviceId, realURL) {
  let data = {
    "real-URL": realURL ? realURL : window.document.location.pathname,
    pageName: pageName.replace(/ - /g, "-").replace(/ /g, "-"),
    category: category,
    serviceType: serviceType,
    serviceId: serviceId ? serviceId : undefined,
    establishmentId: store.state.establishment.id
  };
  data = { ...data, ...getCommonData() };
  sendEvent("page", data);
}

export function logVirtualPage(pageName, category, subcategory) {
  let data = {
    "real-URL": window.document.location.pathname,
    pageName: pageName.replace(/ - /g, "-").replace(/ /g, "-"),
    category: category,
    serviceType: subcategory,
    establishmentId: store.state.establishment.id
  };
  data = { ...data, ...getCommonData() };
  sendEvent("page", data);
  const a = {
    location: "ES"
  };
}

export function logExternalUrl(externalUrl, pageName, category, subcategory) {
  let data = {
    "real-URL": window.document.location.pathname,
    pageName: pageName.replace(/ - /g, "-").replace(/ /g, "-"),
    category: category,
    serviceType: subcategory,
    establishmentId: store.state.establishment.id,
    url: externalUrl
  };
  data = { ...data, ...getCommonData() };
  sendEvent("external-page", data);
}

export function logFrontEvent(pageName, bubbleType, title, bubbleIndexLabel, extraData) {
  let data = {
    "real-URL": window.document.location.pathname,
    pageName: pageName.replace(/ - /g, "-").replace(/ /g, "-"),
    establishmentId: store.state.establishment.id,
    bubbleType: bubbleType,
    bubbleTitle: title,
    bubbleIndex: bubbleIndexLabel
  };
  data = { ...data, ...getCommonData() };
  if (extraData) {
    data = { ...data, ...extraData };
  }
  sendEvent("front-click", data);
}

export function logEvent(pageName, extraData) {
  let data = {
    "real-URL": window.document.location.pathname,
    pageName: pageName.replace(/ - /g, "-").replace(/ /g, "-"),
    establishmentId: store.state.establishment.id
  };
  data = { ...data, ...getCommonData() };
  if (extraData) {
    data = { ...data, ...extraData };
  }
  sendEvent("event", data);
}

export async function sendEvent(event, data) {
  const Tinybird = await window.Tinybird;
  if (!Tinybird) {
    return;
  }
  Tinybird.trackEvent(event, data);
}

export function getAnalyticsUserStatus() {
  let userStatus = "VISITOR";
  if (
    store.state.user &&
    store.state.user.establishments &&
    store.state.user.establishmentId &&
    store.state.user.establishments[store.state.user.establishmentId]
  ) {
    userStatus = "CLIENT";
  }

  if (userStatus === "CLIENT") {
    return "guest";
  } else if (userStatus === "PRE_CLIENT") {
    return "preguest";
  } else {
    return "visitor";
  }
}
