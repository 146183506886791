<template>
  <div class="superwrapper">
    <div
      v-if="data.backgroundImage && frontpageVisible"
      class="wrapper"
      :style="'background-image: url(' + getBackgroundImage(data.backgroundImage, true) + ')'"
      :class="{ blurred: hasForm || hasPanel || hasInbox || showWelcome }"
    >
      <div class="fullscreen-fade"></div>
      <div v-show="!hideHeader && data.level1Nodes" class="header-logo">
        <transition name="bounceIn">
          <div v-if="frontpageIcon !== 0" class="logo animated fadeIn">
            <img :src="getBackgroundImage(frontpageIcon, true)" />
          </div>
        </transition>
      </div>
      <div v-show="!hideHeader && data.level1Nodes" class="header">
        <div v-show="!hasForm" class="menu">
          <div class="inbox" @click="openAlertsAndCheck">
            <div class="inbox-icon">
              <div v-if="unreadAlertCount > 0" class="unread-count">{{ unreadAlertCount }}</div>
              <div v-else-if="hasPushPermission == false" class="unread-count"></div>
            </div>
          </div>
          <div class="user" :class="{ logged: isLogged() }" @click="openForm">
            <div class="user-icon" :class="{ logged: isLogged() }"></div>
            <div v-if="isLogged()" class="user-info animated fadeIn">
              <div class="user-room">
                {{ user.room }}
              </div>
              <div class="user-name">
                <!-- {{ user.name.split(" ")[0] }} -->
                {{ user.name }}
                <!-- {{"Swasteiger Ar"}} -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="event-area">
        <div
          v-show="hasEvents && actualEvents && actualEvents.length"
          class="event-area-inside"
          :class="{ 'animated fadeInLong': hasNewEvent }"
        >
          <div class="text-event">{{ getString("EVENT").toUpperCase() }}</div>
          <div class="event-slider">
            <flickity :options="flickityOptions" :class="{ noDots: actualEvents.length == 1 }">
              <div
                v-for="actualEvent in actualEvents"
                :key="actualEvent.id"
                class="event-element"
                @click="enterToEventById(actualEvent.id)"
              >
                <div v-if="actualEvent.translatableName" class="name-event">
                  {{ translate(actualEvent.translatableName) }}
                </div>
                <div v-if="actualEvent.startDate && actualEvent.endDate" class="date-event">
                  {{ getMonthDayWeekText(actualEvent) }}
                </div>
              </div>
            </flickity>
          </div>
        </div>
      </div>
      <div class="bubble-area">
        <div v-if="data.level1Nodes && data.level1Nodes.length" class="animated fadeIn" :class="bubblesWrapperClass">
          <slot v-for="(bubbleItem, index) in data.level1Nodes">
            <bubble-set
              v-if="bubbleItem.children && bubbleItem.children[0]"
              :bubble-item="bubbleItem"
              :data="data"
              :index="index + 1"
              :locale="locale"
              :class="'pos' + index"
              :parent-bubble-count="data.level1Nodes.length"
              @action="bubbleTapped"
            ></bubble-set>
            <bubble
              v-else
              :bubble-item="bubbleItem"
              :data="data"
              :index="index + 1"
              :locale="locale"
              @action="bubbleTapped"
            ></bubble>
          </slot>
        </div>
      </div>
    </div>

    <!--<login-form v-show="hasForm" @scrollTop="handleScroll" @guest="userBecameGuest" @backLogin="hasForm = false"></login-form>-->
    <div v-if="hasNewPMS">
      <login-pms-form
        v-if="hasForm && logingFields"
        :loging-fields="logingFields"
        @back-login="hasForm = false"
        @scroll-top="handleScroll"
        @guest="userBecameGuest"
        @close-form="openForm"
      ></login-pms-form>
    </div>
    <div v-else>
      <login-form
        v-if="hasForm"
        @scroll-top="handleScroll"
        @guest="userBecameGuest"
        @close-form="openForm"
      ></login-form>
    </div>
    <div
      v-if="showChatWidget && showWelcomeMessage && !hasForm && !hasInbox && !hasPanel && !showWelcome"
      class="chat-welcome-wrapper animated bounceIn"
    >
      <div class="chat-welcome-bubble" @click="$router.push('/chat')">
        <div class="chat-welcome-text">{{ welcomeMessage() }}</div>
      </div>
      <div class="chat-bubble-triangle"></div>
    </div>

    <div
      v-if="showChatWidget && !hasForm && !hasInbox && !hasPanel && !showWelcome"
      :key="'chat-widget'"
      class="chat-widget animated bounceIn"
      leave-active-class="animated fadeOut"
      @click="showChat()"
    >
      <div class="chat-widget-icon"></div>
    </div>

    <survey
      v-if="showSurvey && surveyData && surveyData.name && !serviceDetailData"
      ref="survey"
      class="survey animated fadeIn"
      :survey-data="surveyData"
      @close="
        showSurvey = false;
        hideThisSurvey(surveyData);
      "
    ></survey>
    <welcome-popup
      v-show="showWelcome"
      :icon="getBackgroundImage(frontpageIcon, true)"
      :name="user.name"
      :check-in="user.checkIn"
      :check-out="user.checkOut"
      @close-welcome-popup="
        showWelcome = false;
        togglePendingTerms(false);
        startUserDataWithDelay(2);
      "
    ></welcome-popup>
    <service-popup
      v-if="showServicePopup && servicePopupServiceData"
      ref="servicePopup"
      class="survey animated fadeIn"
      :service="servicePopupServiceData"
      @close="
        showServicePopup = false;
        servicePopupServiceData = false;
      "
      @go-to-service="
        openService(servicePopupServiceData);
        showServicePopup = false;
      "
    ></service-popup>

    <promotions-popup
      v-if="displayPromotionPopup && !showWelcome"
      key="upsell-popup"
      style="height: 100%"
      :upsell-info-popup="upsellInfoPopup"
      @cancel-up-sell-click="hideUpsellPopup"
    />

    <message-popup
      v-show="showError"
      style="height: 100%"
      :title="getString('LOGIN_ERROR')"
      :text="errorMessage"
      :action-text="getString('CLOSE')"
      @click="showError = false"
    ></message-popup>

    <div v-show="showCongressLoader" class="loading-container mini">
      <div class="loader"></div>
    </div>
    <message-popup
      v-show="showPopupError"
      style="height: 100%"
      :title="getString('WARNING')"
      :text="errorPopupMessage"
      :action-text="getString('CLOSE')"
      @click="showPopupError = false"
    ></message-popup>

    <congress-popup
      v-if="showCongressPopup"
      :is-public="congressRedirectIsPublic"
      :congress-id="new Number(congressIdRedirect)"
      :title="
        isLogged()
          ? getString('CONGRESS_REDIRECT_TITLE_LOGGED') + ' ' + headerCongressRedirect
          : getString('CONGRESS_REDIRECT_TITLE_NO_LOGGED') + ' ' + headerCongressRedirect
      "
      :text="getString('ENTER_EVENT_CODE')"
      :text2="getString('CONGRESS_ARE_YOU_GUEST')"
      :action-text="getString('EVENT_ENTER_BUTTON')"
      :input-text="getString('EVENT_CODE')"
      :full-name-input-text="getString('FULL_NAME')"
      :cancelar-text="getString('CANCEL')"
      @click="congressFromPopup"
      @cancel-click="showCongressPopup = false"
      @name-added="userNameUpdated"
    ></congress-popup>

    <service-detail
      v-if="serviceDetailData && !showWelcome && !showServicePopup && !servicePopupServiceData"
      :service-data="serviceDetailData"
      :class="[showServiceDetail ? 'service-detail service-detail-show' : 'service-detail service-detail-hide']"
      :from-frontpage="true"
      @close-service-detail="closePopupServiceDetail"
    >
    </service-detail>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import mixinServices from "../../services/mixin";
import frontpageMixin from "../frontpageMixin";
import Flickity from "@/components/UiKit/Flickity/Flickity.vue";
import PromotionsPopup from "@/components/Promotions/PromotionsPopup.vue";

const CongressPopup = defineAsyncComponent(() => import("../Congress/PrivateCongressPopup.vue"));
const Bubble = defineAsyncComponent(() => import("./Components/Bubble.vue"));
const BubbleSet = defineAsyncComponent(() => import("./Components/BubbleSet.vue"));

export default {
  components: {
    PromotionsPopup,
    CongressPopup,
    Bubble,
    BubbleSet,
    Flickity
  },
  mixins: [frontpageMixin, mixinServices],
  data() {
    return {};
  }
};
</script>

<style lang="less">
@import (reference) "../../assets/styleguide.less";
@import (reference) "../../assets/loader.css";

.event-slider .flickity-page-dots {
  top: 0;
  right: 0;
  height: 2 * @base-unit;
  width: auto;
}
.noDots .flickity-page-dots {
  display: none;
  // opacity:0;
}
.event-slider .flickity-page-dots .dot {
  background: white;
  height: @base-unit;
  width: @base-unit;
  margin-left: 0px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" rel="stylesheet/less" scoped>
@import (reference) "../../assets/styleguide.less";
.superwrapper {
  height: 100%;
}

.blurred {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
}

.wrapper {
  height: 100%;
  background-size: cover !important;
  background-color: white;
  transition: all 0.2s linear;
  position: fixed;
  top: 0;
  width: 100%;
}

.fullscreen-fade {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(39, 46, 56, 0.45);
}

.header {
  // padding: 6em 5% 1em;
  padding: 4em 5% 0;
  position: fixed;
  width: 90%;
  // height: 10.25 * @base-unit;
  z-index: 1;
}

.menu {
  float: right;
  display: flex;
  flex-direction: row;
}

.logo img {
  max-width: 45%;
  object-fit: contain;
  width: auto;
  height: 8.25 * @base-unit;
}

.bubble-area {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  position: relative;
  // background:blue;
  // margin-top: ~"calc(50% - 95px)";
  // margin-top: 28  * @base-unit;
  // height: 9.5 * @base-unit;
  //     margin-top: 13  * @base-unit;

  // padding: 4%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 4%;
  text-align: center;
  font-size: 2.4em;
  width: 424px !important;
  height: 337px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 2;

  margin-top: 3.5 * @base-unit;
  // margin-top: 10%;
  @media @desktop-xxl {
    margin-top: 50%;
    // margin-top: 32  * @base-unit;
  }
  @media @mini-mobile {
    // margin-top: 35%;
    // margin-top: 22  * @base-unit;
    margin-top: 1 * @base-unit;
  }

  .fadeIn {
    .bubble-element {
      position: absolute;
    }
    .bubble-element:nth-child(1) {
      position: absolute;
      left: 0;
      left: 163px;
      // top: 80px;
    }

    &.five {
      .bubble-element:nth-child(2) {
        left: 275px;
        // top: 113px;
        top: 33px;
      }
      .bubble-element:nth-child(3) {
        left: 219px;
        // top: 208px;
        top: 128px;
      }
      .bubble-element:nth-child(4) {
        left: 107px;
        // top: 208px;
        top: 128px;
      }
      .bubble-element:nth-child(5) {
        left: 52px;
        // top: 113px;
        top: 33px;
      }

      @media @mini-mobile {
        .bubble-element:nth-child(2) {
          left: 265px;
          // top: 113px;
          top: 33px;
        }
        .bubble-element:nth-child(3) {
          left: 214px;
          // top: 208px;
          top: 128px;
        }
        .bubble-element:nth-child(4) {
          left: 112px;
          // top: 208px;
          top: 128px;
        }
        .bubble-element:nth-child(5) {
          left: 62px;
          // top: 113px;
          top: 33px;
        }
      }
    }

    &.four {
      .bubble-element:nth-child(2) {
        left: 270px;
        // top: 135px;
        top: 55px;
      }
      .bubble-element:nth-child(3) {
        left: 163px;
        // top: 195px;
        top: 115px;
      }
      .bubble-element:nth-child(4) {
        left: 55px;
        // top: 135px;
        top: 55px;
      }

      @media @mini-mobile {
        .bubble-element:nth-child(2) {
          left: 260px;
          // top: 135px;
          top: 55px;
        }
        .bubble-element:nth-child(4) {
          left: 65px;
          // top: 135px;
          top: 55px;
        }
      }
    }

    &.three {
      .bubble-element:nth-child(2) {
        left: 240px;
        // top: 170px;
        top: 90px;
      }
      .bubble-element:nth-child(3) {
        left: 85px;
        // top: 170px;
        top: 90px;
      }
    }
  }
}

.user {
  margin-right: 0.5em;
  background: rgba(52, 50, 61, 0.4);
  // background: red;
  height: 8em;
  width: 8em;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0.85;
  &.logged {
    // width: 16em;
    // width
    width: auto;
    border-radius: 4em;
    justify-content: flex-start;
  }
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.unread-count {
  position: relative;
  right: -50%;
  top: -40%;
  background: red;
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 2em;
  width: 1.5em;
  height: 1.5em;
}

.user-icon {
  background: url("../../assets/ic-user.png") no-repeat;
  height: 3.9em;
  width: 3.9em;
  background-size: contain;
  &.logged {
    margin-left: 2em;
    display: none;
  }
}

.user-info {
  color: white;
  font-size: 2em;
  // margin-left: 0.5em;
  // margin-left: @base-unit;
  // margin-right: @base-unit;
  // background:blue;
  text-align: left;

  padding-right: 2 * @base-unit;
  padding-left: 2 * @base-unit;
  padding-bottom: 0.3 * @base-unit;
  // padding-top: 1*@base-unit;
  min-width: 7.5 * @base-unit;
  max-width: 13 * @base-unit;
  overflow: hidden;
  .user-room {
    .bodyFont;
    .white;
    .semibold;
    // background:blue;
  }
  .user-name {
    .smallFont;
    .white;
    .semibold;

    max-height: 1.7 * @base-unit;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // background:red;
    // max-width: 4em;
    // white-space: normal;
    // text-overflow: ellipsis;
  }
}

.inbox {
  margin-right: 2em;
  background: rgba(52, 50, 61, 0.4);
  height: 8em;
  width: 8em;
  border-radius: 50%;
  opacity: 0.85;
  display: inline-block;
}

.inbox-icon {
  background: url("../../assets/ic-notifications.png") no-repeat 0.2em center;
  height: 3.9em;
  width: 3.9em;
  background-size: contain;
  margin: 22% 26.4%;
}

.bottom {
  margin-top: 1em;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #35495e;
}

// Fix for bubbles position in iphone 5, 4, 4S etc
@media @mini-mobile {
  .bubble-area {
    padding: 0;
    width: 100%;
    .bubble-wrapper {
      height: 6.5em;
      width: 6.5em;
    }
  }
}
.chat-widget {
  background-color: var(--main-color);
  border-radius: 4 * @base-unit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8 * @base-unit;
  width: 8 * @base-unit;
  position: fixed;
  right: @margin-horizontal;
  bottom: @margin-vertical;
  z-index: 10000;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 0px 15px 0px;
  &.with-action-button {
    bottom: 10 * @base-unit;
  }
  @media (min-width: 750px) and (orientation: landscape) {
    right: 31%;
  }
}

.survey {
  z-index: 12001;
  position: fixed;
}

.chat-widget-icon {
  background: url("../../assets/ic-chatbot@2x.png") no-repeat center;
  background-size: contain;
  height: 4 * @base-unit;
  width: 4 * @base-unit;
}

.chat-welcome-wrapper {
  position: fixed;
  right: 5.5 * @margin-horizontal;
  bottom: 1.2 * @margin-vertical;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
  @media (min-width: 750px) and (orientation: landscape) {
    width: 29%;
    right: 39%;
  }
}
.chat-welcome-bubble {
  z-index: 10001;
  height: 5 * @base-unit;
  background: white;
  border: 1px solid #ddd;
  padding: @base-unit 2 * @base-unit @base-unit 2 * @base-unit;
  border-top-right-radius: 2 * @base-unit;
  border-top-left-radius: 2 * @base-unit;
  border-bottom-left-radius: 2 * @base-unit;
  border-bottom-right-radius: 2 * @base-unit;
  display: flex;
  align-items: center;
  width: 85%;
}
@triangle-size: 1.5 * @base-unit;
.chat-bubble-triangle {
  z-index: 10002;
  border-left: @triangle-size solid white;
  border-top: @triangle-size solid transparent;
  border-bottom: @triangle-size solid transparent;
  width: 0px;
  height: 0px;
  margin-left: -3px;
}

.chat-welcome-text {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 2 * @small-line-height;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  padding-right: 16pt;
  max-width: 100%;
  .smallFont;
  height: 2 * @small-line-height;
}

/* create the ... */
.chat-welcome-text:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 6pt;
  bottom: 0;
}
.chat-welcome-text:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 16pt;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

.event-area {
  // background-size: cover;
  top: 0;
  padding: 0 5% 0;
  position: relative;
  height: 9.5 * @base-unit; //84

  // height: 13 * @base-unit; //104
  margin-top: 13 * @base-unit;
  width: 90%;
  // width: calc(90%);
  // width: 42*@base-unit;
  @media @mini-mobile {
    // margin-top: 35%;
    margin-top: 11 * @base-unit;
  }
  z-index: 1;
}
.event-area-inside {
  position: relative;
  height: 100%;
  // width: ~"calc(100% - (2*@base-unit))";
  padding-left: @base-unit;
  padding-right: @base-unit;

  border-radius: 5px;

  background: rgba(52, 50, 61, 0.7);
  // opacity: 0.85;

  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: center;
}
.text-event {
  .superSmallFont;
  .white;
  margin-top: @base-unit;
  position: absolute;
  background-color: var(--main-color);
  padding: (@base-unit / 4) (@base-unit / 2) (@base-unit / 4);
  border-radius: 5px;
}

.name-event {
  .subheadFont;
  color: white;
  padding-top: (@base-unit / 2);
  padding-bottom: (@base-unit / 4);

  // width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.date-event {
  .smallFont;
  .white;
}
.event-slider {
  height: 7.5 * @base-unit; //8.5
  width: 95%;
  // width: ~"calc(100% - (2*@base-unit))";
  position: absolute;
  margin-top: 1.3 * @base-unit;
  // overflow-x: scroll;
  overflow-y: hidden;
  // background: green;
}
.event-element {
  // position:relative;
  margin-top: 2.2 * @base-unit;
  height: 5.3 * @base-unit;
  width: 100%;
  // background: blue;
}

.header-logo {
  padding: 2.5em 5% 0;
  position: absolute;
  width: 90%;
  z-index: 1;
}
.loading-container {
  height: 40%;
  width: 100%;
  z-index: 1;
  position: fixed;
  display: flex;
  // background-color: red;
  justify-content: center;
  align-content: center;
}
</style>
