<template>
  <div
    v-if="upsellInfoPopup?.notification"
    class="c-promotion-popup animated fadeIn"
    :class="{ 'c-promotion-popup--full-screen': fullScreen }"
  >
    <div
      class="c-promotion-popup__body"
      :class="{ desktop: isDesktop, 'c-promotion-popup__body--full-screen': fullScreen }"
    >
      <div
        class="c-promotion-popup__body__top"
        :class="{ desktop: isDesktop, 'c-promotion-popup__body__top--full-screen': fullScreen }"
      >
        <div
          class="c-promotion-popup__body__top__close"
          :class="{ desktop: isDesktop, 'c-promotion-popup__body__top__close--full-screen': fullScreen }"
          @click="cancelClick"
        >
          <img class="arrow-close" :class="{ desktop: isDesktop }" src="../../assets/closed_white.png" />
        </div>

        <flickity ref="flickity" class="c-promotion-popup__body__top__carousel" :options="getFlickityOptions">
          <div
            v-for="(m, index) in upsellInfoPopup.notification.photographs"
            :key="`m${index}`"
            class="c-promotion-popup__body__top__carousel__item"
            :style="{
              'background-image': `url(${getImageUrl(m, 'ld')})`
            }"
          >
            {{ m }}
          </div>
        </flickity>

        <div class="c-promotion-popup__body__top__image__info">
          <promotions-upsell-chip :promotion="translate(upsellInfoPopup.notification.translatableLabel)" />
          <div class="c-promotion-popup__body__top__image__title" :class="{ desktop: isDesktop }">
            {{ translate(upsellInfoPopup.notification.translatableTitle) }}
          </div>
        </div>
      </div>

      <div
        class="c-promotion-popup__body__container"
        :class="{ 'c-promotion-popup__body__container--full-screen': fullScreen }"
      >
        <div
          v-if="upsellInfoPopup.notification.translatableDescription"
          class="c-promotion-popup__body__description"
          :class="{ desktop: isDesktop, 'c-promotion-popup__body__description--full-screen': fullScreen }"
          v-html="translate(upsellInfoPopup.notification.translatableDescription)"
        ></div>

        <button class="c-promotion-popup__body__button" @click="bookClick">
          {{ translate(upsellInfoPopup.notification.translatableButtonString) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getImageUrl, translate } from "../../services/utils";
import mixin from "../../services/mixin";
import upSellMixin from "@/mixins/upSell/upSellMixin";
import Flickity from "@/components/UiKit/Flickity/Flickity.vue";
import PromotionsUpsellChip from "@/components/Promotions/PromotionsUpsellChip.vue";

export default {
  name: "PromotionsPopup",
  components: { PromotionsUpsellChip, Flickity },
  mixins: [mixin, upSellMixin],
  props: {
    upsellInfoPopup: Object,
    fullScreen: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancelUpSellClick"],
  data() {
    return {
      colorPopup: "#A0A0A0"
    };
  },
  computed: {
    ...mapState(["isDesktop"]),
    getFlickityOptions() {
      return {
        prevNextButtons: false,
        dragThreshold: 1,
        wrapAround: true,
        autoPlay: true,
        contain: true,
        groupCells: true,
        pageDots: true
      };
    }
  },
  mounted() {
    if (this.upsellInfoPopup?.notification) {
      this.notification = this.upsellInfoPopup.notification;
    }
  },
  methods: {
    getImageUrl,
    translate,
    async bookClick() {
      await this.goToBookWithUpSell();
      this.cancelClick();
    },
    cancelClick() {
      this.$emit("cancelUpSellClick");
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../assets/styleguide";

.c-promotion-popup {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  z-index: 20000;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 0 @size-base*4;
  background: rgba(0, 0, 0, 0.2);

  &--full-screen {
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 60vh;
    max-height: 600px;

    border-radius: @size-base*4;
    background: white;

    overflow: hidden;

    &.desktop {
      width: 400px;
      height: 600px;
      border-radius: 16px;
    }

    &__top {
      width: 100%;
      height: calc(70vw - 4 * @size-base);
      position: relative;

      &--full-screen {
        height: @size-base*75;
      }

      &.desktop {
        width: 400px;
        height: 240px;
      }

      &__image {
        width: 100%;
        height: 100%;
        aspect-ratio: 4/3;

        position: relative;

        &.desktop {
          width: 400px;
          height: 240px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          opacity: 0.6;
          background: linear-gradient(180deg, rgba(19, 19, 25, 0) 0%, #131319 100%);

          &.desktop {
            width: 400px;
            height: 240px;
            background: rgba(10, 10, 13, 0.75);
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          gap: @size-base*2;

          position: absolute;
          bottom: @size-base*4;
          left: @size-base*4;
        }

        &__title {
          .u-text__headline--2--white;
        }
      }

      &__carousel {
        width: 100%;
        height: 100%;
        position: relative;

        &__item {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50%;
        }

        .flickity-page-dots {
          top: 25px;
        }
      }

      &__close {
        position: absolute;
        top: @size-base*4;
        right: @size-base*4;
        width: 30px;
        height: 30px;
        z-index: 9;

        &--full-screen {
          left: @size-base*4;
          right: initial;
        }

        &.desktop {
          width: 100%;
          top: @size-base*4;
          position: relative;
          display: flex;
          justify-content: flex-end;
        }

        .arrow-close {
          width: 100%;
          height: 100%;
          &.desktop {
            width: 30px;
            height: 30px;
            margin-right: @size-base*4;
          }
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: @size-base*4;

      width: 100%;
      height: 100%;

      padding: @size-base*4 @size-base*4 @size-base*6;

      &--full-screen {
        height: calc(100% - @size-base*75);
      }
    }

    &__description {
      .u-text__paragraph;
      width: 100%;
      height: calc(100% - 46px);
      max-height: 232px;

      overflow-y: scroll;
      &.desktop {
        width: 400px;
        height: 360px;

        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 24px;
        color: #312f3c;
      }

      &--full-screen {
        max-height: 100%;
        height: 100%;
      }
    }

    &__button {
      cursor: pointer;
      background-color: @color-brand;
      color: white;
      border-radius: 3 * @base-unit;
      padding: 12px 17px;
      height: 46px;
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      justify-content: center;
    }

    &--full-screen {
      max-height: 100%;
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
  }
}
</style>

<style lang="less">
@import (reference) "../../assets/styleguide";

.c-promotion-popup {
  &__body {
    &__top {
      &__carousel {
        .flickity-viewport {
          height: 100% !important;
          background-color: rgb(239, 243, 246);
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
        }

        .flickity-page-dots {
          top: 25px;
          bottom: initial;

          .dot {
            opacity: 0.3;
            background: #fff;

            &.is-selected {
              opacity: 1;
            }
          }
        }
      }
    }

    &__description {
      p {
        margin: 0 0 @size-base*5;
      }
    }
  }
}
</style>
