import config from "../config";
import * as auth from "./auth";
import request from "@/services/request";

const getMultiEstablishmentPublicId = (state, serviceId) => {
  return state.multiEstablishmentPublicId
    ? state.multiEstablishmentPublicId
    : state.multiEstablishment && state.multiEstablishment.length >= 1
      ? state.multiEstablishment.find(item => item.id.toString() === serviceId.toString())?.establishmentPublicId
      : null;
};

const get = (state, success, fail) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/establishment/" + state.establishment.id,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    }
  })
    .then(success)
    .catch(fail);
};

const getStrings = (state, success, fail) => {
  const urlParams = new URLSearchParams({ filter: "mobile" });

  if (state.establishment.id) {
    urlParams.append("establishment", state.establishment.id);
  }

  if (state.establishment.commercialEntity) {
    urlParams.append("chain", state.establishment.commercialEntity);
  }

  request({
    method: "get",
    url: `${config.currentPWAApiHost}/strings?${urlParams.toString()}`,
    headers: {
      "Content-Type": "application/json",
      "cache-control": ""
    },
    cache: false
  })
    .then(success)
    .catch(fail);
};

const getServices = (state, success, fail, serviceType) => {
  const url = `${config.cmsApiHost}/api/v1/service/${serviceType}`;
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getService = (state, success, fail, serviceType, serviceId) => {
  const multiEstablishmentPublicId = getMultiEstablishmentPublicId(state, serviceId);

  request({
    method: "get",
    url: `${config.cmsApiHost}/api/v1/service/${serviceId}`,
    headers: {
      "Stay-Establishment": multiEstablishmentPublicId || state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {},
    cache: false
  })
    .then(success)
    .catch(fail);
};

const getServicePublicId = (state, success, fail, serviceType, servicePublicId) => {
  request({
    method: "get",
    url: `${config.cmsApiHost}/api/v2/services/publicId/${servicePublicId}`,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getServiceCatalogues = (state, success, fail, serviceType, serviceId) => {
  const url = `${config.cmsApiHost}/api/v2/service/${serviceType}/${serviceId}/catalogues`;
  const multiEstablishmentPublicId = getMultiEstablishmentPublicId(state, serviceId);

  request({
    method: "get",
    headers: {
      "Stay-Establishment": multiEstablishmentPublicId || state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    url: url
  })
    .then(success)
    .catch(fail);
};

const getServiceItems = (state, success, fail, serviceType, serviceId) => {
  const url = config.cmsApiHost + "/api/v1/service/" + serviceType + "/" + serviceId + "/item";
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};
const getServiceItem = (state, success, fail, serviceType, serviceId, itemId) => {
  const url = config.cmsApiHost + "/api/v1/service/" + serviceType + "/" + serviceId + "/item/" + itemId;
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getServiceItemCategories = (state, success, fail, serviceType, serviceId) => {
  const url = config.cmsApiHost + "/api/v1/service/" + serviceType + "/" + serviceId + "/itemcategory";
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getCategory = (state, success, fail, serviceType, categoryId) => {
  const url = config.cmsApiHost + "/api/v1/service/" + serviceType + "/category/" + categoryId;
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getCategoryCalendar = (state, success, fail, serviceType, categoryId, query) => {
  let url = config.cmsApiHost + "/api/v1/service/" + serviceType + "/category/" + categoryId + "/calendar";
  if (query) {
    url += "?" + query;
  }
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getShowsCalendar = (state, success, fail, query) => {
  let url = config.cmsApiHost + "/api/v1/service/show/calendar";
  if (query) {
    url += "?" + query;
  }
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getTripAdvisorServices = (state, success, fail) => {
  const url = config.mobileApiHost + "/v1/externalServices/TripAdvisor";
  request({
    method: "post",
    url: url,
    headers: {
      "Stay-Establishment": state.establishment.id
    },
    data: {},
    params: {
      apikey: config.apiKey,
      establishment: state.establishment.id,
      userkey: state.user.userKey,
      devicekey: state.user.deviceKey,
      lastupdate: state.userInbox ? state.userInbox.lastUpdate : 0
    }
  })
    .then(success)
    .catch(fail);
};

const getCategories = (state, success, fail, serviceType) => {
  const url = config.cmsApiHost + "/api/v1/service/" + serviceType + "/category";
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getProduct = (state, success, fail, productId) => {
  const url = `${config.cmsApiHost}/api/v1/product/${productId}`;

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getProducts = (state, success, fail, { serviceType, serviceId }) => {
  const multiEstablishmentPublicId = getMultiEstablishmentPublicId(state, serviceId);

  request({
    method: "get",
    headers: {
      "Stay-Establishment": multiEstablishmentPublicId || state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    url: `${config.cmsApiHost}/api/v1/service/${serviceType}/${serviceId}/product`,
    cache: false
  })
    .then(success)
    .catch(fail);
};

const getProductCategories = (state, success, fail, serviceType, serviceId) => {
  const url = config.cmsApiHost + "/api/v1/service/" + serviceType + "/" + serviceId + "/productcategory";
  const multiEstablishmentPublicId = getMultiEstablishmentPublicId(state, serviceId);

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": multiEstablishmentPublicId || state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {},
    cache: false
  })
    .then(success)
    .catch(fail);
};

const getBookingConfig = (state, success, fail, serviceType, serviceId) => {
  const url = `${config.cmsApiHost}/api/v1/service/${serviceType}/${serviceId}/bookingConfig`;
  const multiEstablishmentPublicId = getMultiEstablishmentPublicId(state, serviceId);

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": multiEstablishmentPublicId || state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getMapPoints = (state, success, fail) => {
  const url = config.cmsApiHost + "/api/v1/map/points";
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getMapCategories = (state, success, fail, serviceType, serviceId) => {
  const url = config.cmsApiHost + "/api/v1/map/category";
  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getUrls = (state, success, fail, data) => {
  const url = `${config.cmsApiHost}/api/v1/urls`;

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    }
  })
    .then(success)
    .catch(fail);
};

const getDeepLinks = (state, success, fail, data) => {
  const url = config.mobileApiHost + "/v1/deepLink/getLinks";
  request({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      branchUrl: true,
      facebookUrl: true,
      channel: "PopUpPWA"
    },
    params: {
      apikey: config.apiKey,
      establishment: state.establishment.id,
      userkey: state.user.userKey,
      devicekey: state.user.deviceKey,
      lastupdate: state.userInbox ? state.userInbox.lastUpdate : 0
    }
  })
    .then(success)
    .catch(fail);
};

const getDeepLinkData = (state, success, fail, data) => {
  const language = window.navigator.language.substring(0, 2);

  const url =
    config.mobileApiHost +
    "/v1/deepLink/getData/" +
    data.idLink +
    "?userkey=" +
    data.userKey +
    "&apikey=" +
    config.apiKey +
    "&establishment=" +
    data.establishmentId +
    "&devicekey=" +
    data.deviceKey;

  request({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: { language: language },
    params: {}
  })
    .then(success)
    .catch(fail);
};

const sendPostToUrl = (state, success, fail, url, body, headers) => {
  if (!headers) {
    headers = {
      "Content-Type": "application/json"
    };
  }
  request({
    method: "post",
    url: url,
    headers: headers,
    data: body
  })
    .then(success)
    .catch(fail);
};

const sendGetToUrl = (state, success, fail, url) => {
  request({
    method: "get",
    url: url
  })
    .then(success)
    .catch(fail);
};

const getFrontpageIcon = (state, success, fail) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/template/establishment/bubbles",
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    data: {},
    params: {}
  })
    .then(success)
    .catch(fail);
};

const getSurvey = (state, success, fail, idSurvey) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/survey/" + idSurvey,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    }
  })
    .then(success)
    .catch(fail);
};

const getSurveys = (state, success, fail) => {
  const bearer = auth.getAuthBearer(state);
  request({
    method: "get",
    url: config.clusterHost + "/survey/user/questionnaires",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    }
  })
    .then(success)
    .catch(fail);
};

const getSurveyQuestions = (state, success, fail, idSurvey) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/survey/" + idSurvey + "/question",
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    }
  })
    .then(success)
    .catch(fail);
};

const sendSurvey = (state, success, fail, data) => {
  request({
    method: "post",
    url: config.mobileApiHost + "/v1/survey/responses",
    headers: {
      "Content-Type": "application/json"
    },
    data: data,
    params: {
      apikey: config.apiKey,
      establishment: state.establishment.id,
      userkey: state.user.userKey,
      devicekey: state.user.deviceKey
    }
  })
    .then(success)
    .catch(fail);
};
const sendSurveyV2 = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  request({
    method: "post",
    url: config.clusterHost + "/survey/user/questionnaire/" + data._id,
    data: data.body,
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    }
  })
    .then(success)
    .catch(fail);
};

const getSurveysV2Replies = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  request({
    method: "get",
    url: config.clusterHost + "/survey/user/replies",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    cache: false
  })
    .then(success)
    .catch(fail);
};

const getSurveysExternalReviewsConfiguration = (state, success, fail, establishmentId) => {
  const bearer = auth.getAuthBearer(state);
  request({
    method: "get",
    url: config.clusterHost + "/survey/external-reviews-configuration/" + establishmentId,
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    }
  })
    .then(success)
    .catch(fail);
};

const getEventsCalendar = (state, success, fail, serviceType, categoryId, query) => {
  let url = config.cmsApiHost + "/api/v1/service/" + serviceType + "/category/" + categoryId + "/calendar";
  if (query) {
    url += "?" + query;
  }

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
      // 'Content-Type': 'application/json',
    }
  })
    .then(success)
    .catch(fail);
};

const findEnabledModules = (state, success, fail) => {
  const url = config.cmsApiHost + "/api/v1/establishment/" + state.establishment.id + "/functionality/all";

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    }
  })
    .then(success)
    .catch(fail);
};

const getClusterEstablishmentId = (state, success, fail) => {
  const bearer = auth.getAuthBearer(state);

  const url = "/establishment/legacy/" + state.establishment.id + "?fields[]=_id";

  request({
    method: "get",
    url: config.clusterHost + url,
    headers: {
      Authorization: "Bearer " + bearer
    }
  })
    .then(success)
    .catch(fail);
};

const getEstablishmentAssociatedApps = (state, success, fail) => {
  request({
    method: "get",
    url: `${config.clusterHost}/application/client/configuration/establishment/${state.clusterEstablishmentId}`,
    headers: {
      Authorization: `Bearer ${auth.getAuthBearer(state)}`
    }
  })
    .then(success)
    .catch(fail);
};

const getWeather = (state, success, fail) => {
  const url = config.cmsApiHost + "/api/v1/establishment/weather";

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    }
  })
    .then(success)
    .catch(fail);
};

const getProductAttributes = (state, success, fail, serviceType) => {
  const url = `${config.cmsApiHost}/api/v1/product/attributes/type/${serviceType}`;

  request({
    method: "get",
    url: url,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    cache: false
  })
    .then(success)
    .catch(fail);
};

const subscriptionActive = (state, success, fail, establishmentId) => {
  const bearer = auth.getAuthBearer(state);
  const url = "/stay-api/establishment/" + establishmentId + "/subscription";

  request({
    method: "get",
    url: config.clusterHost + url,
    headers: {
      Authorization: "Bearer " + bearer
    },
    cache: false
  })
    .then(success)
    .catch(fail);
};

const unlockAppWithCode = (state, success, fail, establishmentId, code) => {
  const bearer = auth.getAuthBearer(state);
  const url = "/stay-api/establishment/" + establishmentId + "/subscription/check-code/" + code;

  request({
    method: "get",
    url: config.clusterHost + url,
    headers: {
      Authorization: "Bearer " + bearer
    }
  })
    .then(success)
    .catch(fail);
};

const getCorporateContents = (state, success, fail, data) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v2/services/type/" + data.serviceType + "/entity/" + data.commercialEntity,
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    }
  })
    .then(success)
    .catch(fail);
};

const getDateNow = (state, success, fail) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/establishment/time", // config.cmsApiHost + '/api/v1/establishment/time',
    headers: {
      "Stay-Establishment": state.establishmentHash,
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer
    },
    cache: false
  })
    .then(success)
    .catch(fail);
};

const getServiceLegalTextLinks = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  const url = `/stay-api/legal/link/${state.clusterEstablishmentId}/${data.itemType}/${data.itemId}`;
  request({
    method: "get",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    url: config.clusterHost + url
  })
    .then(success)
    .catch(fail);
};

const getLegalText = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  const url = `/stay-api/legal/clauses/${data.id}`;
  request({
    method: "get",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    url: config.clusterHost + url
  })
    .then(success)
    .catch(fail);
};

const getServiceSubscriptionForm = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  const url = `/stay-api/miniclub/form/3/${data.itemId}`;
  request({
    method: "get",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    url: config.clusterHost + url
  })
    .then(success)
    .catch(fail);
};

const getMembers = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);

  let url = `/stay-api/miniclub/membership/itemType/3/itemId/${data.itemId}`;
  if (data.stayId) {
    url += "/stayId/" + data.stayId + "/mobile?status=confirmed";
  }
  request({
    method: "get",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    url: config.clusterHost + url,
    cache: false
  })
    .then(success)
    .catch(fail);
};

const getMember = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  const url = `/stay-api/miniclub/membership/${data.memberId}/3/${data.itemId}`;
  request({
    method: "get",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    url: config.clusterHost + url,
    cache: false
  })
    .then(success)
    .catch(fail);
};

const createMember = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  const url = `/stay-api/miniclub/membership/3/${data.itemId}`;
  request({
    method: "post",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    url: config.clusterHost + url,
    data: data.data
  })
    .then(success)
    .catch(fail);
};

const signMember = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  const url = `/stay-api/miniclub/membership/${data.memberId}/3/${data.itemId}`;
  request({
    method: "put",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    url: config.clusterHost + url,
    data: data.data
  })
    .then(success)
    .catch(fail);
};

const getGDPRConfiguration = (state, success, fail) => {
  const bearer = auth.getAuthBearer(state);
  const url = `/stay-api/legal/gdpr/config`;
  request({
    method: "get",
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    },
    url: config.clusterHost + url
  })
    .then(success)
    .catch(fail);
};

const getCampaignUrl = (state, success, fail, id) => {
  const bearer = auth.getAuthBearer(state);
  request({
    method: "get",
    url: config.clusterHost + "/acquisition/url/" + id,
    headers: {
      Authorization: "Bearer " + bearer,
      "Stay-Authorization": state.user.userKey,
      "Stay-Establishment": state.clusterEstablishmentId
    }
  })
    .then(success)
    .catch(fail);
};

const getMultiEstablishment = (state, success, fail, data) => {
  let url;

  if (data.endpoint === "multi-establishment") {
    url = `/api/v2/services/type/${data.serviceType}/multi-establishment`;
  } else if (data.endpoint === "multi-establishment-list") {
    url = `/api/v2/services/multi-establishment/list`;
  }

  request({
    method: "get",
    headers: {
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer,
      "Stay-Establishment": state.establishment.publicId
    },
    url: config.cmsApiHost + url
  })
    .then(success)
    .catch(fail);
};

const getAppDataByApiKey = (state, success, fail, apiKey) => {
  request({
    method: "get",
    url: `${config.clusterHost}/stay-api/app/${apiKey}`,
    headers: {
      Authorization: `Bearer ${auth.getAuthBearer(state)}`,
    },
  })
    .then(success)
    .catch(fail);
};

export {
  get,
  getStrings,
  getServices,
  getServiceLegalTextLinks,
  getLegalText,
  getServiceSubscriptionForm,
  getMembers,
  getMember,
  createMember,
  signMember,
  getTripAdvisorServices,
  getService,
  getServicePublicId,
  getServiceCatalogues,
  getServiceItems,
  getServiceItem,
  getServiceItemCategories,
  getCategories,
  getCategory,
  getCategoryCalendar,
  getShowsCalendar,
  getProduct,
  getProducts,
  getProductCategories,
  getBookingConfig,
  getMapPoints,
  getMapCategories,
  getUrls,
  getDeepLinks,
  getDeepLinkData,
  sendPostToUrl,
  sendGetToUrl,
  getFrontpageIcon,
  getSurvey,
  getSurveys,
  getSurveyQuestions,
  sendSurvey,
  sendSurveyV2,
  getSurveysV2Replies,
  getSurveysExternalReviewsConfiguration,
  getEventsCalendar,
  findEnabledModules,
  getClusterEstablishmentId,
  getEstablishmentAssociatedApps,
  getWeather,
  getProductAttributes,
  subscriptionActive,
  unlockAppWithCode,
  getCorporateContents,
  getDateNow,
  getGDPRConfiguration,
  getCampaignUrl,
  getMultiEstablishment,
  getAppDataByApiKey,
};
