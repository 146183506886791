import * as catalogueServices from "../services/catalogues";

const catalogues = {
  namespaced: true,
  actions: {
    getServiceCataloguesV2: ({ commit, rootState }, data) => {
      return new Promise((resolve, reject) => {
        catalogueServices.getServiceCataloguesV2(
          rootState,
          response => {
            if (response?.data) {
              resolve(response.data);
            } else {
              reject("TRY_AGAIN");
            }
          },
          error => {
            reject(error);
          },
          data
        );
      });
    },
    getServicesCatalogueConfiguration: ({ commit, rootState }, data) => {
      return new Promise((resolve, reject) => {
        catalogueServices.getServicesCatalogueConfiguration(
          rootState,
          response => {
            if (response?.data) {
              resolve(response.data);
            } else {
              reject("TRY_AGAIN");
            }
          },
          error => {
            reject("TRY_AGAIN");
          },
          data
        );
      });
    },
    getServicesCatalogueCategories: ({ commit, rootState }, data) => {
      return new Promise((resolve, reject) => {
        catalogueServices.getServicesCatalogueCategories(
          rootState,
          response => {
            if (response?.data?.results) {
              resolve(response.data.results);
            } else {
              reject("TRY_AGAIN");
            }
          },
          error => {
            reject("TRY_AGAIN");
          },
          data
        );
      });
    },
    getServicesCatalogueSubcategories: ({ commit, rootState }, data) => {
      return new Promise((resolve, reject) => {
        catalogueServices.getServicesCatalogueSubcategories(
          rootState,
          response => {
            if (response?.data?.results) {
              resolve(response.data.results);
            } else {
              reject("TRY_AGAIN");
            }
          },
          error => {
            reject("TRY_AGAIN");
          },
          data
        );
      });
    },
    getServicesCatalogueProducts: ({ commit, rootState }, data) => {
      return new Promise((resolve, reject) => {
        catalogueServices.getServicesCatalogueProducts(
          rootState,
          response => {
            if (response?.data) {
              resolve(response.data);
            } else {
              reject("TRY_AGAIN");
            }
          },
          error => {
            reject("TRY_AGAIN");
          },
          data
        );
      });
    }
  }
};
export default catalogues;
