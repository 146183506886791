import spacesStatus from "../services/spacesStatus";

const getBackgroundColorStatus = function (status, serviceType, isSpaceOrder) {
  if (isSpaceOrder) {
    let colorStatus = "#e5e7f4";
    if (status) {
      switch (status) {
        case spacesStatus.ORDER_RECEIVED_STATUS:
          colorStatus = "#e5e7f4";
          break;
        case spacesStatus.ORDER_IN_PROGRESS_STATUS:
          colorStatus = "#fff0de";
          break;
        case spacesStatus.ORDER_READY_TO_SERVE_STATUS:
          colorStatus = "#f9e9ff";
          break;
        case spacesStatus.ORDER_SERVED_STATUS:
          colorStatus = "#e0f6ea";
          break;
        case spacesStatus.ORDER_DELETED_STATUS:
          colorStatus = "#ff8080";
          break;
        default:
          return;
      }
    }
    return colorStatus;
  }

  const value = getStatusIconForServiceType(status, serviceType);
  switch (value) {
    case "staff_status_new":
      return "#7D86C8";
    case "staff_status_solved":
      return "#62D398";
    case "staff_status_in_progress":
      return "#EDC861";
    case "staff_status_open":
      return "#eab5f1";
    case "staff_status_reject":
      return "#FF7D78";
    default:
      return "#7D86C8";
  }
};

const getStatusIconForServiceType = function (status, serviceType) {
  switch (serviceType) {
    case 26:
    case 30:
    case 31:
    case 47:
    case 55:
      return getStatusIconForRequests(status);
    default:
      return getStatusIcon(status);
  }
};

const getStatusIcon = function (status) {
  switch (status) {
    case 0:
      return "staff_status_new";
      break;
    case 1:
      return "staff_status_solved";
      break;
    case 2:
      return "staff_status_reject";
      break;
    case 3:
      return "staff_status_reject";
      break;
    case 4:
      return "staff_status_in_progress";
      break;
    case 5:
      return "staff_status_in_progress";
      break;
    case 7:
      return "staff_status_reject";
      break;
  }
};

const getStatusIconForRequests = function (status) {
  switch (status) {
    case 2:
      return "staff_status_in_progress";
    case 1:
      return "staff_status_new";
    case 0:
    case 5:
      return "staff_status_open";
    case 3:
    case 6:
      return "staff_status_solved";
  }
};

const manageStatus = function (serviceType, status, isSpaceOrder) {
  switch (serviceType) {
    case 26:
    case 30:
    case 31:
    case 47:
    case 55:
      return manageRequestStatus(status);
      break;
    default:
      return manageBookingStatus(status, isSpaceOrder);
  }
};

const manageRequestStatus = function (status) {
  switch (status) {
    case 2:
      return "IN_PROGRESS";
      break;
    case 1:
      return "NEW";
      break;
    case 0:
    case 5:
      return "PENDING";
      break;
    case 3:
    case 6:
      return "SOLVED";
      break;
  }
};

const manageBookingStatus = function (status, isSpaceOrder) {
  if (isSpaceOrder) {
    switch (status) {
      case spacesStatus.ORDER_RECEIVED_STATUS:
        return "NEW";
      case spacesStatus.ORDER_IN_PROGRESS_STATUS:
        return "IN_PROGRESS";
      case spacesStatus.ORDER_READY_TO_SERVE_STATUS:
        return "READY_TO_SERVE";
      case spacesStatus.ORDER_SERVED_STATUS:
        return "CONFIRMED";
      case spacesStatus.ORDER_DELETED_STATUS:
        return "CANCELED";
      default:
        return "";
    }
  }
  switch (status) {
    case 0:
      return "NEW";
      break;
    case 1:
      return "APPROVE";
      break;
    case 2:
      return "REJECTED";
      break;
    case 3:
      return "REJECTED";
      break;
    case 7:
      return "REJECTED";
      break;
    default:
      break;
  }
};

const checkMessageIsClient = function (booking) {
  const chatMessages = booking.requestChat;
  if (chatMessages && chatMessages.length > 0) {
    const lastMessage = chatMessages[chatMessages.length - 1];
    if (lastMessage.type === "guest") {
      if (booking.ts == lastMessage.timeZoneTs) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

const checkIsMessage = function (booking) {
  const chatMessages = booking.requestChat;
  if (chatMessages && chatMessages.length > 0) {
    const lastMessage = chatMessages[chatMessages.length - 1];
    if (lastMessage.type === "staff") {
      let bookingTs;
      if (booking.timezoneTs) {
        bookingTs = booking.timezoneTs;
      } else if (booking.ts) {
        bookingTs = booking.ts;
      }
      if (bookingTs === lastMessage.timeZoneTs) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

const checkIsObject = function (booking) {
  const chatMessages = booking.requestChat;
  if (chatMessages && chatMessages.length > 0) {
    const lastMessage = chatMessages[chatMessages.length - 1];
    if (lastMessage.type === "staffRichObject") {
      return true;
    } else {
      return false;
    }
  }
};

const isChangeInBookingNotifiable = function (booking) {
  const isMobileBooking = booking.mobileBooking;
  let isNew = booking.status === 0;

  if (
    booking &&
    booking.customData &&
    booking.customData.bookingSpace &&
    booking.customData.bookingSpace.hasOwnProperty("orderStatus")
  ) {
    isNew = booking.customData.bookingSpace.orderStatus == 0;
  }

  if (booking?.bookingSystem == 11 && booking.customData?.bookingSpace?.bookingEnabled == 7) {
    isNew = false;
  }

  let lastMessageFromStaff = false;
  const chatMessages = booking.requestChat;
  if (chatMessages && chatMessages.length > 0) {
    const lastMessage = chatMessages[chatMessages.length - 1];
    if (lastMessage.type === "staff") {
      lastMessageFromStaff = true;
    }
  }
  return isMobileBooking === false || lastMessageFromStaff || (isMobileBooking === true && !isNew);
};

const isChangeInRequestNotifiable = function (request) {
  let changedStatus = request.status !== 1;
  let changedStatusConfirmed = false;
  let lastMessageFromStaff = false;
  const chatMessages = request.requestChat;

  const isMobileRequest = request.mobileLead;

  if (chatMessages && chatMessages.length > 0) {
    const lastMessage = chatMessages[chatMessages.length - 1];
    if (
      (lastMessage.type === "staff" || lastMessage.type === "staffRichObject") &&
      request.timezoneTs === lastMessage.timeZoneTs
    ) {
      lastMessageFromStaff = true;
    }
    changedStatus = request.status !== 1 && request.timezoneTs !== lastMessage.timeZoneTs;

    if (
      isMobileRequest != undefined &&
      isMobileRequest === false &&
      !changedStatus &&
      request.status === 1 &&
      lastMessage &&
      lastMessage.type === "auto"
    ) {
      changedStatus = true;
    }

    changedStatusConfirmed =
      (request.status == 3 || request.status == 6) && request.timezoneTs == lastMessage.timeZoneTs;
  }
  return changedStatus || lastMessageFromStaff || changedStatusConfirmed;
};
const getTransactionStatusStringKey = function (status, serviceType, isSpaceOrder) {
  switch (serviceType) {
    case 26:
    case 30:
    case 31:
    case 47:
    case 55:
      return getRequestStatusString(status);
    default: {
      if (isSpaceOrder) {
        return getSpaceOrderStatusString(status);
      } else {
        return getBookingStatusString(status);
      }
    }
  }
};
const getSpaceOrderStatusString = function (status) {
  switch (status) {
    case spacesStatus.ORDER_RECEIVED_STATUS:
      return "NEW";
    case spacesStatus.ORDER_IN_PROGRESS_STATUS:
      return "IN_PROGRESS";
    case spacesStatus.ORDER_READY_TO_SERVE_STATUS:
      return "READY_TO_SERVE";
    case spacesStatus.ORDER_SERVED_STATUS:
      return "CONFIRMED";
    case spacesStatus.ORDER_DELETED_STATUS:
      return "CANCELED";
    default:
      break;
  }
  return "";
};
const getBookingStatusString = function (status) {
  switch (status) {
    case 0:
      return "PENDING";
    case 1:
      return "CONFIRMED";
    case 2:
      return "CANCELED";
    case 3:
      return "REJECTED";
    case 4:
      return "PROPOSAL_RECEIVED";
    case 5:
      return "PROPOSAL_SENT";
    case 7:
      return "REJECTED";
    case 9:
      return "PAYMENT_REQUIRED";
    case 11:
      return "PRECONFIRMED_AND_PAID";
    case 12:
      return "CONFIRMED_AND_PAID";
  }
  return "";
};
const getRequestStatusString = function (status) {
  switch (status) {
    case 0:
    case 5:
      return "PENDING";
    case 1:
      return "NEW";
    case 2:
      return "IN_PROGRESS";
    case 3:
    case 6:
      return "CONFIRMED";
    default:
      return "";
  }
};

export {
  getBackgroundColorStatus,
  getStatusIconForServiceType,
  getStatusIcon,
  getStatusIconForRequests,
  manageRequestStatus,
  manageBookingStatus,
  manageStatus,
  checkMessageIsClient,
  checkIsMessage,
  checkIsObject,
  isChangeInBookingNotifiable,
  isChangeInRequestNotifiable,
  getTransactionStatusStringKey
};
