<template>
  <div class="superwrapper">
    <div
      class="frontpage-v4"
      :style="{
        background: `
          ${
            data.mobileBackgroundOverlay || !data.hasOwnProperty('mobileBackgroundOverlay')
              ? 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), fixed'
              : ''
          } ${data.backgroundImage ? `url(${getBackgroundImage(data.backgroundImage, true)}` : ''})`
      }"
    >
      <div
        v-if="(frontpageVisible || isCmsMode) && !hasForm"
        class="frontpage-v4__header"
        :class="{ 'frontpage-v4__header--embed': isFullEmbedPWA() && !isMonoHotelApp() }"
      >
        <div
          v-if="isFullEmbedPWA() && !isMonoHotelApp()"
          class="frontpage-v4__header__exit"
          @click="emitExitEstablishmentEventoToEmbedded"
        >
          <div class="exit-icon"></div>
        </div>

        <div v-show="!hideHeader && data.level1Nodes" class="frontpage-v4__header__logo">
          <transition name="bounceIn">
            <img
              v-if="frontpageIcon !== 0"
              class="animated fadeIn embed"
              :src="getBackgroundImage(frontpageIcon, true)"
            />
          </transition>
          <div class="powered-by" :style="{ color: data.textColor ? data.textColor : '' }">
            <a
              v-if="poweredBy"
              href="https://www.stay-app.com?utm_campaign=anonymous&utm_medium=poweredbystay&utm_source=poweredbystay"
              target="_blank"
              >Powered by Stay-App
            </a>
            <span v-else>
              {{ establishment.name }}
            </span>
          </div>
        </div>

        <div
          v-if="!hideLoginButton && !loadingEstablishment"
          v-show="!hideHeader && data.level1Nodes"
          class="frontpage-v4__header__boxes header-boxes animated fadeIn"
          :class="{ logged: isLogged() }"
        >
          <div v-if="notificationsCount > 0 && isLogged()" class="unread-count logged"></div>
          <div v-show="!hasForm" ref="user-button" class="menu">
            <div class="user" :class="{ logged: isLogged() }" @click="showProfilePopup">
              <div class="user-login">
                <div class="user-icon" :class="{ logged: isLogged() }"></div>
                <div v-if="!isLogged()" class="login-label subheader-white medium">Log in</div>
              </div>
              <div v-if="isLogged()" class="user-info animated fadeIn">
                <div class="user-room subheader-white medium">
                  {{ user.room }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="notificationsCount > 0 && !isLogged()" class="unread-count"></div>
        </div>

        <transition
          name="custom-classes-transition"
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <shortcuts-widget-v2
            v-if="data.shortcuts && data.shortcuts.length"
            class="frontpage-v4__header__shortcuts"
            :shortcuts="data.shortcuts"
            :shortcuts-background-color="data.shortcutsBackgroundColor"
            :text-color="data.textColor"
            @action="bubbleTapped"
          ></shortcuts-widget-v2>
        </transition>

        <transition
          name="custom-classes-transition"
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <div
            v-if="showChatWidget && (!surveyData || !showSurvey) && (!data.shortcuts || !data.shortcuts.length)"
            class="frontpage-v4__header__bot-chat"
            :class="{ 'chat-congress': hasEvents && actualEvents && actualEvents.length, 'only-globe': onlyGlobe() }"
          >
            <div
              class="frontpage-v4__header__bot-chat__background"
              :class="{
                sticky: scrollY > (hasEvents && actualEvents && actualEvents.length ? 200 : 115),
                fullscreen: isChatActive,
                'only-globe': onlyGlobe()
              }"
              @click="showChat()"
            >
              <div class="chat-float-icon" :class="{ 'only-globe': onlyGlobe() }" />
              <div class="chat-float-text" :class="{ 'only-globe': onlyGlobe() }">
                <vue-typer :text="user.locale === 'es' ? wordsEs : words"></vue-typer>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div
        v-if="frontpageVisible || isCmsMode"
        class="wrapper-boxes"
        :class="{
          blurred: hasForm || hasPanel || hasInbox || showWelcome,
          'no-shortcuts': !data.shortcuts || data.shortcuts.length === 0
        }"
      >
        <div
          id="boxesarearef"
          class="boxes-area"
          :class="[
            !scrollableBoxes && !isCmsMode
              ? 'no-scrollable'
              : [!showChatWidget && (!data.shortcuts || data.shortcuts.length == 0) ? 'boxes-no-margin' : '']
          ]"
        >
          <div v-if="data.upsellEnabled && filteredUpsellNode.length" class="upsell-widget-class">
            <upsell-frontpage
              :upsellnodes="filteredUpsellNode"
              :upselldark-background="false"
              :upsell-background-color="data.upsellBackgroundColor"
              @action="bubbleTapped"
            ></upsell-frontpage>
          </div>

          <div class="">
            <div v-if="hasEvents && actualEvents && actualEvents.length" class="event-area">
              <div class="event-area-inside" :class="[animateEvents ? 'animated fadeInLong' : 'animated fadeIn']">
                <div v-if="false" class="text-event" :style="{ color: data.textColor ? data.textColor : '' }">
                  {{ getString("MY_NEXT_EVENTS") }}
                </div>
                <div
                  v-for="actualEvent in actualEvents"
                  :key="actualEvent.id"
                  class="event-element"
                  @click="enterToEventById(actualEvent.id)"
                >
                  <div class="event-text-and-image">
                    <img
                      v-if="actualEvent.photographs && actualEvent.photographs.length > 0"
                      :src="getImageUrl(actualEvent.photographs[0])"
                      class="event-image"
                    />
                    <div
                      :class="[
                        actualEvent.photographs && actualEvent.photographs.length > 0
                          ? 'event-info'
                          : 'event-info-wo-image'
                      ]"
                    >
                      <div v-if="actualEvent.translatableName" class="name-event">
                        {{ translate(actualEvent.translatableName) }}
                      </div>
                      <div v-if="actualEvent.startDate && actualEvent.endDate" class="date-event">
                        {{ getMonthDayWeekText(actualEvent) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="data.level1Nodes && data.level1Nodes.length" class="animated fadeIn">
              <slot v-for="(bubbleItem, index) in data.level1Nodes">
                <frontpage-v4-row
                  :index="index"
                  :bubble-item="bubbleItem"
                  :data="data"
                  :class="bubbleItem.class"
                  :locale="locale"
                  @action="bubbleTapped"
                ></frontpage-v4-row>
              </slot>
            </div>
          </div>
        </div>
        <div
          v-if="showChatWidget && !hasForm && !hasInbox && !hasPanel && !showWelcome"
          class="margin-bottom-chat"
          :class="[showSurveyButton ? 'with-survey' : '']"
        ></div>
      </div>
    </div>

    <transition name="slide-vertical">
      <div v-show="fixedHeaderVisible" class="panel-back">
        <div class="establishment-name in-header">
          {{ establishment.name }}
        </div>
        <div v-show="!hideHeader && data.level1Nodes" class="header-boxes in-header" :class="{ logged: isLogged() }">
          <div v-show="!hasForm" class="menu">
            <div class="user in-header" :class="{ logged: isLogged() }" @click="openForm">
              <div class="user-login">
                <div class="user-icon in-header" :class="{ logged: isLogged() }"></div>
                <div v-if="!isLogged()" class="login-label subheader-white medium">Log in</div>
              </div>
              <div v-if="isLogged()" class="user-info in-header animated fadeIn">
                <div class="user-room subheader-white medium in-header">
                  {{ user.room }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="hasNewPMS">
      <login-pms-form
        v-if="hasForm && logingFields"
        :loging-fields="logingFields"
        @back-login="
          hasForm = false;
          scrollableBoxes = true;
        "
        @scroll-top="handleScroll"
        @guest="
          userBecameGuest();
          scrollableBoxes = true;
        "
        @close-form="openForm"
        @scrollable-boxes="scrollableBoxes = true"
        @no-scrollable-boxes="scrollableBoxes = false"
      ></login-pms-form>
    </div>
    <div v-else>
      <login-form
        v-if="hasForm"
        @scroll-top="handleScroll"
        @guest="userBecameGuest"
        @close-form="openForm"
      ></login-form>
    </div>
    <congress-popup
      v-if="showCongressPopup"
      :is-public="congressRedirectIsPublic"
      :congress-id="new Number(congressIdRedirect)"
      :title="
        isLogged()
          ? getString('CONGRESS_REDIRECT_TITLE_LOGGED') + ' ' + headerCongressRedirect
          : getString('CONGRESS_REDIRECT_TITLE_NO_LOGGED') + ' ' + headerCongressRedirect
      "
      :text="getString('ENTER_EVENT_CODE')"
      :text2="getString('CONGRESS_ARE_YOU_GUEST')"
      :action-text="getString('EVENT_ENTER_BUTTON')"
      :input-text="getString('EVENT_CODE')"
      :full-name-input-text="getString('FULL_NAME')"
      :cancelar-text="getString('CANCEL')"
      @click="congressFromPopup"
      @cancel-click="closeCongressPopup"
      @name-added="userNameUpdated"
    ></congress-popup>

    <survey
      v-if="showSurvey && surveyData && surveyData.name && !serviceDetailData && !isCmsMode"
      ref="survey"
      class="survey animated fadeIn"
      :survey-data="surveyData"
      @close="
        showSurvey = false;
        hideThisSurvey(surveyData);
        animateChatWidget();
        scrollableBoxes = true;
      "
      @scrollable-boxes="scrollableBoxes = true"
      @no-scrollable-boxes="scrollableBoxes = false"
    ></survey>

    <promotions-popup
      v-if="displayPromotionPopup && !showWelcome"
      key="upsell-popup"
      style="height: 100%"
      :upsell-info-popup="upsellInfoPopup"
      @cancel-up-sell-click="hideUpsellPopup"
    />

    <welcome-popup
      v-show="showWelcome"
      :icon="getBackgroundImage(frontpageIcon, true)"
      :name="user.name"
      :check-in="user.checkIn"
      :check-out="user.checkOut"
      :show-loading-in-welcome="showLoadingInWelcome"
      @close-welcome-popup="
        showWelcome = false;
        togglePendingTerms(false);
        startUserDataWithDelay(2);
      "
    ></welcome-popup>
    <service-popup
      v-if="showServicePopup && servicePopupServiceData"
      ref="servicePopup"
      class="survey animated fadeIn"
      :service="servicePopupServiceData"
      @close="
        showServicePopup = false;
        servicePopupServiceData = false;
      "
      @go-to-service="
        openService(servicePopupServiceData);
        showServicePopup = false;
      "
    ></service-popup>
    <message-popup
      v-show="showError"
      style="height: 100%"
      :title="getString('LOGIN_ERROR')"
      :text="errorMessage"
      :action-text="getString('CLOSE')"
      @click="showError = false"
    ></message-popup>
    <div v-show="showCongressLoader" class="loading-container mini">
      <div class="loader"></div>
    </div>
    <message-popup
      v-show="showPopupError"
      style="height: 100%"
      :title="getString('WARNING')"
      :text="errorPopupMessage"
      :action-text="getString('CLOSE')"
      @click="showPopupError = false"
    ></message-popup>

    <message-popup
      v-show="confirmExitCongress"
      style="height: 100%"
      :title="getString('EXIT_CONGRESS_TITLE')"
      :text="getString('EXIT_CONGRESS_SUBTITLE')"
      :action-text="getString('OK')"
      :cancelar-text="getString('CANCEL')"
      @click="exitCongress()"
      @cancel-click="confirmExitCongress = false"
    ></message-popup>

    <add-to-home-popup
      v-if="!isEmbedPWA() && !isCmsMode"
      class="modal-screen"
      :class="[
        showAddToHomePopup && !showWelcome && !serviceDetailData && (!showSurvey || !surveyData)
          ? 'modal-screen-show'
          : 'modal-screen-hidden'
      ]"
      :title="getString('ADD_TO_HOME_TITLE')"
      :text="getString('ADD_TO_HOME_SUBTITLE')"
      bullet1-icon="share-ios.png"
      :bullet1-text="getString('ADD_TO_HOME_BULLET1')"
      bullet2-icon="add-to-home-ios.png"
      :bullet2-text="getString('ADD_TO_HOME_BULLET2')"
      :action-text="getString('CLOSE')"
      @click="showAddToHomePopup = false"
    ></add-to-home-popup>

    <service-detail
      v-if="serviceDetailData && !showWelcome && !showServicePopup && !servicePopupServiceData && !isCmsMode"
      :service-data="serviceDetailData"
      :style="{ top: welcomeIsActive ? '0' : '100vh' }"
      :class="[showWelcomeAnimation ? 'service-detail service-detail-show' : 'service-detail service-detail-hide']"
      :from-frontpage="true"
      @close-service-detail="closePopupServiceDetail"
    >
    </service-detail>
    <survey-button
      v-if="surveyProactiveAvailable"
      v-show="showSurveyButton"
      :button-type="surveyButtonTypes.BOTTOM_BANNER"
      :item-type="15"
      :item-id="establishment.id"
      :trigger-type="surveyTriggerTypes.SURVEY_PROACTIVE"
      @survey-available="showSurveyButton = true"
    ></survey-button>
    <div
      v-if="data.floatingBubbles && data.floatingBubbles_nodes && data.floatingBubbles_nodes.length > 0"
      class="floating-bubble-bottom-right"
      :class="[showSurveyButton ? 'with-survey' : '']"
      :style="{ 'background-color': data.floatingBubblesColor }"
      @click="floatingBubbleTapped(data.floatingBubbles_nodes[0], 0)"
    >
      <img :src="getImageUrl(data.floatingBubbles_nodes[0].icon)" class="floating-bubble-icon" />
    </div>
    <profile-popup
      v-if="profilePopupVisible"
      class="animated fadeIn"
      :origin-div="userButtonDiv"
      @show-profile="openForm"
      @close="profilePopupVisible = false"
    ></profile-popup>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import * as types from "@/store/mutation-types";
import mixinServices from "@/services/mixin";
import frontpageMixin from "@/components/frontpageMixin";
import { getImageUrl, isEmbedPWA, isFullEmbedPWA, isMonoHotelApp } from "@/services/utils";
import PromotionsPopup from "@/components/Promotions/PromotionsPopup.vue";

const UpsellFrontpage = defineAsyncComponent(() => import("@/components/UpSell/UpSellFrontpage.vue"));
const ShortcutsWidgetV2 = defineAsyncComponent(
  () => import("@/components/Frontpages/Components/ShortcutsWidgetV2.vue")
);
const FrontpageV4Row = defineAsyncComponent(() => import("@/components/Frontpages/Components/FrontpageV4Row.vue"));
const CongressPopup = defineAsyncComponent(() => import("@/components/Congress/PrivateCongressPopup.vue"));
const AddToHomePopup = defineAsyncComponent(() => import("@/components/AddToHomePopup.vue"));

export default {
  components: {
    PromotionsPopup,
    FrontpageV4Row,
    ShortcutsWidgetV2,
    CongressPopup,
    AddToHomePopup,
    UpsellFrontpage
  },
  mixins: [frontpageMixin, mixinServices],
  data() {
    return {
      scrollY: null,
      isChatActive: false,
      animateEvents: false,
      chatWidgetDeployed: false,
      words: ["Towel", "Restaurant", "Spa", "Kids"],
      wordsEs: ["Toalla", "Restaurante", "Spa", "Niños"],
      fixedHeaderVisible: false
    };
  },
  mounted() {
    this.animateEvents = false;
    window.addEventListener("scroll", this.handleScroll);
    this.$store.commit(types.SURVEY_SHOW_FRONTPAGE, true);
    this.animateChatWidget();
    if (this.hasEvents && this.actualEvents && this.actualEvents.length > 0 && this.newCongress) {
      setTimeout(() => {
        this.scrollToTop();
      }, 2);
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    isEmbedPWA,
    isFullEmbedPWA,
    isMonoHotelApp,
    getImageUrl(image) {
      return getImageUrl(image, "/original");
    },
    handleScroll(event) {
      this.scrollProactive(this.hasForm);
      this.scrollY = Math.round(window.scrollY);
      const scroll = event.srcElement.scrollingElement.scrollTop;
      this.fixedHeaderVisible = scroll > 50;
    },
    onlyGlobe() {
      return !this.chatWidgetDeployed;
    },
    animateChatWidget() {
      this.chatWidgetDeployed = false;
      setTimeout(() => {
        this.chatWidgetDeployed = true;
      }, 500);
    },
    scrollToTop() {
      try {
        this.$store.commit(types.NEW_CONGRESS, false);
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.animateEvents = true;
      } catch (e) {
        console.error("Error", e);
      }
    },
    floatingBubbleTapped(selectedItem, index) {
      const ind = parseInt(index) + 1;
      const label = "F" + ind;

      this.bubbleTapped({
        data: selectedItem,
        index: 0 + "_" + index + 1,
        title: this.translate(selectedItem.translatableName),
        logTitle: this.translateEnglishOrDefaultForced(selectedItem.translatableName),
        bubbleType: "floating-bubble",
        bubbleIndex: label
      });
    },
    emitExitEstablishmentEventoToEmbedded() {
      window.location.href = "https://exit-establishment";
    }
  }
};
</script>

<style lang="less">
@import (reference) "../../assets/styleguide.less";
@import (reference) "../../assets/loader.css";

.upsell-widget-class {
  width: 100%;
  height: calc(58vw - 4 * @base-unit);
  position: relative;
}

.event-slider .flickity-page-dots {
  top: 0;
  right: 0;
  height: 2 * @base-unit;
  width: auto;
}
.noDots .flickity-page-dots {
  display: none;
  // opacity:0;
}
.event-slider .flickity-page-dots .dot {
  background: white;
  height: @base-unit;
  width: @base-unit;
  margin-left: 0px;
}

.frontpage-v4__header__shortcuts {
  .shortcuts-wrapper {
    margin-left: 0 !important;
    width: 100% !important;
    justify-content: center !important;
    gap: @size-base*6;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" rel="stylesheet/less" scoped>
@import (reference) "../../assets/styleguide.less";

.frontpage-v4 {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  height: fit-content;
  background-size: cover !important;
  background-position: center !important;
  background-attachment: fixed;
  padding: calc(@size-base*6 + var(--embed-top-safearea)) @size-base*4 calc(@size-base*6 + var(--embed-bottom-safearea));

  * {
    box-sizing: border-box;
  }

  &__header {
    width: 100%;
    display: grid;
    grid-template-columns: 45% 1fr;
    row-gap: @size-base*8;

    z-index: 11;
    position: relative;

    padding-bottom: @size-base*8;

    &--embed {
      grid-template-columns: 1fr 45% 1fr;
    }

    &__exit {
      background: rgba(52, 50, 61, 0.75);
      height: @size-base*12;
      width: @size-base*12;
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      opacity: 0.85;
      -webkit-transition: width 0.3s ease-in-out;
      -moz-transition: width 0.3s ease-in-out;
      -o-transition: width 0.3s ease-in-out;
      transition: width 0.3s ease-in-out;
      z-index: 10;

      .exit-icon {
        background: url("../../assets/log_out_front.png") no-repeat;
        height: 5.9em;
        width: 5.9em;
        background-size: contain;
      }
    }

    &__logo {
      display: flex;
      flex-direction: column;
      gap: @size-base*4;
      z-index: 1;

      img {
        object-fit: contain;
        object-position: left;
        width: 100%;
        height: 60px;
      }

      .powered-by {
        .h4-black;
        text-transform: none;
        text-align: left;
        text-decoration: none;

        &a {
          color: white;
        }
      }
    }

    &__boxes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &.in-header {
        padding-top: 0;
        &.logged {
          padding-right: 0;
          width: calc(100% - 24px);
        }
      }
      &.logged {
        padding-right: 0;
        width: calc(100% - 16px);
      }
    }

    &__shortcuts {
      position: initial !important;
      grid-column-end: span 2;
      padding: 0 !important;
    }

    &__bot-chat {
      position: relative;
      z-index: 100;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-column-end: span 3;

      padding: 0 @size-base*4;

      &.chat-congress {
        margin-top: 200px;
      }
      &.only-globe {
        align-items: flex-start;
      }

      &__background {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: @color-bg-page;
        border-radius: 5rem;
        box-shadow: rgba(0, 0, 0, 0.9) 0px 0px 15px 0px;
        transition: all ease-in-out 0.5s;

        position: relative;

        &.sticky {
          position: fixed;
          top: 65px;
          width: calc(100% + 2 * @size-base*4);
          border-radius: 0;
          transition: all ease-in-out 0.3s;
        }
        &.fullscreen {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0px;
          right: 0;
          position: fixed;
          animation: chat-onshow-animation 300ms ease 0ms forwards;
          margin-left: 0;
          .chat-float-icon,
          .chat-float-text {
            opacity: 0;
            display: none;
          }
        }
        .vue-typer {
          display: inline-block;
        }
        &.fullscreen.sticky {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0px;
          right: 0;
          position: fixed;
          animation: chat-onshow-animation-2 300ms ease 0ms forwards;
          .chat-float-icon,
          .chat-float-text {
            opacity: 0;
            display: none;
          }
        }
        &.only-globe {
          width: 60px;
        }
      }
    }
  }
}

.superwrapper {
  height: 100%;
  width: 100%;
  // background-color: red;
  // position:fixed;
}

.blurred {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
}

.wrapper-boxes {
  background-size: cover !important;
  transition: all 0.2s linear;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.menu {
  float: right;
  display: flex;
  flex-direction: row;
}

.establishment-name {
  margin-top: 16px;
  text-align: left;
  width: 100%;
  .h4-black;
  &.in-header {
    margin-top: 0;
    width: calc(100% - 120px);
  }
}

.boxes-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  //padding-left: 16px; //4%;

  padding-bottom: 2 * @margin-minimum-unit;

  user-select: none;
  z-index: 9;
}
.boxes-no-margin {
  margin-top: 0px;
}
.bubble-area {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  position: relative;
  // background:blue;
  // margin-top: ~"calc(50% - 95px)";
  // margin-top: 28  * @base-unit;
  // height: 9.5 * @base-unit;
  //     margin-top: 13  * @base-unit;

  // padding: 4%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 4%;
  text-align: center;
  font-size: 2.4em;
  width: 424px !important;
  height: 337px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 2;

  margin-top: 3.5 * @base-unit;
  // margin-top: 10%;
  @media @desktop-xxl {
    margin-top: 50%;
    // margin-top: 32  * @base-unit;
  }
  @media @mini-mobile {
    // margin-top: 35%;
    // margin-top: 22  * @base-unit;
    margin-top: 1 * @base-unit;
  }

  .fadeIn {
    .bubble-element {
      position: absolute;
    }
    .bubble-element:nth-child(1) {
      position: absolute;
      left: 0;
      left: 163px;
      // top: 80px;
    }

    &.five {
      .bubble-element:nth-child(2) {
        left: 275px;
        // top: 113px;
        top: 33px;
      }
      .bubble-element:nth-child(3) {
        left: 219px;
        // top: 208px;
        top: 128px;
      }
      .bubble-element:nth-child(4) {
        left: 107px;
        // top: 208px;
        top: 128px;
      }
      .bubble-element:nth-child(5) {
        left: 52px;
        // top: 113px;
        top: 33px;
      }

      @media @mini-mobile {
        .bubble-element:nth-child(2) {
          left: 265px;
          // top: 113px;
          top: 33px;
        }
        .bubble-element:nth-child(3) {
          left: 214px;
          // top: 208px;
          top: 128px;
        }
        .bubble-element:nth-child(4) {
          left: 112px;
          // top: 208px;
          top: 128px;
        }
        .bubble-element:nth-child(5) {
          left: 62px;
          // top: 113px;
          top: 33px;
        }
      }
    }

    &.four {
      .bubble-element:nth-child(2) {
        left: 270px;
        // top: 135px;
        top: 55px;
      }
      .bubble-element:nth-child(3) {
        left: 163px;
        // top: 195px;
        top: 115px;
      }
      .bubble-element:nth-child(4) {
        left: 55px;
        // top: 135px;
        top: 55px;
      }

      @media @mini-mobile {
        .bubble-element:nth-child(2) {
          left: 260px;
          // top: 135px;
          top: 55px;
        }
        .bubble-element:nth-child(4) {
          left: 65px;
          // top: 135px;
          top: 55px;
        }
      }
    }

    &.three {
      .bubble-element:nth-child(2) {
        left: 240px;
        // top: 170px;
        top: 90px;
      }
      .bubble-element:nth-child(3) {
        left: 85px;
        // top: 170px;
        top: 90px;
      }
    }
  }
}

.background-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.exit {
  margin-top: 4em;
  margin-left: 5%;
  background: rgba(52, 50, 61, 0.4);
  // background: red;
  height: 8em;
  width: 8em;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0.85;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  z-index: 10;
}

.exit-icon {
  margin-right: 5px;
  background: url("../../assets/log_out_front.png") no-repeat;
  height: 5.9em;
  width: 5.9em;
  background-size: contain;
}

.user {
  padding: 0 8px;
  margin-right: 0.5em;
  background: var(--main-color);
  // background: red;
  height: 32px;
  width: auto;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &.logged {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: auto;
    padding: 2px 8px;
    justify-content: flex-start;
  }
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.unread-count {
  position: relative;
  left: -15px;
  top: -9px;
  background: red;
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 2em;
  width: 1.5em;
  height: 1.5em;
  &.logged {
    left: 12px;
  }
}

.user-icon {
  margin-top: 2px;
  background: url("../../assets/loginicon.png") no-repeat;
  height: 16px;
  width: 16px;
  background-size: contain;
  &.logged {
    background: url("../../assets/Room.png") no-repeat;
    background-size: contain;
  }
}

.user-info {
  color: white;
  font-size: 2em;
  // margin-left: 0.5em;
  // margin-left: @base-unit;
  // margin-right: @base-unit;
  // background:blue;
  text-align: left;

  padding-left: @base-unit;
  padding-bottom: 0.3 * @base-unit;
  // padding-top: 1*@base-unit;
  overflow: hidden;
  .user-name {
    .smallFont;
    .white;
    .semibold;
    max-height: 1.7 * @base-unit;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inbox {
  margin-right: 2em;
  background: rgba(52, 50, 61, 0.4);
  height: 8em;
  width: 8em;
  border-radius: 50%;
  opacity: 0.85;
  display: inline-block;
}

.inbox-icon {
  background: url("../../assets/ic-notifications.png") no-repeat 0.2em center;
  height: 3.9em;
  width: 3.9em;
  background-size: contain;
  margin: 22% 26.4%;
}

.bottom {
  margin-top: 1em;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #35495e;
}

.notifications {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}
// Fix for bubbles position in iphone 5, 4, 4S etc
@media @mini-mobile {
  .bubble-area {
    padding: 0;
    width: 100%;
    .bubble-wrapper {
      height: 6.5em;
      width: 6.5em;
    }
  }
}
.chat-widget {
  background-color: var(--main-color);
  border-radius: 4 * @base-unit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8 * @base-unit;
  width: 8 * @base-unit;
  position: fixed;
  right: @margin-horizontal;
  bottom: @margin-vertical;
  z-index: 10000;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 0px 15px 0px;
  &.with-action-button {
    bottom: 10 * @base-unit;
  }
  @media (min-width: 750px) and (orientation: landscape) {
    right: 31%;
  }
}

.survey {
  z-index: 12001;
  position: fixed;
}

.chat-widget-icon {
  background: url("../../assets/ic-chatbot@2x.png") no-repeat center;
  background-size: contain;
  height: 4 * @base-unit;
  width: 4 * @base-unit;
}

.chat-welcome-wrapper {
  position: fixed;
  right: 5.5 * @margin-horizontal;
  bottom: 1.2 * @margin-vertical;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
  @media (min-width: 750px) and (orientation: landscape) {
    width: 29%;
    right: 39%;
  }
}
.chat-welcome-bubble {
  z-index: 10001;
  height: 5 * @base-unit;
  background: white;
  border: 1px solid #ddd;
  padding: @base-unit 2 * @base-unit @base-unit 2 * @base-unit;
  border-top-right-radius: 2 * @base-unit;
  border-top-left-radius: 2 * @base-unit;
  border-bottom-left-radius: 2 * @base-unit;
  border-bottom-right-radius: 2 * @base-unit;
  display: flex;
  align-items: center;
  width: 85%;
}
@triangle-size: 1.5 * @base-unit;
.chat-bubble-triangle {
  z-index: 10002;
  border-left: @triangle-size solid white;
  border-top: @triangle-size solid transparent;
  border-bottom: @triangle-size solid transparent;
  width: 0px;
  height: 0px;
  margin-left: -3px;
}

.chat-welcome-text {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 2 * @small-line-height;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  padding-right: 16pt;
  max-width: 100%;
  .smallFont;
  height: 2 * @small-line-height;
}

/* create the ... */
.chat-welcome-text:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 6pt;
  bottom: 0;
}
.chat-welcome-text:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 16pt;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

.event-area {
  margin-bottom: 2 * @base-unit;
  width: calc(100% - 16px);
}
.event-text-and-image {
  display: flex;
  flex-direction: row;
}
.event-image {
  width: 120px;
  height: 80px;
  border-radius: @base-unit;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  overflow: hidden;
  object-fit: cover;
}
.event-info {
  margin-left: 2 * @base-unit;
  width: 100% - 3 * @base-unit - 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}
.event-info-wo-image {
  .event-info;
  margin-left: 0;
  width: 100% - 2 * @base-unit;
}
.text-event {
  text-transform: none;
  // letter-spacing: 1px;
  display: flex;
  flex: 0 0 100%;
  height: 25px;
  line-height: 25px;

  .subheadFont;
  color: rgb(52, 50, 61);
  .semibold;
}
.name-event {
  .h4-black;
  margin-top: (@base-unit / 2);
  text-align: left;
}
.event-close-button {
  margin-top: (@base-unit / 2);
  height: 3.5 * @base-unit;
  width: 3.5 * @base-unit;
  align-self: flex-end;
  z-index: 1;
}

.date-event {
  .subheader-black;
  margin-top: @base-unit;
  color: @secondary-text;
}
.event-slider {
  height: 7.5 * @base-unit; //8.5
  width: 95%;
  position: absolute;
  margin-top: 1.3 * @base-unit;
  overflow-y: hidden;
}
.event-element {
  // width: 100% - @base-unit;
  margin-bottom: 2 * @base-unit;
  background-color: white;
  border-radius: @base-unit;
  padding: @base-unit;
  box-shadow: 0 12px 22px 0 rgba(105, 113, 129, 0.2);
}

.fullscreen-fade {
  width: 100%;
  height: 150px;
  position: absolute;
  background-color: rgba(39, 46, 56, 0); //0.45
}

.fullscreen-fade-congress {
  width: 100%;
  height: 230px;
  position: absolute;
  background-color: rgba(39, 46, 56, 0); //0.45
}

.header-background-congress {
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  // position:absolute;
}

.background-front {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
}

.background-front-congress {
  width: 100%;
  height: 230px;
  position: absolute;
  background-color: white;
}

.margin-bottom-chat {
  height: @base-unit * 7;
  &.with-survey {
    height: calc(@base-unit * 7 + 80px);
  }
}
.margin-top-event-chat {
  height: @base-unit * 9.5;
}
.no-scrollable {
  // height:auto
  height: 100%;
  top: 150px; //header-background height
  width: 100%;
  position: fixed;
  overflow-y: hidden;
  padding-left: 0 !important; //4%;
  padding-right: 0 !important; //4%;
}

.no-scrollable-congress {
  // height:auto;
  top: 230px; //header-background height
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: hidden;
}

.background-search {
  width: calc(100% - 2 * @margin-horizontal);
  height: 60px;
  background-color: #fbfcfe;
  border-radius: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 0px 15px 0px;
  transition: all ease-in-out 0.5s;
  top: 120px;
  left: 15px;
  // right: 20px;
  position: fixed;
  &.sticky {
    width: 100%;
    left: 0;
    top: 90px;
    position: fixed;
    border-radius: 0;
    transition: all ease-in-out 0.3s;
    margin-left: 0;
  }

  &.fullscreen {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0px;
    right: 0;
    position: fixed;
    animation: chat-onshow-animation 300ms ease 0ms forwards;
    margin-left: 0;
    .chat-float-icon,
    .chat-float-text {
      opacity: 0;
      display: none;
    }
  }
  .vue-typer {
    display: inline-block;
  }
  &.fullscreen.sticky {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0px;
    right: 0;
    position: fixed;
    animation: chat-onshow-animation-2 300ms ease 0ms forwards;
    .chat-float-icon,
    .chat-float-text {
      opacity: 0;
      display: none;
    }
  }
  &.only-globe {
    width: 60px;
  }
}

@keyframes chat-onshow-animation {
  0% {
    top: 120px;
  }
  100% {
    top: 0px;
    bottom: 0;
    border-radius: 0px;
  }
}

@keyframes chat-onshow-animation-2 {
  0% {
    top: 0px;
  }
  100% {
    top: 0px;
    bottom: 0;
    border-radius: 0px;
  }
}

.chat-float-icon {
  margin-left: @margin-horizontal;
  background-size: contain;
  height: 4 * @base-unit;
  width: 4 * @base-unit;
  transition: all ease-in-out 0.4s;
  &.only-globe {
    margin-left: 1.8 * @base-unit;
  }
}

.chat-float-text {
  text-transform: none;
  margin-left: @margin-horizontal;
  .bodyFont;
  color: rgb(105, 113, 129);
  transition: all 0.3s ease-in-out 1s;
  width: calc(100% - 6 * @margin-horizontal);
  font-size: @body-size;
  &.only-globe {
    opacity: 0;
    width: 0;
    font-size: 0;
  }
}

.loading-container {
  height: 80%;
  width: 100%;
  z-index: 1;
  position: fixed;
  display: flex;
  // background-color: red;
  justify-content: center;
  align-content: center;
}

.service-detail {
  z-index: 10001 !important; //under 10002 of enquiries
  height: 100vh;
  width: 100%;
  position: fixed;
  //top: 100vh;
  opacity: 0;
  background-color: white;
  transition: all 0.4s ease;
  pointer-events: none;
  &.service-detail-show {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    height: 100vh;
    overflow: scroll;
  }
  &.service-detail-hide {
    top: 100vh;
    opacity: 0;
    pointer-events: none;
  }
}

.floating-bubble-bottom-right {
  position: fixed;
  bottom: calc(var(--embed-bottom-safearea) + @base-unit);
  right: @base-unit;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 10px 0 rgba(49, 47, 60, 0.3);
  border-radius: 35px;
  z-index: 10;
  &.with-survey {
    bottom: calc(80px + @base-unit);
  }
}
.floating-bubble-icon {
  width: 35px;
  height: 35px;
}

.slide-vertical-enter-active {
  animation: slideInDownFade 0.3s;
}
.slide-vertical-leave-active {
  animation: slideOutUpFade 0.3s;
}
.slide-vertical-enter, .slide-vertical-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate3d(0, -100%, 0);
  opacity: 0;
}
.user-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -2px;
}
.login-label {
  margin-left: 8px;
}
.modal-screen {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 2000;
}
@default-animation-time: 0.2s;
.modal-screen-show {
  top: 0;
  height: 100%;
  transition-property: top;
  transition-timing-function: "ease-in-out";
  transition-duration: @default-animation-time;
}
.modal-screen-hidden {
  top: 100%;
  transition-property: top;
  transition-timing-function: "ease-in-out";
  transition-duration: @default-animation-time;
}
</style>
