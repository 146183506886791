export default {
  DISABLED: 0,
  ENABLED: 1,
  ENABLED_SPA: 2,
  ENABLED_ROOMSERVICE: 3,
  ENABLED_SPACES: 4,
  ENABLED_SPACES_BOOKING_AND_ORDERING: 5,
  ENABLED_SPACES_ONLY_ORDERING: 6,
  ENABLED_SPACES_PRODUCTS_WITH_TURNS: 7
};
