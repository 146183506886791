<template>
  <div
    class="shortcuts-widget"
    :class="[
      getClassName(),
      isDesktop ? 'desktop' : '',
      showUpsell ? 'showUpsell' : '',
      mobile ? `c-shortcuts-widget c-shortcuts-widget--${getItemsClassName()}` : ''
    ]"
  >
    <div
      v-for="(item, index) in shortcuts.slice(0, 3)"
      :key="item.itemId + '-' + index"
      class="shortcut"
      :class="'shortcut-' + index"
      :style="{ background: getBackgroundColor() }"
      @click.prevent="selectedShortcut(item, index)"
    >
      <div class="shortcut-icon" :style="'background-image: url(' + getImageUrl(item.icon, '/original') + ')'"></div>
      <div class="shortcut-name" :style="'background-image: url(' + getImageUrl(item.icon, '/original') + ')'">
        {{ translate(item.translatableName) }}
      </div>
      <div v-if="shortcuts.length === 3" class="shortcut-name-three">{{ translate(item.translatableName) }}</div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate, translateEnglishOrDefaultForced } from "@/services/utils.js";
import { mapState } from "vuex";

export default {
  props: {
    shortcuts: {
      type: Array,
      required: true
    },
    showUpsell: {
      type: Boolean,
      default: false
    },
    shortcutsBackgroundColor: String,
    backgroundColor: {
      type: String,
      required: false
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ["action"],
  computed: {
    ...mapState(["isDesktop", "isTV", "isLobby"])
  },
  methods: {
    getImageUrl,
    translate,
    translateEnglishOrDefaultForced,
    getClassName() {
      const classes = {
        1: "one-item",
        2: "two-items",
        3: "three-items"
      };
      const obj = { isDesktop: this.isDesktop || this.isLobby || this.isTV };
      obj[classes[this.shortcuts.length]] = true;
      return obj;
    },
    getItemsClassName() {
      const classes = {
        1: "one-item",
        2: "two-items",
        3: "three-items"
      };

      return classes[this.shortcuts.length];
    },
    getBackgroundColor() {
      if (this.shortcutsBackgroundColor && this.shortcutsBackgroundColor !== "") {
        return this.shortcutsBackgroundColor;
      } else {
        return "#" + this.$store.state.mainColor;
      }
    },
    selectedShortcut(item, index) {
      this.$emit("action", {
        data: item,
        index: 0 + "_" + (index + 1),
        title: this.translate(item.translatableName),
        logTitle: this.translateEnglishOrDefaultForced(item.translatableName),
        bubbleType: "shortcut-child",
        bubbleIndex: `SH${parseInt(index) + 1}`
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
@maincolor: var(--main-color);

.shortcuts-widget {
  font-family: @font-family;
  position: absolute;
  z-index: 98;
  top: 110px;
  left: 0;
  right: 0;

  .white-background {
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    height: 100px;
    border-radius: 27px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 90%
    );
    &.three-items {
      height: 110px;
      margin-bottom: @base-unit;
    }
  }
  &.desktop {
    position: fixed;
  }

  &.showUpsell {
    z-index: 0;
  }

  &.chat-congress {
    margin-top: 200px;
  }

  padding: 16px 12px;
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .shortcut {
    position: relative;
    height: 48px;
    line-height: 48px;
    background: @maincolor;
    text-align: center;
    cursor: pointer;
    .shortcut-icon {
      width: 20px;
      height: 48px;
      display: inline-block;
      vertical-align: top;
      background-size: 20px auto;
      background-repeat: no-repeat;
      background-position: 0px;
    }

    .shortcut-name {
      padding-left: 30px;
      display: inline-block;
      vertical-align: top;
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
      background-size: 20px auto;
      background-repeat: no-repeat;
      background-position: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .h4-white;
      line-height: 48px;
    }

    .shortcut-name-three {
      color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .subheader-white;
      line-height: 48px;

      &.isDesktop {
        display: none;
      }
      @media @small-mobile {
        font-size: 11px;
      }
    }
  }

  &.one-item {
    .shortcut {
      width: 100%;
      border-radius: 27px;
      .shortcut-name {
        max-width: ~"calc(100% - 50px)";
      }

      .shortcut-icon {
        display: none;
      }
    }
  }

  &.two-items {
    .shortcut {
      width: 50%;

      display: inline-block;
      .shortcut-name {
        max-width: ~"calc(100% - 20px)";
      }

      .shortcut-icon {
        display: none;
      }
    }

    .shortcut-0 {
      border-top-left-radius: 27px;
      border-bottom-left-radius: 27px;
      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 10px;
        bottom: 10px;
        width: 1px;
        background: rgba(255, 255, 255, 0.3);
      }
    }

    .shortcut-1 {
      border-top-right-radius: 27px;
      border-bottom-right-radius: 27px;
    }
  }
  &.three-items {
    .shortcut {
      width: 33%;
      height: 60px;
      display: inline-block;
      .shortcut-name {
        display: none;
      }
      .shortcut-icon {
        height: 36px;
      }
      .shortcut-name-three {
        padding-left: 8px;
        padding-right: 8px;
        position: absolute;
        top: 20px;
        text-align: center;
        width: 100%;
        .subheader-white;
        line-height: 48px;
      }
    }

    .shortcut-0 {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      padding-left: 1%;
    }

    .shortcut-0,
    .shortcut-1 {
      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 10px;
        bottom: 10px;
        width: 1px;
        background: rgba(255, 255, 255, 0.3);
      }
    }

    .shortcut-2 {
      width: 34%;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      padding-right: 1%;
    }
  }

  &.showUpsell {
    top: 0px;
  }

  &.isDesktop {
    position: relative;
    box-sizing: border-box;
    left: ~"calc(50% - 300px)";
    right: auto;
    top: 0;
    margin-top: 80px;
    text-align: center;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
    border-radius: 27px;
    padding: 0;

    &.enabledCongressSlider {
      top: 115px;
    }

    .shortcut-name {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &.one-item {
      width: 250px;
      left: ~"calc(50% - 125px)";
    }

    &.two-items {
      width: 500px;
      left: ~"calc(50% - 250px)";
    }

    &.three-items {
      width: 750px;
      left: ~"calc(50% - 375px)";

      .shortcut {
        box-sizing: border-box;
        height: 54px;
        max-width: auto;
        display: inline-block;
        border-radius: 0px;
        .shortcut-name {
          max-width: ~"calc(100% - 20px)";
          margin-top: 4px;
        }

        .shortcut-icon {
          display: none;
        }
        margin-right: 0px;
      }

      .shortcut-0 {
        border-top-left-radius: 27px;
        border-bottom-left-radius: 27px;
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 10px;
          bottom: 10px;
          width: 1px;
          background: rgba(255, 255, 255, 0.3);
        }
      }

      .shortcut-1 {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      .shortcut-2 {
        border-top-right-radius: 27px;
        border-bottom-right-radius: 27px;
      }

      .shortcut-0,
      .shortcut-1 {
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 10px;
          bottom: 10px;
          width: 1px;
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .shortcut-name-three {
      display: none;
    }

    .shortcut-name {
      display: inline-block !important;
    }

    .shortcut-name-three {
      display: none;
    }
  }
}

.c-shortcuts-widget {
  padding: 16px 12px;
  box-sizing: border-box;
  background: @color-white;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;

  position: relative;
  top: initial;
  left: initial;

  .shortcut {
    width: 100%;
  }
}
</style>
