// Booking systems
export default {
  BOOKING_PENDING: 0,
  BOOKING_CONFIRMED: 1,
  BOOKING_CANCELLED_BY_CLIENT: 2,
  BOOKING_CANCELLED_BY_MANAGER: 3,

  ORDER_RECEIVED_STATUS: 0,
  ORDER_IN_PROGRESS_STATUS: 1,
  ORDER_READY_TO_SERVE_STATUS: 2,
  ORDER_SERVED_STATUS: 3,
  ORDER_DELETED_STATUS: 4
};
