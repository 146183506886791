import config from "@/config";

export default class StayTrackerServiceDefault {
  baseUrl = `${config.clusterHost}/reporting/tracking`;
  #basePayload;
  #userAgent = navigator.userAgent;

  async sendStayEvent({ eventModule, event, eventType, payload, bearer }) {
    return await fetch(this.baseUrl, {
      method: "post",
      headers: {
        Authorization: `Bearer ${bearer}`,
        "Content-Type": "application/json"
      },
      body: await this.#generateBody(eventModule, event, eventType, payload)
    }).then(this.#handlerError);
  }

  async viewEvent({ eventModule, event, eventType, payload, bearer }) {
    const response = await fetch(this.baseUrl, {
      method: "post",
      headers: {
        Authorization: "Bearer " + bearer,
        "Content-Type": "application/json"
      },
      body: await this.#generateBody(eventModule, event, eventType, payload)
    }).then(this.#handlerError);
  }

  async clickEvent(success, fail, { event, eventModule, payload, bearer }) {
    return fetch(this.baseUrl, {
      method: "post",
      headers: {
        Authorization: "Bearer " + bearer,
        "Content-Type": "application/json"
      },
      body: await this.#generateBody(eventModule, event, "click", payload)
    })
      .then(r => r.json())
      .then(response => {
        if (response.errors.length) {
          throw Error(response.errors);
        }

        success(response);
      })
      .catch(fail);
  }

  // private methods
  #getInfoDevice() {
    const { width, height } = window.screen;
    const isMobile = /(mobile|tablet|ipad|iphone|ipod|android|windows phone)/i.test(navigator.userAgent);
    const [os, deviceModel] = this.#userAgent.match(/\(([^)]+)\)/)[1].split(";");
    let [version] = this.#userAgent.match(/\(([^)]+)\)/)[1].split(";");

    if (/iPhone|iPad|iPod|Windows NT/.test(os)) {
      const temp = /Windows NT (\d+\.\d+)|OS (\d+[._]\d+[._]?\d*)/.exec(this.#userAgent);
      version = temp[1] ?? temp[2];
    }

    return {
      locale: navigator.language,
      device: isMobile ? "Mobile" : "Desktop",
      os: os?.trim(),
      version: version?.trim(),
      deviceModel: deviceModel?.trim(),
      screenResolution: `${width}x${height}`
    };
  }

  #getBrowser() {
    const userAgentMatched = this.#userAgent.match(/Firefox|Chrome|Safari|Opera|Trident|AppleWebKit/);
    return (userAgentMatched && userAgentMatched[0]) ?? "unknown";
  }

  async #generateBody(module, event, eventType, payload = {}) {
    this.basePayloadPromise = await (async () => {
      this.#basePayload = {
        ...payload,
        deviceInfo: this.#getInfoDevice(),
        browser: this.#getBrowser()
      };
    })();
    return JSON.stringify({
      source: "pwa",
      module,
      event,
      eventType,
      payload: {
        ...this.#basePayload,
        ...payload
      }
    });
  }

  async #handlerError(response) {
    try {
      if (response.status >= 200 && response.status < 300 && response.statusText === "No Content") {
        return;
      }
      if (response.status === 204 && response.type === "cors") {
        return;
      }

      const json = await response.json();

      if (json?.errors?.length) {
        throw Error(json.errors);
      }

      return json;
    } catch (error) {
      console.error("Error sending stay event", error);
      throw error;
    }
  }
}
