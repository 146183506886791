const Mixin = {
  data() {
    return {
      defaultImage: "../assets/imagedefault.png",
      resolveParam: false
    };
  },
  computed: {},

  created() {},
  methods: {
    getOS() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
      }

      return os;
    },

    isAndroidOS() {
      if (this.getOS() == "Android") {
        return true;
      } else {
        return false;
      }
    },

    haveModuleDigitalKey() {
      if (this.getOS() == "Android") {
        if ("DigitalKey" in window) {
          return true;
        } else {
          return false;
        }
      } else {
        if (window?.webkit?.messageHandlers?.DigitalKey) {
          return true;
        } else {
          return false;
        }
      }
    },

    callAndroidAsync(javaFuncName, params) {
      // const rand = "asyncJava_" + Math.floor(Math.random() * 1000000);
      // window[rand] = {};
      //
      // // func called from android
      // window[rand].callback = isSuccess => {
      //   const dataOrErr = Android.runAsyncResult(rand);
      //   if (isSuccess) window[rand].resolve(dataOrErr);
      //   else window[rand].reject(dataOrErr);
      //   delete window[rand]; // clean up
      // };
      //
      // // call some android function that returns immediately - should run in a new thread
      // // setTimeout(() => window[rand].callback(false, params.val * 2), 4000) // see testCallJavaAsync
      // Android.runAsync(rand, javaFuncName, JSON.stringify(params));
      //
      // return new Promise((resolve, reject) => {
      //   window[rand].resolve = data => resolve(data);
      //   window[rand].reject = err => reject(err);
      // });
    }
  }
};

export default Mixin;
