import { createLogger, createStore } from "vuex";
import frontpage from "./modules/frontpage";
import surveys from "./modules/surveys";
import catalogues from "../../../shared/src/modules/catalogues";
import promotions from "./modules/promotions";
import notificationsModule from "./modules/notificationsModule";
import tracker from "./modules/tracker";
import createPersistedState from "vuex-persistedstate";
import * as actions from "./actions";
import * as mutations from "./mutations";
import config from "@/config";
import initialState from "./initialState";
import { jwtDecode as decode } from "jwt-decode";
import _throttle from "lodash-es/throttle";

const getEstablishmentId = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id") ?? params.get("e");

  if (id) return id;

  const token = params.get("urlToken");

  if (!token) return null;

  const { establishment } = decode(token);

  return establishment;
};

const debug = import.meta.env.NODE_ENV !== "production";
const LIMIT_STORE_KEYS = 3;

const options = {
  key: `stay-store--${getEstablishmentId()}`,
  storage: window.localStorage,
  setState: _throttle((key, state, storage) => {
    let ts = {};
    if (storage.ts) {
      ts = JSON.parse(storage.ts);
    }
    var items = Object.keys(ts);
    if (items.length > LIMIT_STORE_KEYS) {
      items = items.map(key => {
        return [key, ts[key]];
      });

      items.sort((first, second) => {
        return first[1] - second[1];
      });

      var keys = items.map(e => {
        return e[0];
      });
      delete storage[keys[0]];
      delete ts[keys[0]];
    }
    ts[key] = Date.now();
    try {
      storage.ts = JSON.stringify(ts);
      storage[key] = JSON.stringify(state);
    } catch (error) {
      items?.forEach(aKey => {
        if (aKey !== key) {
          delete storage[aKey];
          delete ts[aKey];
        }
      });
    }
    return storage;
  }, 1000)
};

export default createStore({
  state: initialState,
  modules: {
    frontpage,
    surveys,
    catalogues,
    promotions,
    notificationsModule,
    tracker
  },
  actions,
  mutations,
  strict: false,
  plugins:
    config.enableVuexLogger === "true"
      ? [createLogger(), createPersistedState(options)]
      : [createPersistedState(options)]
});
