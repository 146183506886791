const es = [
  "el",
  "la",
  "los",
  "las",
  "y",
  "o",
  "u",
  "un",
  "una",
  "en",
  "unos",
  "unas",
  "de",
  "del",
  "mi",
  "tu",
  "por",
  "a",
  "con",
  "desde",
  "si",
  "me",
  "nos",
  "restaurante",
  "restaurantes",
  "restaurant",
  "restaurants",
  "spa",
  "hora",
  "que",
  "al",
  "para",
  "hotel",
  "es"
];
const en = [
  "the",
  "is",
  "a",
  "an",
  "of",
  "some",
  "my",
  "your",
  "you",
  "with",
  "this",
  "in",
  "and",
  "&",
  "what",
  "do",
  "to",
  "time",
  "i",
  "no",
  "not",
  "from",
  "me",
  "can",
  "out",
  "for",
  "by",
  "or",
  "you",
  "hotel"
];
export { es, en };
