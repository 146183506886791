<template>
  <div class="frontpage-column">
    <div class="column-title">
      {{ getTitle() }}
    </div>

    <frontpage-v3-item
      v-for="(it, index) in bubbleItem.children"
      :ref="`frontpage-v3-item`"
      :key="it.itemId"
      :bubble-item="it"
      :data="data"
      :locale="locale"
      :num-items="numItems[index].n"
      :item-row="numItems[index].row"
      :index="index"
      @action="action"
    ></frontpage-v3-item>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { translate } from "@/services/utils.js";
import mixin from "@/services/mixin";

const FrontpageV3Item = defineAsyncComponent(() => import("./FrontpageV3Item.vue"));

export default {
  components: {
    FrontpageV3Item
  },
  mixins: [mixin],
  props: ["data", "bubbleItem", "index", "locale"],
  emits: ["action"],
  computed: {
    numItems() {
      const n = this.bubbleItem.children.length;
      const options = {
        1: [
          {
            n: 1,
            row: 1
          }
        ],
        2: [
          {
            n: 2,
            row: 1
          },
          {
            n: 2,
            row: 2
          }
        ],
        3: [
          {
            n: 1,
            row: 1
          },
          {
            n: 2,
            row: 1
          },
          {
            n: 2,
            row: 2
          }
        ],
        4: [
          {
            n: 1,
            row: 1
          },
          {
            n: 2,
            row: 1
          },
          {
            n: 2,
            row: 2
          },
          {
            n: 1,
            row: 1
          }
        ],
        5: [
          {
            n: 2,
            row: 1
          },
          {
            n: 2,
            row: 2
          },
          {
            n: 1,
            row: 1
          },
          {
            n: 2,
            row: 1
          },
          {
            n: 2,
            row: 2
          }
        ]
      };

      return options[n] || n;
    }
  },
  methods: {
    getTitle: function () {
      return this.bubbleItem && this.bubbleItem.translatableName
        ? translate(this.bubbleItem.translatableName, { locale: this.locale })
        : "";
    },
    action: function ({ index, title, logTitle }) {
      const selectedItem =
        typeof index === "undefined" || this.bubbleItem.children.length === 0
          ? this.bubbleItem
          : this.bubbleItem.children[index];

      const section = this.index !== undefined ? parseInt(this.index) + 1 : 1;

      this.$emit("action", {
        data: selectedItem,
        index: `${this.index}_${index + 1}`,
        title: title,
        logTitle: logTitle,
        bubbleType: "box-bubble-child",
        bubbleIndex: `S${section}B${parseInt(index) + 1}`
      });
    }
  }
};
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import (reference) "../../../assets/styleguide.less";

.frontpage-column {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  text-align: center;
  margin-bottom: 2 * @base-unit;
}

.column-title {
  text-transform: none;
  display: flex;
  flex: 0 0 100%;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  word-wrap: break-word;
  .h4-black;
}
</style>
