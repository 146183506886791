export default function initialState() {
  return {
    isFromPWA: false,
    isDesktop: false,
    isTV: false,
    isLobby: false,
    isWidget: false,
    os: undefined,
    cmsMode: false,
    establishment: {},
    user: {
      userKey: "",
      deviceKey: "",
      psId: "1052659481528108",
      activity: {}
    },
    congressEvents: {},
    notifications: [],
    frontpageIcon: 0,
    dynamicFrontpage: {},
    elasticResults: [],
    digitalKeyField: [],
    digitalKeyFieldArray: {},
    strings: [],
    services: {},
    categories: {},
    externalServices: {},
    eventsCalendar: {},
    products: {},
    productCategories: {},
    chatMessages: [],
    pendingTermsConditions: {},
    publicEvents: {}, // publicEvents are in getEvents call,
    newEventId: 0,
    hiddenSurveys: {},
    userInbox: {},
    embeddedReturnURL: false,
    appIsDisabled: false,
    grouping: {
      selectedView: false,
      selectedTab: false,
      selectedPointIndex: false
    },
    welcomeSurveyResponseDate: false,
    mySchedule: {},
    myVisitorBookings: {},
    roomServiceCartCache: {},
    notificationsTimesShowed: 0,
    inboxPopupTimesShowed: 0,
    welcomeShow: true,
    welcomeShowVisible: false,
    welcomeShowActive: false,
    offlineMessages: {},
    starterPlan: false,
    allSurveys: {},
    allSurveysShow: {},
    surveyReplies: {},
    surveyExternalReviewsConfiguration: {},
    surveyExternalReviewsAlreadyShown: {},
    surveysFrontpageShow: false,
    cityGuide: {
      bookmarks: {}
    },
    directory: {},
    gdprConfiguration: {},
    shownPromotions: [],
    interfaceLanguage: false,
    infoTracker: {},
    verticalScreen: false,
    verticalScreenWithVideo: false,
    frontpageScroll: 0,
    showOpenObject: {},
    embedSafearea: 0,
    eventPreLogin: false,
    multiEstablishment: [],
    multiEstablishmentPublicId: "",
    scheduleServices: {},
    serviceOrderingFunnelCache: {}
  };
}
