import config from "../config";
import request from "@/services/request";
import requestDigitalKey from "@/services/requestDigitalKey";
import * as auth from "./auth";
import { getmapResponse } from "./utils";
import * as types from "../store/mutation-types";
import cloneDeep from "lodash-es/cloneDeep";

const initDigitalKey = function (digitalKey, provider, router, doubleBack, replaceScreen) {
  return new Promise((resolve, reject) => {
    if (provider.provider != "ASSA_ABLOY") {
      digitalKey.reset = true;
    }
    /*global DigitalKey*/
    /*eslint no-undef: "error"*/
    DigitalKey.initializeWithStayData(JSON.stringify(digitalKey), JSON.stringify(provider), "resultInitData");
  });
};

//comprobar que el step complete es de que tipo
const userDidTypePhoneNumber = function (router, phoneNumber) {
  return new Promise((resolve, reject) => {
    //DigitalKeysetPhoneNumber(phoneNumber);
    //DigitalKey.setPhoneNumber(phoneNumber);
  });
};

const userDidTypeEmail = function (router, email) {
  DigitalKey.setEmail(email); //registrationStepCompletedCallback
};

const startProcess = replace => {};

const showDigitalKeysFunnelExt = (router, stayData, selectedEstablishmentId, store, replaceScreen) => {
  if (store.state.tokenUserArray && store.state.tokenUserArray[store.state.establishment.id] != undefined) {
    // if(dkmodule){
    getFormDigitalKey(store, router, replaceScreen);
    /*  }else{
        showDKErrorScreenRouter(router,"MOBILE_KEY","KEY_ERROR_NO_SDK","OK",false,replaceScreen);
      }*/
  } else {
    showDKErrorScreenRouter(router, "MOBILE_KEY", "KEY_NO_TOKEN", "OK", false, replaceScreen);
  }
};

const showDigitalKeysFunnel = (router, stayData, selectedEstablishmentId, store, replaceScreen) => {
  getFormDigitalKey(store, router, replaceScreen);
};

const getCredencialDigitalKey = (state, success, fail, FieldsKey) => {
  const data = {};
  if (FieldsKey) {
    for (let i = 0; i < FieldsKey.length; i++) {
      if (FieldsKey[i].value) {
        const key = FieldsKey[i].ref;
        data[key] = FieldsKey[i].value;
      }
    }
  }

  const tokenKey = state.tokenUserArray[state.establishment.id];

  const bearerKey = auth.getAuthBearer(state);
  requestDigitalKey({
    method: "post",
    url: config.clusterHost + "/digitalkey/getMyKeys/",
    headers: {
      "x-stay-pms-guest": tokenKey + "",
      "Stay-App-Type": "pwa",
      "Stay-Version": "v1",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerKey
    },
    data
  })
    .then(success)
    .catch(fail);
};

const sendLogDigitalKey = (state, success, fail, log) => {
  const urlComplete = config.clusterHost + "/hook/keylog/";
  const bearerKey = auth.getAuthBearer(state);
  const tokenKey = state.tokenUserArray[state.establishment.id];

  request({
    method: "post",
    url: urlComplete,
    headers: {
      "x-stay-pms-guest": tokenKey + "",
      "Stay-App-Type": "pwa",
      "Stay-Version": "v1",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerKey
    },
    log
  })
    .then(success)
    .catch(fail);
};

const getDigitalKey = (state, success, fail, FieldsKey) => {
  const urlComplete = config.clusterHost + "/digitalkey/requestForm/" + state.establishment.id;
  //let bearerKey = config.cmsApiHostCredentials.bearer;
  const tokenKey = state.tokenUserArray[state.establishment.id];
  const bearerKey = auth.getAuthBearer(state);

  const header = {
    "x-stay-pms-guest": tokenKey + "",
    "Stay-App-Type": "weex",
    "Stay-Version": "v1",
    "Content-Type": "application/json",
    Authorization: "Bearer " + bearerKey
  };

  const bearer = auth.getAuthBearer(state);

  requestDigitalKey({
    method: "get",
    url: urlComplete,
    headers: header
  })
    .then(success)
    .catch(fail);

  /*

    request({
        method: "get",
        headers: header,
        url: urlComplete,
        json: true
      })
        .then(success)
        .catch(fail);*/
};

const userDidTypeSMSCode = function (router, smsCode) {
  //return new Promise((resolve, reject) => {
  DigitalKey.setSMSCode(smsCode);

  /* dkmodule.setSMSCode(smsCode, success => {
        if (success) {
          showKeys("", router, true,true);
          resolve();
        }
        else {
          reject();
        }
      })*/
  // });
};

const userDidTapOnResendSMSButton = function () {
  DigitalKey.resendSMSCode();
};

const userDidSelectKey = function (router, selectedKey, selectedKeyIndex) {
  showDKDetailScreen(router, selectedKey, selectedKeyIndex, false);
};

const sendInvitationCode = function (router, invitationCode) {
  DigitalKey.setInvitationCode(invitationCode);
};

const userDidTapOnActivateButton = function (store, router, selectedKeyIndex, provider, binary) {
  DigitalKey.activateKeyWithIndex(selectedKeyIndex);
};

const userDidTapOnDeactivateButton = function (store, router, selectedKeyIndex, provider, binary) {
  //plantear separar la funciones de desactivar y activar llave.
  DigitalKey.deactivateKeyWithIndex(selectedKeyIndex);
  /*
    return new Promise((resolve, reject) => {
      if(dkmodule){
        dkmodule.deactivateKeyWithIndex(selectedKeyIndex, success => {
          if(success!=1){
            reject();
            //call error  success
            createSendLog(store, "STOP_SCAN_ERROR",false,"ERROR_STOP_SCAN", provider, binary);
          }
          else {
            //createSendLog(store, "button_desactive", true, "", provider, binary); //no necesesary send log
            resolve();
          }
        })
      }else{
        //call error  module
        reject();
        createSendLog(store, "STOP_SCAN_ERROR",false,"ERROR_NO_MODULE_KEY", provider, binary);
      }

    });*/
};

const isBlockButtonDesactive = function () {
  DigitalKey.needsBlockButtonDesactive("resultInitData");
};

const isneedPermissionLocation = function () {
  DigitalKey.needsPermissionLocation("resultInitData");
};

const setbluetoothAtive = function () {
  DigitalKey.setbluetoothAtive("resultInitData");
};

const isBluetoothEnabled = function () {
  DigitalKey.isBluetoothEnabled("resultInitData");
};

const retryShowkeysNative = function (retry, replace, router) {
  /* if(retry<=8){
      setTimeout(function(){
        startProcess(replace).then((response) =>{
            if(response===1){ //tenemos el proceso listo para obtener las llaves
              checkKeysNative("", router, true,retry);
            }else{
              retry=retry+1;
              if(retry==9){
                showDKErrorScreenReplace(router, "MOBILE_KEY", "KEY_ERROR_NO_KEYS","OK", false);
              }else{
                retryShowkeysNative(retry,replace, router)
              }
            }
          }).catch(error => {});
      },1000);
    }*/
};

const checkKeysNative = function (responseData, router, replaceScreen, retry) {
  /*setTimeout(function(){
      reloadKeys().then((responseInfo) => {
          var response;;
          if((isAndroid())){
            var response = JSON.parse(responseInfo);
          }else{
            response=responseInfo;
          }
          if(response && response.hasOwnProperty("error")){
            if(retry<9){
              retry=retry+1;
              retryShowkeysNative(retry, replaceScreen, router);
            }else{
              showDKErrorScreenRouter(router, "MOBILE_KEY", response.error,"OK", false,replaceScreen);
            }
          }else{
            if(response && response.length==0){
              if(retry<9){
                retry=retry+1;
                retryShowkeysNative(retry, replaceScreen, router);
              }else{
                showDKErrorScreenRouter(router, "MOBILE_KEY", "KEY_ERROR_NO_KEYS","OK", false,replaceScreen);
              }
            }else if (response) {
              responseData = response;
              if (responseData && responseData.length == 1) {
                showDKDetailScreen(router, responseData[0], 0, replaceScreen);
              }
              else if (responseData.keys) {
                showDKListScreen(router, responseData, replaceScreen);
              }
              else {
                if(retry<9){
                  retry=retry+1;
                  retryShowkeysNative(retry, replaceScreen, router);
                }else{
                  showDKErrorScreenRouter(router, "MOBILE_KEY", "KEY_ERROR_NO_KEYS","OK", false,replaceScreen);
                }
              }
            }else{
              if(retry<9){
                retry=retry+1;
                retryShowkeysNative(retry, replaceScreen, router);
              }else{
                showDKErrorScreenRouter(router, "MOBILE_KEY","KEY_ERROR_NO_KEYS","OK", false,replaceScreen);
                return true;
              }
            }
          }
        }).catch((err) => {
          return false;
        });
      },1000);*/
};

const showKeys = function (responseData, router, replaceScreen, nativeFlag) {
  if (nativeFlag) {
    //TODO loading...
    //modify responseData
    // eslint-disable-next-line no-undef
    reloadKeys()
      .then(responseInfo => {
        var response;
        // eslint-disable-next-line no-undef
        if (isAndroid()) {
          var response = JSON.parse(responseInfo);
        } else {
          response = responseInfo;
        }
        if (response && response.hasOwnProperty("error")) {
          showDKErrorScreenRouter(router, "MOBILE_KEY", response.error, "OK", false, replaceScreen);
        } else {
          if (response && response.length == 0) {
            showDKErrorScreenRouter(router, "MOBILE_KEY", "KEY_ERROR_NO_KEYS", "OK", false, replaceScreen);
          } else if (response) {
            responseData = response;
            if (responseData && responseData.length == 1) {
              showDKDetailScreen(router, responseData[0], 0, replaceScreen);
            } else if (responseData.keys) {
              showDKListScreen(router, responseData, replaceScreen);
            } else {
              showDKErrorScreenRouter(router, "MOBILE_KEY", "KEY_ERROR_NO_KEYS", "OK", false, replaceScreen);
              //showDKErrorScreen(router, "MOBILE_KEY","KEY_ERROR_NO_KEYS","OK", false);
            }
          } else {
            //showDKErrorScreen(router, "MOBILE_KEY","KEY_ERROR_NO_KEYS","OK", false);
            showDKErrorScreenRouter(router, "MOBILE_KEY", "KEY_ERROR_NO_KEYS", "OK", false, replaceScreen);
          }
        }
      })
      .catch(err => {
        //showDKErrorScreen(router, "MOBILE_KEY",err,"OK",false);
        showDKErrorScreenRouter(router, "MOBILE_KEY", err, "OK", false, replaceScreen);
      });
  } else {
    if (responseData.keys && responseData.keys.length == 1) {
      showDKDetailScreen(router, responseData.keys[0], 0, replaceScreen);
    } else if (responseData.keys) {
      showDKListScreen(router, responseData.keys, replaceScreen);
    } else {
      //showDKErrorScreen(router, "MOBILE_KEY","","", false);
      showDKErrorScreenRouter(router, "MOBILE_KEY", "", "OK", false, replaceScreen);
    }
  }
};

const showDKErrorScreenRouter = function (router, title, description, button, _doubleBack, replace) {
  if (replace) {
    showDKErrorScreenReplace(router, title, description, button, _doubleBack);
  } else {
    showDKErrorScreen(router, title, description, button, _doubleBack);
  }
};

const showDKErrorScreen = function (router, title, description, button, _doubleBack) {
  const params = {
    title: title,
    description: description,
    buttonTitle: button,
    doubleBack: _doubleBack
  };

  router.push({
    name: "DKErrorScreen",
    params: params,
    state: { params: cloneDeep(params) }
  });
};

const showDKErrorScreenReplace = function (router, title, description, button, _doubleBack) {
  router.replace({
    name: "DKErrorScreen",
    params: {
      title: title,
      description: description,
      buttonTitle: button,
      doubleBack: _doubleBack
    }
  });
};

const showDKEmailScreen = function (router) {
  router.push({
    name: "DKEmail"
  });
};

const showDKPhoneNumberScreen = function (router, mobilePhone) {
  const params = {
    mobilePhone: mobilePhone
  };

  router.replace({
    name: "DKPhoneNumber",
    params: params,
    state: { params: cloneDeep(params) }
  });
};

const showDKSMSScreen = function (router) {
  router.push({
    name: "DKSMSCode"
  });
};

const showDKListScreen = function (router, keys, replaceScreen) {
  const params = {
    keys: keys
  };
  if (replaceScreen) {
    router.replace({
      name: "DKKeysList",
      params: params,
      state: { params: cloneDeep(params) }
    });
  } else {
    router.push({
      name: "DKKeysList",
      params: params,
      state: { params: cloneDeep(params) }
    });
  }
};

const showDKRedirectionScreen = function (loginFieldsKey, router, provider, replaceScreen) {
  const pathRoute = router.currentRoute.path;
  let name = "DKKeysRedirection";
  if (pathRoute.includes("dk-keys-redirection")) {
    name = "DKKeysRedirectionReload";
  }

  const params = {
    loginFieldsKey: loginFieldsKey,
    provider: provider
  };

  if (replaceScreen) {
    router.replace({
      name: name,
      params: params,
      state: { params: cloneDeep(params) }
    });
  } else {
    router.push({
      name: name,
      params: params,
      state: { params: cloneDeep(params) }
    });
  }
};

const showDKDetailScreen = function (router, selectedKey, selectedKeyIndex, replaceScreen) {
  const params = {
    selectedKey: selectedKey,
    selectedKeyIndex: selectedKeyIndex
  };

  if (replaceScreen) {
    router.replace({
      name: "DKKeyDetail",
      params: params,
      state: { params: cloneDeep(params) }
    });
  } else {
    router.push({
      name: "DKKeyDetail",
      params: params,
      state: { params: cloneDeep(params) }
    });
  }
};

const showDKFormulary = function (router, keyDigitalFields, provider, replaceScreen) {
  const params = {
    keyDigitalFields: keyDigitalFields,
    provider: provider
  };

  if (replaceScreen) {
    router.replace({
      name: "DKFormulary",
      params: params,
      state: { params: cloneDeep(params) }
    });
  } else {
    router.push({
      name: "DKFormulary",
      params: params,
      state: { params: cloneDeep(params) }
    });
  }
};

const managementFormDigitalKey = function (loginFieldsKey, store, router, provider, replaceScreen) {
  if (checkifQuestionDigitalKey(loginFieldsKey)) {
    const FieldsKey = checkifQuestionDigitalSaveKey(loginFieldsKey, store);
    if (checkifQuestionValueDigitalKey(FieldsKey)) {
      getBinaryProvider(FieldsKey, store, router, provider, replaceScreen);
    } else {
      showDKFormulary(router, FieldsKey, provider, replaceScreen);
    }
  } else {
    getBinaryProvider(loginFieldsKey, store, router, provider, replaceScreen);
  }
};

const getBinaryProvider = function (loginFieldsKey, store, router, provider, replaceScreen) {
  //provider="ASSA_ABLOY";
  if (provider === "ASSA_ABLOY") {
    showDKRedirectionScreen(loginFieldsKey, router, provider, replaceScreen);
  } else {
    getBinaryDigitalKey(loginFieldsKey, store, router, replaceScreen);
  }
};

const managementListDigitalKey = function (responseData, router, logingFieldsKey, replaceScreen) {
  /* //control to SDK NATIVE.
    let binary=true;
    for(let i=0;i<responseData.keys.length;i++){
      if(!(responseData.keys[i].binary && responseData.keys[i].binary.length>0)){
        binary=false;
        break;
      }
    }
    if(binary){
      showKeys(responseData, router, replaceScreen,false);
    }else{
      showDKCurrentScreen(responseData, router, replaceScreen);
    }*/
  showKeys(responseData, router, replaceScreen, false);
};

const checkifQuestionDigitalSaveKey = function (loginFieldsKey, store) {
  let fieldNoHidden = [];
  if (!store.state.hasOwnProperty("digitalKeyFieldArray")) {
    store.state.digitalKeyFieldArray = {};
  }
  if (store.state.digitalKeyFieldArray[store.state.establishment.id] != undefined) {
    fieldNoHidden = store.state.digitalKeyFieldArray[store.state.establishment.id];
    if (fieldNoHidden != null && fieldNoHidden.length > 0) {
      for (let i = 0; i < loginFieldsKey.length; i++) {
        if (!loginFieldsKey[i].hidden) {
          for (let j = 0; j < fieldNoHidden.length; j++) {
            if (fieldNoHidden[j].ref === loginFieldsKey[i].ref) {
              loginFieldsKey[i].value = fieldNoHidden[j].value;
              break;
            }
          }
        }
      }
    }
  }
  return loginFieldsKey;
};

const checkifQuestionValueDigitalKey = function (loginFieldsKey) {
  let isValue = true;
  for (let i = 0; i < loginFieldsKey.length; i++) {
    if (!loginFieldsKey[i].value) {
      isValue = false;
      break;
    }
  }
  return isValue;
};

const checkifQuestionDigitalKey = function (loginFieldsKey) {
  let isQuestion = false;
  for (let i = 0; i < loginFieldsKey.length; i++) {
    if (!loginFieldsKey[i].hidden) {
      isQuestion = true;
      break;
    }
  }
  return isQuestion;
};

const getFormDigitalKey = function (store, router, replaceScreen) {
  const loginFields = [];
  store
    .dispatch("getDigitalKey", loginFields)
    .then(responseData => {
      const provider = responseData[0].provider;
      const responseMapped = getmapResponse(responseData[0].keyForm);
      const loginFieldsKey = [];
      for (let i = 0; i < responseMapped.length; i++) {
        if (
          (store.state.user.establishments[store.state.establishment.id][responseMapped[i].key] ||
            responseMapped[i].key === "selectedEstablishment") &&
          responseMapped[i].hidden
        ) {
          let value = "";
          if (responseMapped[i].key === "selectedEstablishment") {
            value = store.state.establishment.id;
          } else {
            value = store.state.user.establishments[store.state.establishment.id][responseMapped[i].key];
          }
          const fieldLoginFields = {
            ref: responseMapped[i].key,
            value: value,
            required: responseMapped[i].required,
            hidden: responseMapped[i].hidden
          };
          loginFieldsKey.push(fieldLoginFields);
        } else {
          const fieldLoginFields = {
            ref: responseMapped[i].key,
            value: "",
            required: responseMapped[i].required,
            hidden: responseMapped[i].hidden,
            type: responseMapped[i].type,
            name: responseMapped[i].keyName
          };
          loginFieldsKey.push(fieldLoginFields);
        }
      }

      managementFormDigitalKey(loginFieldsKey, store, router, provider, replaceScreen);
    })
    .catch(err => {
      if (err === "KEY_ERROR_UNATHORIZED") {
        //is need logout
        createSendLog(
          store,
          "call: " + "/digitalkey/requestForm/" + store.state.establishment.id,
          false,
          err + " force logout",
          "no_info",
          "no_binary"
        );
        store.dispatch("logout").then(() => {
          //saveStore(store.state);
        });
      } else if (err === "KEY_ERROR_CREDENCIAL" || err === "KEY_ERROR_NO_KEYS") {
        deleteSaveQuestionDigitalKey(store);
        createSendLog(
          store,
          "call: " + "/digitalkey/requestForm/" + store.state.establishment.id,
          false,
          err + " delete Info Keys",
          "no_info",
          "no_binary"
        );
      } else {
        createSendLog(
          store,
          "call: " + "/digitalkey/requestForm/" + store.state.establishment.id,
          false,
          err,
          "no_info",
          "no_binary"
        );
      }

      showDKErrorScreen(router, "MOBILE_KEY", err, "OK", false);
    });
};

const getBinaryDigitalKey = async (FieldsKey, store, router, replaceScreen) => {
  try {
    const [responseData] = await store.dispatch("getCredencialDigitalKey", FieldsKey);
    FieldsKey = getArrayNoHiddenField(FieldsKey);

    if (!responseData) return;

    if (responseData.keys) {
      store.commit(types.SET_DIGITAL_KEY_FIELD, FieldsKey);
      managementListDigitalKey(responseData, router, FieldsKey, replaceScreen);
    } else if (responseData.invitationCode) {
      router.replace({
        name: "DKKeysRedirection",
        params: {
          data: responseData[0]
        }
      });
    }
  } catch (err) {
    console.error(`digitalkey error: ${err}`);
    if (err === "KEY_ERROR_UNATHORIZED") {
      //is need logout
      createSendLog(store, "call: " + "/digitalkey/getMyKeys/", false, err + " force logout", "no_info", "no_binary");
      store.dispatch("logout").then(() => {
        //saveStore(store.state);
      });
    } else if (err === "KEY_ERROR_CREDENCIAL" || err === "KEY_ERROR_NO_KEYS") {
      deleteSaveQuestionDigitalKey(store);
      createSendLog(
        store,
        "call: " + "/digitalkey/getMyKeys/",
        false,
        err + " delete Info Keys",
        "no_info",
        "no_binary"
      );
    } else {
      createSendLog(store, "call: " + "/digitalkey/getMyKeys/", false, err, "no_info", "no_binary");
    }
    showDKErrorScreen(router, "MOBILE_KEY", err, "OK", replaceScreen);
  }
};

const createSendLog = function (store, type, success, error, provider, binary) {
  store
    .dispatch("sendLogDigitalKey", { type: type, success: success, error: error, provider: provider, binary: binary })
    .then(responseData => {});
};

const deleteSaveQuestionDigitalKey = function (store) {
  const FieldsKey = [];
  store.commit(types.SET_DIGITAL_KEY_FIELD, FieldsKey);
  //saveStore(store.state);
};

const getArrayNoHiddenField = function (FieldsKey) {
  const fieldArrayNoHidden = [];
  if (FieldsKey) {
    for (let i = 0; i < FieldsKey.length; i++) {
      if (!FieldsKey[i].hidden) {
        fieldArrayNoHidden.push(FieldsKey[i]);
      }
    }
  }
  return fieldArrayNoHidden;
};

export {
  getCredencialDigitalKey,
  sendLogDigitalKey,
  getDigitalKey,
  initDigitalKey,
  startProcess,
  showDigitalKeysFunnelExt,
  showDigitalKeysFunnel,
  userDidTypeEmail,
  userDidTypePhoneNumber,
  userDidTypeSMSCode,
  userDidTapOnResendSMSButton,
  userDidSelectKey,
  sendInvitationCode,
  userDidTapOnActivateButton,
  userDidTapOnDeactivateButton,
  isBlockButtonDesactive,
  isneedPermissionLocation,
  setbluetoothAtive,
  isBluetoothEnabled,
  retryShowkeysNative,
  checkKeysNative,
  showKeys,
  showDKErrorScreenRouter,
  showDKErrorScreen,
  showDKErrorScreenReplace,
  showDKEmailScreen,
  showDKPhoneNumberScreen,
  showDKSMSScreen,
  showDKListScreen,
  showDKRedirectionScreen,
  showDKDetailScreen,
  showDKFormulary,
  managementFormDigitalKey,
  getBinaryProvider,
  managementListDigitalKey,
  checkifQuestionDigitalSaveKey,
  checkifQuestionValueDigitalKey,
  checkifQuestionDigitalKey,
  getFormDigitalKey,
  getBinaryDigitalKey,
  createSendLog,
  deleteSaveQuestionDigitalKey,
  getArrayNoHiddenField
};
