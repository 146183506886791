import config from "../config";
import request from "@/services/request";

const createWithProduct = (state, success, fail, leadData) => {
  request({
    method: "post",
    url: config.apiHost + "/webhooks/leads/creation-demand",
    headers: {
      "Content-Type": "application/json"
    },
    data: leadData
  })
    .then(success)
    .catch(fail);
};

export { createWithProduct };
