import axios from "axios";

const searchWit = (state, success, fail, text) => {
  const url = "https://api.wit.ai/message?v=20170307&q=" + text;
  axios({
    method: "get",
    url: url,
    headers: {
      Authorization: "Bearer HV767I4KSAH7XAMSGUWWQOHAELZLWHVM"
    }
  })
    .then(success)
    .catch(fail);
};

export { searchWit };
