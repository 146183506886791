export default {
  namespaced: true,
  state() {
    return {
      isGranted: undefined,
      timestampPopup: null
    };
  },
  getters: {},
  mutations: {
    setNotificationPermission(state, data) {
      state.isGranted = data;
    },
    setTimestampPopup(state, data) {
      state.timestampPopup = data;
    }
  }
};
