import PromotionsService from "@/services/promotions/PromotionsService";

// initial state
// shape: [{ id, quantity }]
const state = {
  promotionsList: [],
  seenPromotions: [],
  displayPopup: false,
  promotionSelected: undefined
};

// getters
const getters = {
  getSeenPromotions(state) {
    return state.seenPromotions;
  }
};

// actions
const actions = {
  async getAll({ rootState, commit }) {
    const promotionsList = await PromotionsService.getAll(rootState);
    commit("setPromotionsList", promotionsList);
    commit("setPromotionSelected", undefined);

    return promotionsList;
  },

  async getById({ rootState, commit }, id) {
    const promotion = await PromotionsService.getById(rootState, id);
    commit("setPromotionSelected", promotion);

    return promotion;
  },

  async applyOnRoomService({ rootState, commit }, { promotionId, roomServiceData }) {
    return await PromotionsService.applyOnRoomService(rootState, { promotionId, roomServiceData });
  },

  parseToNotification(ctx, promo) {
    return {
      type: "promo",
      data: {
        id: promo.id,
        image: promo.photographs ? promo.photographs[0] : undefined,
        title: promo.translatableTitle,
        description: promo.translatableDescription,
        data: {
          ...promo
        }
      }
    };
  },

  resetState({ commit }) {
    return commit("setInitialState", {
      promotionsList: [],
      seenPromotions: [],
      displayPopup: false,
      promotionSelected: undefined
    });
  }
};

// mutations
const mutations = {
  setPromotionsList(state, data) {
    state.promotionsList = data;
  },

  addShownPromotion(state, id) {
    state.seenPromotions.push(id);
  },

  displayPopup(state) {
    state.displayPopup = !state.displayPopup;
  },

  setPromotionSelected(state, payload) {
    state.promotionSelected = payload;
  },

  setInitialState(state, payload) {
    state.promotionSelected = payload.promotionSelected;
    state.displayPopup = payload.displayPopup;
    state.promotionsList = payload.promotionsList;
    state.seenPromotions = payload.seenPromotions;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
