export default {
  FRONTPAGE_STYLE_BOXES: 1,
  FRONTPAGE_STYLE_BUBBLES: 2,
  FRONTPAGE_STYLE_BUBBLES_V3: 3,
  FRONTPAGE_STYLE_BOXES_V3: 7,
  FRONTPAGE_STYLE_SLIDING_BOXES: 10,
  FRONTPAGE_STYLE_BUBBLES_WITH_ICONS: 11,
  FRONTPAGE_STYLE_BUBBLES_WITH_IMAGES: 12,
  FRONTPAGE_STYLE_WIDGETS: 13,
  FRONTPAGE_STYLE_DYNAMIC: "dyn"
};
